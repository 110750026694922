<div [class]="class">
  <div [ngClass]="{'information': notificationType === NotificationType.Information, 'success': notificationType === NotificationType.Success, 'warning': notificationType === NotificationType.Warning, 'error': notificationType === NotificationType.Error}" class="flex items-center text-icon rounded d-inline-flex p-2">
    <span *ngIf="notificationType === NotificationType.Error" class="material-symbols-outlined">
      error
    </span>
    <span *ngIf="notificationType === NotificationType.Information" class="material-symbols-outlined">
      info
    </span>
    <span *ngIf="notificationType === NotificationType.Warning" class="material-symbols-outlined">
      warning
    </span>
    <span *ngIf="notificationType === NotificationType.Success" class="material-symbols-outlined">
      check_circle
    </span>
    <p class="p-1 !m-1"><b>{{notification}}</b></p>
  </div>
</div>
