<div class="container mx-auto px-4 py-8">
  <ng-container *ngIf="accountStatement.isInValueState && accountStatement.value">
    <mat-card-title>
      <h1 class="mat-headline !my-0 !mb-6">
        Kontotrend*
      </h1>
    </mat-card-title>
  </ng-container>
  <ng-container *ngIf="accountStatement.isInNoValueState || accountStatement.isInErrorState || accountStatement.isInLoadingState">
    <mat-card-title>
      <h1 class="mat-headline !my-0 !mb-6">
        Kontotrend
      </h1>
    </mat-card-title>
  </ng-container>
  <div class="flex flex-col md:flex-row">
    <ng-container *ngIf="accountStatement.isInValueState && accountStatement.value">
      <div class="w-full md:w-8/12 mb-10 md:mb-0">
      <mat-card class="!p-0">
        <mat-card-content>
          <div class="flex w-full flex-col md:flex-row md:justify-between px-7 py-9 md:items-center">
            <ng-container *ngIf="accountStatement.value.totalBalanceInEuroGross < 0">
              <span class="result md:order-2 mat-headline !mb-0 text-[#B00020]">{{ accountStatement.value.totalBalanceInEuroGross | number:'1.2-2' }} €</span>
            </ng-container>
            <ng-container *ngIf="accountStatement.value.totalBalanceInEuroGross >= 0">
              <span class="result md:order-2 mat-headline !mb-0 text-[#9CC234]">{{ accountStatement.value.totalBalanceInEuroGross | number:'1.2-2' }} €</span>
            </ng-container>
            <span class="dialogTrigger flex items-center">
              Gesamtbilanz
              <mat-icon class="!text-[#C8C8C8] !text-[14px] !md:text-[16px] !w-[16px] !h-[15px] ml-1 cursor-pointer" (click)="openTotalBalanceDialog()"> help </mat-icon>
            </span>
          </div>
        </mat-card-content>
      </mat-card>
      <ng-container *ngIf="accountStatement.value.totalBalanceInEuroGross < 0">
        <div class="flex w-full bg-[#f7e5e8] mt-[-20px] pt-7 pb-2 rounded-b-2xl px-7 text-[#B00020] justify-between items-center">
          <span class="mat-button flex items-center cursor-pointer" routerLink="/{{ environment.monthlyPaymentUrl }}">
            <mat-icon class="!text-[#B00020] !text-[14px] !md:text-[16px] !w-[16px] !h-[15px] mr-1">border_color</mat-icon>
            Zahlbetrag anpassen
          </span>
          <span>EMPFOHLEN</span>
        </div>
      </ng-container>
      <ng-container *ngIf="accountStatement.value.totalBalanceInEuroGross >= 0">
        <div class="flex w-full bg-[#D8E8AB] mt-[-20px] pt-7 pb-2 rounded-b-2xl px-7 text-[#9CC234] justify-between items-center">
          <span class="mat-button flex items-center cursor-pointer" routerLink="/{{ environment.meterReadingUrl }}">
            <mat-icon class="!text-[#9CC234] !text-[14px] !md:text-[16px] !w-[16px] !h-[15px] mr-1">border_color</mat-icon>
            Zählerstand melden
          </span>
          <span>TIPP</span>
        </div>
      </ng-container>
      <div class="tabs flex w-full mt-10">
        <mat-tab-group animationDuration="0">
          <mat-tab *ngFor="let entry of accountStatement.value.entries" [label]="accountStatementService.createDateString(entry)">
            <div class="cardWRapper bg-white p-6 rounded-3xl flex flex-wrap border my-2">
              <div class="titleRow flex justify-end w-full mb-6">
                <span class="uppercase mat-button !text-[#C8C8C8] !bg-[#f7f7f7] px-2 py-1 rounded">
                  Prognose
                </span>
              </div>
              <div class="priceDetailRow w-full flex justify-between mb-2">
                <span class="detail">Monatlicher Zahlbetrag</span>
                <span class="value mat-button">{{entry.monthlyPaymentInEuroGross | number:'1.2-2'}} €</span>
              </div>
              <div class="priceDetailRow w-full flex justify-between mb-2">
                <span class="detail">Grundpreis</span>
                <span class="value mat-button">-{{entry.basicPriceInEuroGross | number:'1.2-2'}} €</span>
              </div>
              <div class="priceDetailRow w-full flex justify-between mb-2">
                <span class="detail flex items-center">
                  Verbrauchskosten
                  <mat-icon class="!text-[#C8C8C8] !text-[14px] !md:text-[16px] !w-[16px] !h-[15px] ml-1 cursor-pointer" (click)="openConsumptionPriceDialog(entry)"> help </mat-icon>
                </span>
                <span class="value mat-button">-{{entry.consumptionPriceInEuroGross | number:'1.2-2'}} €</span>
              </div>
              <hr class="w-full border-t-gray-300 my-[3px]" />
              <hr class="w-full border-t-gray-300" />
              <ng-container *ngIf="entry.serviceFeeInEuroGross">
                  <div class="priceDetailRow w-full flex justify-between mt-4">
                    <span class="detail">Servicepauschale</span>
                    <span class="value mat-button">-{{entry.serviceFeeInEuroGross | number:'1.2-2'}} €</span>
                  </div>
              </ng-container>
              <div class="priceDetailRow w-full flex justify-between mt-2 mb-4">
                <span class="detail">Monatsbilanz</span>
                <span class="value mat-button">{{entry.balanceInEuroGross | number:'1.2-2'}} €</span>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="flex w-full mt-3">
        <span>
          *Der Kontotrend dient lediglich als Orientierung und basiert auf analytisch ermittelten Verbrauchsdaten. Der Rechnungsbetrag kann abweichen und wird mit Ihren Zählerständen errechnet
        </span>
      </div>
    </div>
    </ng-container>
    <ng-container *ngIf="accountStatement.isInLoadingState">
      <div class="w-full md:w-8/12 mb-10 md:mb-0">
        <div class="flex flex-col items-center">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="accountStatement.isInErrorState">
      <div class="w-full md:w-8/12 mb-10 md:mb-0">
        <app-notification [notificationType]="NotificationType.Error" notification="Ihr Kontotrend kann zurzeit nicht angezeigt werden – Bitte versuchen Sie es später erneut."></app-notification>
      </div>
    </ng-container>
    <ng-container *ngIf="accountStatement.isInNoValueState">
      <div class="w-full md:w-8/12 mb-10 md:mb-0">
        <app-notification [notificationType]="NotificationType.Information" notification="Ihr Kontotrend ist bald verfügbar. Zurzeit sind keine Daten vorhanden."></app-notification>
      </div>
    </ng-container>
    <div class="w-full md:flex-1 md:ml-5">
      <app-contact-card></app-contact-card>
    </div>
  </div>

</div>
