import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundUpDecimal'
})
export class RoundUpDecimalPipe implements PipeTransform {

  transform(value: number, decimalPlaces: number = 1): number {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.ceil(value * multiplier) / multiplier;
  }

}
