import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, combineLatest, debounceTime, map, of, startWith, Subject, switchMap, takeUntil, timer} from 'rxjs';
import {AuthService} from '../../shared/services/auth.service';
import {MoveService} from '../services/move.service';
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../confirm-dialog/components/confirm-dialog.component";
import {isValidators} from "../../shared/validators/validators";
import {fadeAnimation} from "../../shared/fade-animation/fade-animation";
import {LocationService} from "../services/location.service";
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-move',
  templateUrl: './move.component.html',
  animations: [fadeAnimation]
})

export class MoveComponent implements OnInit, OnDestroy {
  private destroyed = new Subject<void>();
  moveFormGroup: FormGroup | null = null;
  @ViewChild('moveForm') moveForm: NgForm | null = null;
  isSaveProcessOngoing: boolean = false;
  private minimumProgressTimeInMilliseconds: number = 800;
  postcodeMaximumLength: number = 5;
  cityMaximumLength: number = 30;
  streetMaximumLength: number = 55;
  houseNumberMaximumLength: number = 5;
  meterNumberMaximumLength: number = 22;
  minDate = new Date().setDate(new Date().getDay() - 42);
  maxDate = new Date().setMonth(new Date().getMonth() + 6);

  citiesByPostcode: string[] = [];
  filteredCities: string[] = [];

  streetsByCity: string[] = [];
  filteredStreets: string[] = [];

  constructor(public dialog: MatDialog,
              private moveService: MoveService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private locationService: LocationService,
              private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.moveFormGroup = this.formBuilder.group({
      oldPostcode: [{value: this.authService.contract?.deliveryAddress.location.postcode, disabled: true}],
      oldCity: [{value: this.authService.contract?.deliveryAddress.location.city, disabled: true}],
      oldStreet: [{value: this.authService.contract?.deliveryAddress.location.street, disabled: true}],
      oldHouseNumber: [{value: this.authService.contract?.deliveryAddress.location.houseNumber, disabled: true}],
      newPostcode: [null, [Validators.required, Validators.minLength(this.postcodeMaximumLength)]],
      newCity: [null, [Validators.required]],
      newStreet: [null, [Validators.required]],
      newHouseNumber: [null, [Validators.required]],
      newMeterNumber: [null],
      moveInDate: [null, [Validators.required, isValidators.date(this.maxDate, this.minDate)]],
      moveOutDate: [null, [Validators.required, isValidators.date(this.maxDate, this.minDate)]]
    });

    this.moveFormGroup.controls.newCity.valueChanges.pipe(
      startWith([]),
      debounceTime(300),
    ).subscribe(cityFilter => {
      let postcode = this.moveFormGroup!.controls.newPostcode.value;
      let city = this.moveFormGroup!.controls.newCity.value
      this.filteredCities = this.citiesByPostcode.filter(cities => cities.toLowerCase().includes(cityFilter.toLowerCase()));
      this.locationService.getPlace(postcode, city).pipe(
        catchError(err => []),
      )
        .subscribe(
          streets => this.streetsByCity = streets
        )
    });

    this.moveFormGroup.controls.newPostcode.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(
        postcode => {
          return this.locationService.getCities(postcode as string).pipe(
            catchError(err => [])
          );
        })
    ).subscribe(cities => {
      this.citiesByPostcode = cities ?? [];
      this.filteredCities = [];
    });

    this.moveFormGroup.controls.newStreet.valueChanges.pipe(
      startWith([]),
      debounceTime(300),
    ).subscribe(streetFilter => {
      this.filteredStreets = this.streetsByCity?.filter(streets => streets.toLowerCase().includes(streetFilter.toLowerCase()));
    });
  }

  onSubmit(): void {
    if (this.moveForm!.invalid || this.isSaveProcessOngoing) {
      return;
    }

    this.isSaveProcessOngoing = true;
    let moveSubscription = this.moveService.createMove({
      city: this.moveFormGroup!.controls.newCity.value,
      houseNumber: this.moveFormGroup!.controls.newHouseNumber.value,
      postcode: this.moveFormGroup!.controls.newPostcode.value,
      street: this.moveFormGroup!.controls.newStreet.value,
      meterNumber: this.moveFormGroup!.controls.newMeterNumber.value,
      moveInDate: this.moveFormGroup!.controls.moveInDate.value,
      moveOutDate: this.moveFormGroup!.controls.moveOutDate.value
    }, this.authService.contract!.contractId)
      .pipe(catchError(error => of({error})));
    combineLatest([timer(this.minimumProgressTimeInMilliseconds), moveSubscription])
      .pipe(takeUntil(this.destroyed))
      .pipe(map(observables => observables[1])).subscribe(observable => {
      if (observable?.error) {
        this.snackBar.open('Das Speichern ist fehlgeschlagen. Bitte versuchen Sie es später erneut.', 'OK', {
          panelClass: 'snack-danger'
        });
      } else {
        this.router.navigate([environment.moveSuccessUrl], {relativeTo: this.route});
      }
    }).add(() => {
      this.isSaveProcessOngoing = false;
    });
  }

  reset() {
    this.moveForm!.resetForm({
      oldPostcode: this.moveFormGroup!.controls.oldPostcode.value,
      oldCity: this.moveFormGroup!.controls.oldCity.value,
      oldStreet: this.moveFormGroup!.controls.oldStreet.value,
      oldHouseNumber: this.moveFormGroup!.controls.oldHouseNumber.value
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  openDialog(message: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '280px',
      data: {message: message},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.reset();
      }
    });
  }
}

