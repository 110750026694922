<div class="container mx-auto h-full flex items-center px-4 md:px-8 lg:px-24 justify-center">
  <ng-container *ngIf="magicKeyValidity.isInValueState && magicKeyValidity.value">
    <mat-card class="w-full md:w-3/4 lg:w-1/2 !p-8 md:!p-10">
      <mat-card-title>
        <span>Bestätigen Sie Ihre Identität</span>
      </mat-card-title>
      <span class="block mt-8">
        Zum Schutz Ihrer Daten benötigen wir Ihr Geburtsdatum, Vertrags- oder Kundennummer.
      </span>
      <form *ngIf="magicKeyLoginFormGroup" #magicKeyLoginForm="ngForm" [formGroup]="magicKeyLoginFormGroup" (ngSubmit)="onSubmit()">
        <fieldset [disabled]="loginProcess.isInLoadingState">
          <div class="flex mt-5">
            <mat-form-field class="w-full" appearance="fill">
              <mat-label>Anmelden mit</mat-label>
              <mat-select formControlName="authentificationType"
                          (selectionChange)="onAuthentificationTypeChanged()">
                <mat-option [value]="MagicKeyAuthentificationType.Birthdate">
                  Geburtstag
                </mat-option>
                <mat-option [value]="MagicKeyAuthentificationType.ContractId">
                  Vertragsnummer
                </mat-option>
                <mat-option [value]="MagicKeyAuthentificationType.CustomerId">
                  Kundennummer
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flex mt-5">
            <ng-container *ngIf="magicKeyLoginFormGroup.controls.authentificationType.value === MagicKeyAuthentificationType.Birthdate">
              <mat-form-field class="w-full" appearance="fill" floatLabel='always'>
                <mat-label>Geburtstag</mat-label>
                <input placeholder="TT.MM.JJJJ" matInput [matDatepicker]="picker" formControlName="birthdate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="magicKeyLoginFormGroup.controls.birthdate.errors?.required">Pflichtfeld</mat-error>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="magicKeyLoginFormGroup.controls.authentificationType.value === MagicKeyAuthentificationType.ContractId">
              <mat-form-field class="w-full" appearance="fill">
                <mat-label>Vertragsnummer</mat-label>
                <input type="text"
                       formControlName="contractId"
                       matInput>
                <mat-error *ngIf="magicKeyLoginFormGroup.controls.contractId.errors?.required">Pflichtfeld</mat-error>
                <mat-error *ngIf="magicKeyLoginFormGroup.controls.contractId.errors?.maxlength">Maximal {{contractIdMaximumLength}} Stellen</mat-error>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="magicKeyLoginFormGroup.controls.authentificationType.value === MagicKeyAuthentificationType.CustomerId">
              <mat-form-field class="w-full" appearance="fill">
                <mat-label>Kundennummer</mat-label>
                <input type="text"
                       formControlName="customerId"
                       matInput>
                <mat-error *ngIf="magicKeyLoginFormGroup.controls.customerId.errors?.required">Pflichtfeld</mat-error>
                <mat-error *ngIf="magicKeyLoginFormGroup.controls.customerId.errors?.maxlength">Maximal {{customerIdMaximumLength}} Stellen</mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </fieldset>
        <ng-container *ngIf="loginProcess.isInErrorState && loginProcess.error!.status === 401">
          <cpc-notification class="mt-4" [notificationType]="NotificationType.Error"
                            notification="Die eingegebenen Daten sind falsch. Bitte überprüfen Sie Ihre Eingabe.">
          </cpc-notification>
        </ng-container>
        <ng-container *ngIf="loginProcess.isInErrorState && loginProcess.error!.status !== 401">
          <cpc-notification class="mt-4" [notificationType]="NotificationType.Error"
                            notification="Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später erneut.">
          </cpc-notification>
        </ng-container>
        <div class="flex flex-col md:flex-row md:flex-wrap items-center md:justify-between mt-10">
          <app-button caption="Login" color="primary" type="submit" [progressBarVisible]="loginProcess.isInLoadingState" class="w-full md:w-auto"></app-button>
        </div>
      </form>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="magicKeyValidity.isInLoadingState || (!magicKeyValidity.value && !magicKeyValidity.isInErrorState)">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-container *ngIf="magicKeyValidity.isInErrorState">
    <cpc-notification [notificationType]="NotificationType.Error"
                      notification="Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später erneut.">
    </cpc-notification>
  </ng-container>
</div>

