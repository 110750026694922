import {Component, HostBinding} from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact-success-component.html'
})
export class ContactSuccessComponent {

  environment = environment;
  @HostBinding('class') flexClass = 'flex-1';

}
