import { combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { ObservableStatus } from '../classes/observable-status';
import { ObservableDisplayState } from '../enumerations/observable-display-state';


const MINIMUM_LOADING_TIME_NORMAL: number = 400;
const MINIMUM_LOADING_TIME_SLOW: number = 800;

function withStatus<T>(
  source: Observable<T>
): Observable<ObservableStatus<T>> {
  return source.pipe(
    map(value => new ObservableStatus<T>(null, value, false, value ? ObservableDisplayState.Value : ObservableDisplayState.NoValue)),
    catchError(error => of(new ObservableStatus<T>(error, null, false, ObservableDisplayState.Error))),
    startWith(new ObservableStatus<T>(null, null, true, ObservableDisplayState.Loading))
  )
}

function withStatusAndDelayedLoadingIndicator<T>(
  source: Observable<T>,
  minimumTimeToShowLoadingIndicatorInMilliseconds: number
): Observable<ObservableStatus<T>> {
  return combineLatest([
    withStatus(source),
    timer(minimumTimeToShowLoadingIndicatorInMilliseconds).pipe(
      map(() => false),
      startWith(true)
    )
  ]).pipe(
    map(([status, isTimerActive]) => (
      new ObservableStatus<T>(
      status.error,
      status.value,
      isTimerActive ? true : status.isRunning,
      isTimerActive ? ObservableDisplayState.Loading : status.displayState
    )))
  );
}

export function determineGroupedStatus(displayStates: ObservableDisplayState[]): ObservableDisplayState {
  if (displayStates.every(state => state === ObservableDisplayState.None)) {
    return ObservableDisplayState.None;
  }
  if (displayStates.includes(ObservableDisplayState.Loading)) {
    return ObservableDisplayState.Loading;
  }
  if (displayStates.includes(ObservableDisplayState.Error)) {
    return ObservableDisplayState.Error;
  }
  if (displayStates.includes(ObservableDisplayState.Value)) {
    return ObservableDisplayState.Value;
  }
  return ObservableDisplayState.NoValue;
}

export function withNormalLoadingStatus<T>(source: Observable<T>): Observable<ObservableStatus<T>> {
  return withStatusAndDelayedLoadingIndicator(source, MINIMUM_LOADING_TIME_NORMAL);
}

export function withSlowLoadingStatus<T>(source: Observable<T>): Observable<ObservableStatus<T>> {
  return withStatusAndDelayedLoadingIndicator(source, MINIMUM_LOADING_TIME_SLOW);
}

