import {Component, Input} from '@angular/core';
import {Topic} from "../interfaces/topic";
import {Router} from "@angular/router";

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html'
})
export class TopicsComponent {

  @Input() topics: Topic[] = [];

  constructor(private router: Router) {
  }
  selectTopic(topic: Topic) {
    this.router.navigate(['/faq/topics', topic.id.toString()]);
  }
}
