import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DayaheadBasePricesOverview } from '../interfaces/dayahead-base-prices-overview';

@Injectable({
  providedIn: 'root'
})
export class DayaheadBasePriceService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getDayaheadBasePricesOverview(country: string): Observable<DayaheadBasePricesOverview> {
    console.log(`${this.baseUrl}api/dayahead-base-prices/overview?country=${country}`);
    return this.http.get<DayaheadBasePricesOverview>(`${this.baseUrl}api/dayahead-base-prices/overview?country=${country}`);
  }

}
