import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import * as ibantools from "ibantools";

export class CustomValidators {

  static accountHolder(control: AbstractControl): ValidationErrors | null {
    return accountHolderValidator(control);
  }
  static iban(control: AbstractControl): ValidationErrors | null {
    return ibanValidator(control);
  }

  static bic(control: AbstractControl): ValidationErrors | null {
    return bicValidator(control);
  }

  static oneOfBoth(controlName1: string, controlName2: string) {
    return oneOfBothValidator(controlName1, controlName2);
  }

}

export function accountHolderValidator(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null;
  }
  const characterString = control.value as string;
  const regex = /^[a-zA-Z0-9/:?().,'+-\s]+$/;
  if (!regex.test(characterString)) {
    return { accountHolderInvalidCharacters: true };
  }
  return null;
}

export function ibanValidator(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null;
  }
  const iban = control.value as string;
  const clearedIban = ibantools.electronicFormatIBAN(iban);
  if (!clearedIban || !ibantools.isValidIBAN(clearedIban)) {
    return { iban: true };
  }
  return null;
}

export function bicValidator(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null;
  }
  const bic = control.value as string;
  if (!ibantools.isValidBIC(bic)) {
    return { bic: true };
  }
  return null;
}

export function oneOfBothValidator(controlName1: string, controlName2: string) {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control1 = formGroup.get(controlName1);
    const control2 = formGroup.get(controlName2);
    if (isEmptyInputValue(control1?.value) && isEmptyInputValue(control2?.value)) {
      return { oneOfBoth: true };
    } else {
      return null;
    }
  };
}

function isEmptyInputValue(value: any): boolean {
  return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

