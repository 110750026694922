import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditSettlementComponent } from './components/credit-settlement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { CreditSettlementRoutingModule } from "./credit-settlement-routing.module";
import { SharedModule } from "../shared/shared.module";
import { MatRadioModule } from "@angular/material/radio";
import { ContactModule } from '../contact/contact.module';

@NgModule({
  declarations: [
    CreditSettlementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    CreditSettlementRoutingModule,
    SharedModule,
    MatRadioModule,
    ContactModule
  ]
})
export class CreditSettlementModule { }
