import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-dashboard-documents-card',
  templateUrl: './dashboard-documents-card.component.html'
})
export class DashboardDocumentsCardComponent {

  environment = environment;

}
