import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { InformationDialog } from '../interfaces/information-dialog';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html'
})

export class InformationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InformationDialog,
  ) {}

  close() {
    this.dialogRef.close();
  }

}
