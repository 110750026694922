import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { fromEvent, map, of, Subject, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TariffType } from '../../price/enumerations/tariff-type';
import { DailyPriceSnapshot } from '../../price/interfaces/daily-price-snapshot';
import { PriceDetail } from '../../price/interfaces/price-detail';
import { PriceAutoLoadService } from '../../price/services/price-auto-load.service';
import { PriceService } from '../../price/services/price.service';
import { EnergyType } from '../../shared/enumerations/energy-type';
import { Contract } from '../../shared/interfaces/contract';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { Chart, registerables } from "chart.js";
import 'chartjs-adapter-moment';
import { ChartRange } from '../enumerations/chart-range';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GroupUnit } from '../../price/enumerations/group-unit';
import { NotificationType } from "../../shared/enumerations/notification-type";
import { PriceCompositionGroup } from "../../price/interfaces/price-composition-group";
import { PriceComponentType } from '../enumerations/price-component-type';
import { withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { ObservableDisplayState } from '../../observable-status/enumerations/observable-display-state';
import { PriceComposition } from '../../price/interfaces/price-composition';

Chart.register(...registerables);

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html'
})
export class TariffComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  environment = environment;
  EnergyType = EnergyType;
  TariffType = TariffType;
  ChartRange = ChartRange;
  PriceComponentType = PriceComponentType;
  NotificationType = NotificationType;
  ObservableDisplayState = ObservableDisplayState;

  selectedContract: Contract | null = null;
  currentPrice: PriceDetail | null = null;
  dailyPriceSnapshots: DailyPriceSnapshot[] | null = null;
  actualDailyPriceSnapshot: DailyPriceSnapshot | null = null;
  isLoading: boolean = false;
  chart: any = null;
  tariffFormGroup: FormGroup | null = null;
  chartRangeAverage: number | null = null;
  priceCompositionGroupStatus: ObservableStatus<PriceCompositionGroup> | null = null;
  priceComponents: PriceComposition[] = [];

  
  @Input() wrapperClass: string = 'w-full';

  constructor(private formBuilder: FormBuilder, public priceService: PriceService, private customerSelectionService: CustomerSelectionService, public priceAutoLoadService: PriceAutoLoadService) {
  }

  ngOnInit(): void {
    this.tariffFormGroup = this.formBuilder.group({
      chartRange: [ChartRange.Month, []],
      includeTaxesAndDuties: [true, []],
    });
    this.createChart();
    this.tariffFormGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.refreshChart();
      });
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.chart?.resize();
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
      });
    this.priceAutoLoadService.currentPrice$
      .pipe(takeUntil(this.destroyed$))
      .pipe(
        switchMap(currentPrice => {
          this.isLoading = true;
          if (currentPrice?.tariffType === TariffType.Index || currentPrice?.tariffType === TariffType.IndexHybrid) {
            return this.priceService
              .getDailyPriceSnapshots(this.selectedContract!.contractId)
              .pipe(map(dailyPriceSnapshots => ({ currentPrice, dailyPriceSnapshots })));
          } else {
            return of({ currentPrice, dailyPriceSnapshots: null });
          }
        })
      )
      .subscribe(result => {
        this.currentPrice = result.currentPrice;
        result.dailyPriceSnapshots?.sort((first, second) => Date.parse(first.day) - Date.parse(second.day));
        this.dailyPriceSnapshots = result.dailyPriceSnapshots;
        this.actualDailyPriceSnapshot = null;
        if (this.dailyPriceSnapshots && this.dailyPriceSnapshots.length > 0) {
          this.actualDailyPriceSnapshot = this.dailyPriceSnapshots[this.dailyPriceSnapshots.length - 1];
        }
        this.refreshChart();
        this.isLoading = false;
      });

    withNormalLoadingStatus(
        this.priceService.getPriceCompositionGroup(this.selectedContract!.contractId)
      ).pipe(
        takeUntil(this.destroyed$)
      ).subscribe((status) => {
        this.priceCompositionGroupStatus = status;
        if (status.isInValueState && status.value) {
          this.priceComponents = status.value.priceCompositions.sort((a, b) => a.priceCompositionType.localeCompare(b.priceCompositionType));
      } else {
          this.priceComponents = [];
      }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  createChart(): void {
    this.chart = new Chart("priceChart", {
      type: 'line',
      data: {
        datasets: [{
          data: [],
          borderColor: 'rgb(155, 185, 28)',
          fill: false,
          borderWidth: 1.3,
          tension: 0.07,
          pointStyle: 'circle',
          pointRadius: 5,
          pointHoverRadius: 10,
          backgroundColor: 'rgba(155, 185, 28, 0.5)',
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
          },
        },
        parsing: {
          xAxisKey: 'day',
          yAxisKey: 'purchasePriceInEuroGross'
        },
        scales: {
          x: {
            grid: { display: false },
            ticks: {
              color: '#CBC8C8',
            },
            type: 'time',
            time: {
              parser: 'YYYY-MM-DDTHH:mm:ssZ',
              unit: 'month',
              tooltipFormat: 'MM YYYY',
            },
          },
          y: {
            ticks: {
              color: '#CBC8C8',
            },
            title: {
              display: true,
              text: 'Cent',
              color: '#CBC8C8'
            }
          }
        }
      }
    });
  }

  refreshChart(): void {
    if (!this.dailyPriceSnapshots || !this.chart || !this.tariffFormGroup) {
      return;
    }
    let entries: DailyPriceSnapshot[] = [];
    if (this.tariffFormGroup.controls.chartRange.value === ChartRange.Year) {
      entries = this.priceService.aggregateDailyPriceSnapshots(this.dailyPriceSnapshots, 365, GroupUnit.Month);
      this.chart.options.scales.x.time.unit = 'month';
      this.chart.options.scales.x.time.tooltipFormat = 'MMM YYYY';
    } else if (this.tariffFormGroup.controls.chartRange.value === ChartRange.Month) {
      entries = this.priceService.aggregateDailyPriceSnapshots(this.dailyPriceSnapshots, 31, GroupUnit.Week);
      this.chart.options.scales.x.time.unit = 'week';
      this.chart.options.scales.x.time.tooltipFormat = 'll';
    } else if (this.tariffFormGroup.controls.chartRange.value === ChartRange.Week) {
      entries = this.priceService.aggregateDailyPriceSnapshots(this.dailyPriceSnapshots, 7, GroupUnit.Day);
      this.chart.options.scales.x.time.unit = 'day';
      this.chart.options.scales.x.time.tooltipFormat = 'MMM D';
    }
    this.chart.data.datasets[0].data = entries;
    this.chart.options.scales.x.min = entries[0].day;
    this.chart.options.scales.x.max = entries[entries.length - 1].day;
    if (this.tariffFormGroup.controls.includeTaxesAndDuties.value) {
      this.chart.options.parsing.yAxisKey = 'totalPriceInEuroGross';
      this.chartRangeAverage = this.priceService.aggregateDailyPriceSnapshots(entries, entries.length, GroupUnit.All)[0].totalPriceInEuroGross;
    }
    else {
      this.chart.options.parsing.yAxisKey = 'purchasePriceInEuroGross';
      this.chartRangeAverage = this.priceService.aggregateDailyPriceSnapshots(entries, entries.length, GroupUnit.All)[0].purchasePriceInEuroGross;
    }
    this.chart.update('none');
  }
}