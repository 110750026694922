<div class="container mx-auto px-4 py-8">
  <app-breadcrumb></app-breadcrumb>
  <ng-container *ngIf="tag">
    <mat-card-title>
      <h1 class="mat-headline">
        {{ tag.tag }}
      </h1>
    </mat-card-title>
    <div class="w-full">
      <app-favourites [favourites]="favourites"></app-favourites>
    </div>
    <ng-container *ngIf="questions && questions.length > 0">
      <div class="flex flex-wrap my-8">
        <mat-accordion class="w-full">
          <mat-expansion-panel *ngFor="let question of visibleQuestions">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ question.question }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="question.answer"></div>
          </mat-expansion-panel>
        </mat-accordion>

        <ng-container *ngIf="!allQuestionsLoaded">
          <div class="w-full flex justify-center my-8">
            <button type="button" customer-portal-button
                    mat-raised-button
                    class="w-auto !px-10 load-more-button !rounded-md"
                    (click)="loadMore()">
              Mehr Inhalte laden
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="questions.length === 0">
      <div class="w-full my-8">
        <app-notification [notificationType]="NotificationType.Information"
                          notification="Keine Fragen verfügbar.">
        </app-notification>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="this.faqState.isInLoadingState">
    <div class="m-auto flex items-center my-16 w-full z-10">
      <mat-spinner class="m-auto"></mat-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="this.faqState.isInErrorState">
    <app-notification [notificationType]="NotificationType.Error"
                      notification="Die Themen sind derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.">
    </app-notification>
  </ng-container>
</div>


