import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Contract} from "../interfaces/contract";
import { Customer } from '../interfaces/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerSelectionService {
  private selectedCustomer$: BehaviorSubject<Customer | null> = new BehaviorSubject<Customer | null>(null);
  private selectedContract$: BehaviorSubject<Contract | null> = new BehaviorSubject<Contract | null>(null);

  customerSelected$ = this.selectedCustomer$.asObservable();
  contractSelected$ = this.selectedContract$.asObservable();

  private readonly CUSTOMER_KEY = 'SelectedCustomer';
  private readonly CONTRACT_KEY = 'SelectedContract';

  constructor() {
    const selectedCustomer = sessionStorage.getItem(this.CUSTOMER_KEY);
    const selectedContract = sessionStorage.getItem(this.CONTRACT_KEY);

    if (selectedCustomer)
      this.selectedCustomer$.next(JSON.parse(selectedCustomer));

    if (selectedContract)
      this.selectedContract$.next(JSON.parse(selectedContract));
  }

  selectCustomer(customer: Customer) {
    sessionStorage.setItem(this.CUSTOMER_KEY, JSON.stringify(customer));
    this.selectedCustomer$.next(customer);
  }

  selectContract(contract: Contract) {
    sessionStorage.setItem(this.CONTRACT_KEY, JSON.stringify(contract));
    this.selectedContract$.next(contract);
  }

  resetSelection() {
    sessionStorage.removeItem(this.CUSTOMER_KEY);
    sessionStorage.removeItem(this.CONTRACT_KEY);
    this.selectedContract$.next(null);
    this.selectedCustomer$.next(null);
  }

}
