import {Directive, ElementRef} from '@angular/core';
import {MatButton} from "@angular/material/button";
@Directive({
  selector: '[customer-portal-button]'
})
export class CustomerPortalButtonDirective {
  constructor(private el: ElementRef) {
    let buttonStyle = this.el.nativeElement.style;
    buttonStyle.borderRadius = '43px';
    buttonStyle.padding = '12px 30px';
    buttonStyle.whiteSpace = 'normal';
    buttonStyle.boxShadow = '5px 5px 20px #0000001A';
  }
}
