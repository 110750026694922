<div mat-dialog-header>
  <div class="flex justify-end">
    <button mat-button class="close-icon" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="!flex-1 !max-h-none !px-0 !flex !flex-col">
  <app-order-correction-stepper (savePerformed)="performClose()" (cancellationPerformed)="performClose()" [settings]="data" #stepper></app-order-correction-stepper>
</div>

