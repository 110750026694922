import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss']
})
export class FileSelectComponent {

  @Input() class: string = '';
  @Input() acceptedFileExtensions: string = '';
  @Input() maximumFileLengthInBytes: number = 100048576;

  private _file: File | null = null;
  @Output() fileChange = new EventEmitter<File | null>();

  get file(): File | null {
    return this._file;
  }

  @Input() set file(value: File | null) {
    if (this._file != value) {
      this._file = value;
      this.fileChange.emit(value);
    }
  }

  constructor(private snackBar: MatSnackBar) {
  }

  onFileSelected(event: Event) : void {
    const inputElement = event.target as HTMLInputElement;
    let newFile: File | null = null;
    if (inputElement.files && inputElement.files.length > 0) {
      newFile = inputElement.files[0];
      if (newFile.size > this.maximumFileLengthInBytes) {
        const maximumAllowedMb = parseFloat((this.maximumFileLengthInBytes / 1024 / 1024).toFixed(2));
        this.snackBar.open(`Die Datei ist zu groß. Erlaubt sind maximal ${maximumAllowedMb} MB.`, 'OK', {
          panelClass: 'snack-danger'
        });
        newFile = null;
      }
    }
    this.file = newFile;
  }

}
