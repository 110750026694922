<div class="container mx-auto px-4 py-8">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Kontakt
    </h1>
    <span class="mat-title !my-0">
      Um welches Thema geht es?
    </span>
  </mat-card-title>
  <div class="flex flex-col md:flex-row">
    <div class="w-full md:flex-1 md:mr-5 mb-10 md:mb-0">
      <ng-container *ngIf="contactFormGroup">
        <form *ngIf="contactFormGroup && possibleCategories" #contactForm="ngForm" [formGroup]="contactFormGroup"
              (ngSubmit)="save()">
          <div class="flex flex-wrap">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Themenbereich wählen</mat-label>
              <mat-select (selectionChange)="onMainCategoryChange()" [(ngModel)]="selectedCategory"
                          formControlName="mainCategory">
                <mat-option *ngFor="let mainCategory of possibleCategories" [value]="mainCategory">
                  {{ mainCategory.selectionText }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="selectedCategory!= null">
              <div class="border rounded p-3 mt-5 mb-5" [innerHTML]="selectedCategory.description"></div>
              <ng-container *ngIf="showSubCategories===false && this.selectedCategory.linkText != null">
                <a href="{{selectedCategory.linkUrl}}" class="flex flex-col w-full">
                  <span class="color-thema primary-background p-4 mat-subheading-2 rounded text-center">
                    {{ selectedCategory.linkText }}
                  </span>
                </a>
              </ng-container>
              <ng-container *ngIf="this.showSubCategories===false">
                <a class="mat-subheading-2 w-full flex justify-center items-center" (click)="onShowSubCategories()">
                  <span color="primary">Ich habe eine andere Frage</span>
                  <mat-icon color="primary"> chevron_right</mat-icon>
                </a>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="this.showSubCategories && selectedCategory?.contactReasons">
              <span class="mat-subheading-2">Wie können wir Ihnen helfen?</span>
              <mat-form-field appearance="fill" class="w-full mb-5">
                <mat-label>Anliegen auswählen</mat-label>
                <mat-select (selectionChange)="onSubCategoryChange()"
                            formControlName="subCategory" [(value)]="selectedReason">
                  <mat-option *ngFor="let contactReason of selectedCategory?.contactReasons" [value]="contactReason">
                    {{ contactReason.selectionText }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <ng-container *ngIf="showTextarea">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Ihre Nachricht</mat-label>
                  <textarea
                    matInput
                    formControlName="contactMessage"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="4">
                  </textarea>
                  <mat-hint class="block ml-3">[{{ contactFormGroup.controls.contactMessage.value?.length ?? 0 }}
                    / {{ maximumMessageLength }}]
                  </mat-hint>
                  <mat-error *ngIf="contactFormGroup.controls.contactMessage.errors?.maxlength">
                    [{{ contactFormGroup.controls.contactMessage.value?.length ?? 0 }} / {{ maximumMessageLength }}]
                  </mat-error>
                </mat-form-field>
                <div class="w-full md:w-auto mt-5">
                  <app-button caption="Absenden" color="primary" type="submit" [progressBarVisible]="isSaveProcessOngoing"></app-button>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </form>
        <ng-container *ngIf="!contactFormGroup || !possibleCategories">
          <div class="flex justify-center">
            <mat-spinner></mat-spinner>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="w-full md:w-4/12">
      &nbsp;
    </div>
  </div>
</div>
