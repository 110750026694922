import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PaymentInformationEditComponent } from './components/payment-information-edit.component';
import { PaymentInformationComponent } from './components/payment-information.component';
import { SharedModule } from '../shared/shared.module';
import { PaymentInformationRoutingModule } from './payment-information-routing.module';
import { QRCodeModule } from "angularx-qrcode";
import { FileUploadModule } from '../file-upload/file-upload.module';
import { SepaUploadComponent } from './components/sepa-upload.component';

@NgModule({
  declarations: [
    PaymentInformationEditComponent,
    SepaUploadComponent,
    PaymentInformationComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    SharedModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    PaymentInformationRoutingModule,
    FileUploadModule,
    QRCodeModule
  ],
})
export class PaymentInformationModule { }
