import { Component, HostBinding } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { PriceAutoLoadService } from '../../price/services/price-auto-load.service';
import { Subject, takeUntil } from 'rxjs';
import { PriceDetail } from '../../price/interfaces/price-detail';
import { TariffType } from '../../price/enumerations/tariff-type';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent {

  @HostBinding('class') flexClass = 'flex-1';
  private destroyed$ = new Subject<void>();
  currentPrice: PriceDetail | null = null;
  TariffType = TariffType;
  environment = environment;

  constructor(
    public priceAutoLoadService: PriceAutoLoadService,
    public authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.priceAutoLoadService.currentPrice$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(currentPrice => {
        this.currentPrice = currentPrice;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
