import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CreditSettlement } from "../interfaces/credit-settlement";
import { CustomerSelectionService } from "../../shared/services/customer-selection.service";
import { CreditSettlementService } from "../services/credit-settlement.service";
import { of, Subject, takeUntil } from "rxjs";
import { Contract } from "../../shared/interfaces/contract";
import { switchMap } from "rxjs/operators";
import { CreditSettlementType } from "../enumerations/credit-settlement-type";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { CustomValidators } from "../../shared/validators/custom-validators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CreditSettlementTypeViewModel } from '../interfaces/credit-settlement-type-view-model';
import { environment } from '../../../../environments/environment';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus, withSlowLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { NotificationType } from '../../shared/enumerations/notification-type';

@Component({
  selector: 'app-credit-settlement',
  templateUrl: './credit-settlement.component.html'
})
export class CreditSettlementComponent implements OnInit, OnDestroy {

  @HostBinding('class') flexClass = 'flex-1';

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  CreditSettlementType = CreditSettlementType;
  NotificationType = NotificationType;
  environment = environment;

  defaultCreditSettlement: CreditSettlement | null = null;
  creditSettlementSaveOperation: ObservableStatus<void> = ObservableStatus.none<void>();
  lastCreditSettlement: ObservableStatus<CreditSettlement> = ObservableStatus.none<CreditSettlement>();
  selectedContract: Contract | null = null;
  creditSettlementTypeViewModels: CreditSettlementTypeViewModel[] = [];

  creditSettlementFormGroup: FormGroup | null = null;
  @ViewChild('creditSettlementForm') creditSettlementForm: NgForm | null = null;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public creditSettlementService: CreditSettlementService,
    private customerSelectionService: CustomerSelectionService) {
  }

  ngOnInit(): void {
    this.creditSettlementTypeViewModels = this.creditSettlementService.getCreditSettlementTypeViewModels();
    this.creditSettlementFormGroup = this.formBuilder.group({
      creditSettlementType: [null, Validators.required],
      accountHolder: [null, null],
      iban: [null, null],
      bic: [null, null],
    });
    this.creditSettlementFormGroup
      .valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(values => {
        if (values.creditSettlementType === CreditSettlementType.PayoutByBankTransfer
          && !this.creditSettlementFormGroup!.controls.accountHolder.hasValidator(Validators.required)) {
          this.creditSettlementFormGroup!.controls.accountHolder.addValidators([Validators.required]);
          this.creditSettlementFormGroup!.controls.accountHolder.updateValueAndValidity();
          this.creditSettlementFormGroup!.controls.iban.addValidators([Validators.required, CustomValidators.iban]);
          this.creditSettlementFormGroup!.controls.iban.updateValueAndValidity();
          this.creditSettlementFormGroup!.controls.bic.addValidators([Validators.required, CustomValidators.bic]);
          this.creditSettlementFormGroup!.controls.bic.updateValueAndValidity();
        }
        else if (values.creditSettlementType !== CreditSettlementType.PayoutByBankTransfer
          && this.creditSettlementFormGroup!.controls.accountHolder.hasValidator(Validators.required)) {
          this.creditSettlementFormGroup!.controls.accountHolder.clearValidators();
          this.creditSettlementFormGroup!.controls.accountHolder.updateValueAndValidity();
          this.creditSettlementFormGroup!.controls.iban.clearValidators();
          this.creditSettlementFormGroup!.controls.iban.updateValueAndValidity();
          this.creditSettlementFormGroup!.controls.bic.clearValidators();
          this.creditSettlementFormGroup!.controls.bic.updateValueAndValidity();
        }
      });
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of(ObservableStatus.none<CreditSettlement>())
          }
          return withNormalLoadingStatus(
            this.creditSettlementService.getLastCreditSettlement(this.selectedContract.contractId)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(lastCreditSettlement => {
        this.lastCreditSettlement = lastCreditSettlement;
        this.defaultCreditSettlement = this.calculateDefaultCreditSettlement(this.lastCreditSettlement);
        this.creditSettlementFormGroup!.patchValue(this.defaultCreditSettlement);
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

  calculateDefaultCreditSettlement(lastCreditSettlement: ObservableStatus<CreditSettlement>): CreditSettlement {
    if (lastCreditSettlement.isInValueState) {
      return lastCreditSettlement.value!;
    }
    else if (environment.mustSelectPayoutMethodForEachCreditSettlement) {
      return {} as CreditSettlement;
    } else {
      return { creditSettlementType: CreditSettlementType.PayoutByBankTransfer } as CreditSettlement;
    }
  }

  save(): void {
    if (this.creditSettlementForm!.invalid || this.creditSettlementSaveOperation.isInLoadingState) {
      return;
    }
    let selectedCreditSettlement = {
      creditSettlementType: this.creditSettlementFormGroup!.controls.creditSettlementType.value,
      accountHolder: this.creditSettlementFormGroup!.controls.accountHolder.value,
      iban: this.creditSettlementFormGroup!.controls.iban.value,
      bic: this.creditSettlementFormGroup!.controls.bic.value,
    } as CreditSettlement;
    withSlowLoadingStatus(
      this.creditSettlementService.insertCreditSettlement(this.selectedContract!.contractId, selectedCreditSettlement)
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(creditSettlementSaveOperation => {
        this.creditSettlementSaveOperation = creditSettlementSaveOperation;
        if (creditSettlementSaveOperation.isInErrorState) {
          this.snackBar.open('Einstellungen zur Guthabenauszahlung konnten nicht gespeichert werden!', 'OK', {
            panelClass: 'snack-danger'
          });
        } else if (creditSettlementSaveOperation.isInValueState || creditSettlementSaveOperation.isInNoValueState) {
          this.snackBar.open('Einstellungen zur Guthabenauszahlung wurden erfolgreich gespeichert.', 'OK', {
            panelClass: 'snack-success'
          });
          this.refresh();
        }
      });
  }

}
