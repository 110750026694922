import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
})
export class ContractComponent {

  @HostBinding('class') flexClass = 'flex-1';

}
