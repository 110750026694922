import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent {
  @Input() disabled: boolean = false;
  @Input() caption : string = "Absenden";
  @Input() type : string = "Button";
  @Input() color : string | null = null;
  @Input() progressBarVisible: boolean = false;
  @Input() icon: string | null = null;
  @HostBinding('class') flexClass = 'px-0 w-full';
}
