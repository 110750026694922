import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { PaymentMethod } from '../enumerations/payment-method';
import { PaymentInformation } from '../interfaces/payment-information';
import { PaymentMethodModel } from '../interfaces/payment-method-model';
import * as ibantools from "ibantools";

@Injectable({
  providedIn: 'root'
})
export class PaymentInformationService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private authService: AuthService) {
  }

  getAllPossiblePaymentMethods(): PaymentMethodModel[] {
    return [
      { value: PaymentMethod.BankTransfer, label: "Überweisung" },
      { value: PaymentMethod.DirectDebit, label: "Bankeinzug" }
    ];
  }

  getPaymentInformation(contractId: string): Observable<PaymentInformation> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<PaymentInformation>(`${this.baseUrl}api/payment-information/${contractId}`, { headers: headers });
  }

  updatePaymentInformation(contractId: string, value: PaymentInformation): Observable<any> {
    if (value.iban) {
      value.iban = ibantools.electronicFormatIBAN(value.iban);
    }
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.put(`${this.baseUrl}api/payment-information/${contractId}`, value, { headers: headers });
  }

  blurIban(iban: string | undefined | null): string {
    if (!iban) {
      return '';
    }
    const clearedIban = ibantools.electronicFormatIBAN(iban);
    if (!clearedIban || !ibantools.isValidIBAN(clearedIban)) {
      return 'IBAN ungültig'
    }
    else {
      let removeCount = 15;
      let rightCount = 4;
      let leftCount = 3;
      return clearedIban.substr(0, leftCount) + "*".repeat(removeCount) + clearedIban.substr(leftCount + removeCount, rightCount);
    }
  }

}
