import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { AccountStatement } from "../interfaces/account-statement";
import { AccountStatementEntry } from "../interfaces/account-statement-entry";
import { DatePipe } from "@angular/common";

@Injectable({
        providedIn: 'root'
})

export class AccountStatementService {

  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private datePipe: DatePipe){
  }

  getAccountStatement(contractId: string): Observable<AccountStatement> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<AccountStatement>(`${this.baseUrl}api/account-statement/${contractId}`, { headers: headers });
  }

  createDate(entry: AccountStatementEntry): Date
  {
    return new Date(entry.year, entry.month - 1);
  }

  createDateString(entry: AccountStatementEntry): string {
    let date = this.createDate(entry);
    return this.datePipe.transform(date, 'MMMM y')!;
  }

}
