import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { DocumentsRoutingModule } from "./documents-routing.module";
import { DocumentsComponent } from "./components/documents.component";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatExpansionModule } from "@angular/material/expansion";
import { DocumentTableComponent } from "./components/document-table.component";
import { ContactModule } from "../contact/contact.module";
import { SharedModule } from "../shared/shared.module";


@NgModule({
  declarations: [
    DocumentTableComponent,
    DocumentsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    DocumentsRoutingModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatPaginatorModule,
    MatExpansionModule,
    ContactModule
  ],
})

export class DocumentsModule {}
