<div class="legalLinks mt-10">
  <div class="container mx-auto flex flex-col sm:flex-row justify-between my-1 px-4 border-t pt-1">
    <div class="copyrightNotice flex justify-center py-1">
      <span class="text-[#707070]">
        © {{ copyrightBy }} {{ currentDate | date : "YYYY" }}
      </span>
    </div>
    <div class="legalLinksWrapper sm:flex ">
      <div class="legalLink flex justify-center py-1">
        <a href="{{ environment.electricityLabelingUrl }}" target="_blank">
          <span class="text-[#707070] font-medium">Stromkennzeichnung</span>
        </a>
      </div>
      <div class="legalLink flex justify-center py-1 sm:ml-3">
        <a href="{{ environment.dataProtectionUrl }}" target="_blank">
          <span class="text-[#707070] font-medium">Datenschutz</span>
        </a>
      </div>
      <div class="legalLink flex justify-center py-1 sm:ml-3">
        <a href="{{ environment.legalNoticeUrl }}" target="_blank">
          <span class="text-[#707070] font-medium">Impressum</span>
        </a>
      </div>
    </div>
  </div>
</div>
