<mat-card class="!hidden lg:!block flex-1">
  <mat-card-content class="!flex flex-col h-full">
    <a routerLink="/{{ environment.meterReadingUrl }}" class="flex items-center justify-between">
      <span class="flex">
        <mat-icon class="material-symbols-outlined mr-1" color="primary"> pin </mat-icon>
        <h1 class="mat-subheading-2 !my-0">Zählerstände</h1>
      </span>
      <span class="flex">
        <mat-icon color="primary" class="self-end">chevron_right</mat-icon>
      </span>
    </a>
    <div class="cardContent border rounded-md p-2 mt-4 flex-1">
      <div class="flex flex-col">
        <ng-container *ngIf="lastMeterReading.isInValueState && lastMeterReading.value">
          <span class="block">
            {{lastMeterReading.value.readingValue}}
            <span *ngIf="selectedContract?.deliveryInformation?.energyType === EnergyType.Gas"> m<sup>3</sup></span>
            <span *ngIf="selectedContract?.deliveryInformation?.energyType === EnergyType.Electricity"> kWh</span>
          </span>
          <span class="block">abgelesen am {{lastMeterReading.value.readingDate|date:'mediumDate'}}</span>
        </ng-container>
        <ng-container *ngIf="lastMeterReading.isInLoadingState">
          <div class="flex justify-center w-full">
            <mat-spinner [diameter]="70"></mat-spinner>
          </div>
        </ng-container>
        <ng-container *ngIf="lastMeterReading.isInErrorState || lastMeterReading.isInNoValueState">
          <div class="w-full">Hier können Sie Ihren aktuellen Zählerstand hinzufügen.</div>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="lg:!hidden !py-8">
  <mat-card-content>
    <a routerLink="/{{ environment.meterReadingUrl }}" class="flex flex-col justify-center">
      <h1 color="primary" class="mat-subheading-2 text-center !my-0">
        <mat-icon color="primary" class="material-symbols-outlined" style="transform: scale(1.5)">pin</mat-icon>
        <span class="block mt-2">Zählerstände</span>
      </h1>
    </a>
  </mat-card-content>
</mat-card>
