import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { of, Subject, switchMap, takeUntil } from "rxjs";
import { Contract } from "../../shared/interfaces/contract";
import { CustomerSelectionService } from "../../shared/services/customer-selection.service";
import { EnergyType } from "../../shared/enumerations/energy-type";
import { MonthlyPaymentDetail } from "../../monthly-payment/interfaces/monthly-payment-detail";
import { MonthlyPaymentService } from "../../monthly-payment/services/monthly-payment.service";
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { ObservableDisplayState } from '../../observable-status/enumerations/observable-display-state';
import { NotificationType } from '../../shared/enumerations/notification-type';
import {PaymentMethod} from "../../payment-information/enumerations/payment-method";

@Component({
  selector: 'app-dashboard-monthly-payment-card',
  templateUrl: './dashboard-monthly-payment-card.component.html'
})
export class DashboardMonthlyPaymentCardComponent implements OnInit, OnDestroy {

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  EnergyType = EnergyType;
  ObservableDisplayState = ObservableDisplayState;
  NotificationType = NotificationType;
  environment = environment;

  selectedContract: Contract | null = null;
  lastMonthlyPayment: ObservableStatus<MonthlyPaymentDetail> = ObservableStatus.none<MonthlyPaymentDetail>();

  constructor(
    private monthlyPaymentService: MonthlyPaymentService,
    private customerSelectionService: CustomerSelectionService) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of(ObservableStatus.none<MonthlyPaymentDetail>())
          }
          return withNormalLoadingStatus(this.monthlyPaymentService.getMonthlyPaymentDetail(this.selectedContract.contractId));
        }),
        takeUntil(this.destroyed$)
      ).subscribe(lastMonthlyPayment => {
        this.lastMonthlyPayment = lastMonthlyPayment;
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

  protected readonly PaymentMethod = PaymentMethod;
}
