import {Component, HostBinding, Inject, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderCorrectionStepperSettings } from '../interfaces/order-correction-stepper-settings';
import { OrderCorrectionStepperComponent } from './order-correction-stepper.component';

@Component({
  selector: 'app-order-correction-stepper-dialog',
  templateUrl: './order-correction-stepper-dialog.component.html'
})
export class OrderCorrectionStepperDialogComponent {

  @HostBinding('class') flexClass = 'flex flex-col flex-1';

  @ViewChild('stepper') stepper: OrderCorrectionStepperComponent | null = null;

  constructor(
    public dialogRef: MatDialogRef<OrderCorrectionStepperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderCorrectionStepperSettings,
  ) {
    dialogRef.disableClose = true;
  }

  close() {
    if (this.stepper) {
      this.stepper.cancel();
    } else {
      this.performClose();
    }
  }

  performClose() {
    this.dialogRef.close();
  }

}
