import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AntiAuthGuardService implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate(): boolean | UrlTree {
    if (this.auth.isAuthenticated()) {
      const urlTree: UrlTree = this.router.parseUrl(`/${environment.dashboardUrl}`);
      return urlTree;
    }
    return true;
  }

}
