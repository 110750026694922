import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PaymentMethod } from '../../payment-information/enumerations/payment-method';
import { ReceiptOfPayment } from '../interfaces/receipt-of-payment';

@Component({
  selector: 'app-receipt-of-payment-table',
  templateUrl: './receipt-of-payment-table.component.html'
})
export class ReceiptOfPaymentTableComponent implements AfterViewInit, OnChanges {

  @Input() receiptsOfPayment: ReceiptOfPayment[] | null = null;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  displayedColumns: string[] = ['paymentMethod', 'receiptDate', 'amount'];
  dataSource: MatTableDataSource<ReceiptOfPayment> = new MatTableDataSource<ReceiptOfPayment>();
  PaymentMethod = PaymentMethod;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.receiptsOfPayment) {
      return;
    }
    if (!changes.receiptsOfPayment.currentValue) {
      this.dataSource.data = [];
      return;
    }
    this.dataSource.data = changes.receiptsOfPayment.currentValue;
  }

}
