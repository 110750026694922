import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthlyPaymentComponent } from './components/monthly-payment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MonthlyPaymentRoutingModule } from "./monthly-payment-routing.module";
import { MonthlyPaymentChangeComponent } from "./components/monthly-payment-change.component";
import { SharedModule } from "../shared/shared.module";
import { PaymentModule } from '../payment/payment.module';

@NgModule({
  declarations: [
    MonthlyPaymentChangeComponent,
    MonthlyPaymentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MonthlyPaymentRoutingModule,
    SharedModule,
    PaymentModule
  ]
})
export class MonthlyPaymentModule { }
