import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { OrderCorrectionRoutingModule } from "./order-correction-routing.module";
import { SharedModule } from "../shared/shared.module";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { OrderCorrectionStepperComponent } from './components/order-correction-stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FaqModule } from '../faq/faq.module';
import { OrderCorrectionStepperDialogComponent } from './components/order-correction-stepper-dialog.component';
import { OrderCorrectionNotificationComponent } from './components/order-correction-notification.component';

@NgModule({
  exports: [
    OrderCorrectionNotificationComponent
  ],
  declarations: [
    OrderCorrectionStepperComponent,
    OrderCorrectionStepperDialogComponent,
    OrderCorrectionNotificationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    OrderCorrectionRoutingModule,
    SharedModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatStepperModule,
    FaqModule,
  ]
})
export class OrderCorrectionModule { }
