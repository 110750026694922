import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { ContactComponent } from './components/contact.component';
import { ContactSuccessComponent } from "./components/contact-success-component";


const routes: Routes = [
  {
    title: 'Kontakt | Kundenportal | ' + environment.brandName,
    path: environment.contactFormUrl,
    children: [
      {
        path: '',
        component: ContactComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: environment.contactMessageSent,
        component: ContactSuccessComponent,
        canActivate: [AuthGuardService]
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ContactRoutingModule { }
