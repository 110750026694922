<mat-card class="!hidden lg:!block flex-1">
  <mat-card-content class="!flex flex-col h-full">
    <a routerLink="/{{ environment.accountStatementUrl }}" class="flex items-center justify-between">
      <span class="flex">
        <mat-icon class="material-symbols-outlined mr-1" color="primary">calculate</mat-icon>
        <h1 class="mat-subheading-2 !my-0">Kontotrend</h1>
      </span>
      <span class="flex">
        <mat-icon color="primary" class="self-end">chevron_right</mat-icon>
      </span>
    </a>
    <div class="cardContent border rounded-md p-2 mt-4 flex-1">
      <div class="w-full">
        Hier können Sie Ihren Kontotrend einsehen.
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="lg:!hidden !py-8">
  <mat-card-content>
    <a routerLink="/{{ environment.accountStatementUrl }}" class="flex flex-col justify-center">
      <h1 color="primary" class="mat-subheading-2 text-center !my-0">
        <mat-icon color="primary" class="material-symbols-outlined" style="transform: scale(1.5)">calculate</mat-icon>
        <span class="block mt-2">Kontotrend</span>
      </h1>
    </a>
  </mat-card-content>
</mat-card>

