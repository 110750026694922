export enum DocumentCategory {
  Invoice = 'Invoice',
  SupplyConfirmation = 'SupplyConfirmation',
  ContractConfirmation = 'ContractConfirmation',
  PriceAdjustment = 'PriceAdjustment',
  SepaMandate = 'SepaMandate',
  TariffChange = 'TariffChange',
  CorrectedInvoice = 'CorrectedInvoice',
  FinalInvoice = 'FinalInvoice',
  CorrectedFinalInvoice = 'CorrectedFinalInvoice',
  PriceCapInformation = 'PriceCapInformation'
}
