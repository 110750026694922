import { Component } from '@angular/core';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-legal-links',
  templateUrl: './legal-links.component.html'
})
export class LegalLinksComponent {

  environment = environment;
  copyrightBy: string = environment.copyrightBy;
  currentDate: Date = new Date();

  constructor() { }

}
