import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardMoveCardComponent } from './components/dashboard-move-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DashboardMeterReadingCardComponent } from './components/dashboard-meter-reading-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardMonthlyPaymentCardComponent } from "./components/dashboard-monthly-payment-card.component";
import { DashboardContactCardComponent } from "./components/dashboard-contact-card.component";
import { DashboardPaymentInformationCardComponent } from './components/dashboard-payment-information-card.component';
import { DashboardContractOverviewCardComponent } from "./components/dashboard-contract-overview-card.component";
import { DashboardDocumentsCardComponent } from "./components/dashboard-documents-card.component";
import { DashboardCreditSettlementCardComponent } from './components/dashboard-credit-settlement-card.component';
import { DashboardTariffCardComponent } from './components/dashboard-tariff-card.component';
import { TariffModule } from '../tariff/tariff.module';
import { SharedModule } from "../shared/shared.module";
import { DashboardAccountStatementCardComponent } from './components/dashboard-account-statement-card.component';

@NgModule({
  declarations: [
    DashboardMoveCardComponent,
    DashboardMonthlyPaymentCardComponent,
    DashboardMeterReadingCardComponent,
    DashboardContactCardComponent,
    DashboardContractOverviewCardComponent,
    DashboardPaymentInformationCardComponent,
    DashboardDocumentsCardComponent,
    DashboardCreditSettlementCardComponent,
    DashboardTariffCardComponent,
    DashboardAccountStatementCardComponent,
    DashboardComponent
  ],
  exports: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TariffModule,
    SharedModule
  ]
})
export class DashboardModule { }
