<div class="container mx-auto px-4 py-8">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Umzug melden
    </h1>
    <span class="mat-title !my-0">
      Alte Lieferstelle
    </span>
  </mat-card-title>
  <form *ngIf="moveFormGroup" #moveForm="ngForm" [formGroup]="moveFormGroup" (ngSubmit)="onSubmit()" class="mt-2">
    <div class="flex flex-col md:flex-row">
      <div class="mr-5 flex w-full flex-col md:w-8/12">
        <div class="mb-5 flex flex-col justify-between md:flex-row">
          <div class="mr-5 mb-5 flex w-full md:mb-0 md:w-4/12">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Postleitzahl</mat-label>
              <input formControlName="oldPostcode" type="text" matInput>
            </mat-form-field>
          </div>
          <div class="flex w-full md:flex-1">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Ort</mat-label>
              <input formControlName="oldCity" type="text" matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-col justify-between md:flex-row">
          <div class="mr-5 mb-5 flex w-full md:mb-0 md:flex-1">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Straße</mat-label>
              <input formControlName="oldStreet" type="text" matInput>
            </mat-form-field>
          </div>
          <div class="flex w-full md:w-4/12">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Hausnummer</mat-label>
              <input formControlName="oldHouseNumber" type="text" matInput>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="mt-5 flex w-full flex-col md:mt-0 md:flex-1">
        <mat-form-field class="w-full" appearance="fill" floatLabel='always'>
          <mat-label>Auszugsdatum</mat-label>
          <input placeholder="TT.MM.JJJJ" matInput [matDatepicker]="pickerMoveOut" formControlName="moveOutDate">
          <mat-datepicker-toggle matSuffix [for]="pickerMoveOut"></mat-datepicker-toggle>
          <mat-datepicker #pickerMoveOut></mat-datepicker>
          <mat-error *ngIf="moveFormGroup.controls.moveOutDate.errors?.required">
            Pflichtfeld
          </mat-error>
          <mat-error class="flex items-center" *ngIf="moveFormGroup.controls.moveOutDate.errors?.outOfRange">
            <mat-icon>error</mat-icon>
            <span>
              Geben Sie bitte ein Datum zwischen {{minDate | date: 'dd.MM.yyyy'}} und {{maxDate | date: 'dd.MM.yyyy'}} an.
            </span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <span class="mat-title flex !my-0 !mt-8">
      Neue Lieferstelle
    </span>
    <div class="flex flex-col md:flex-row !mt-2">
      <div class="mr-5 flex w-full flex-col md:w-8/12">
        <div class="mb-5 flex flex-col justify-between md:flex-row">
          <div class="mr-5 mb-5 flex w-full md:mb-0 md:w-4/12">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Postleitzahl</mat-label>
              <input formControlName="newPostcode"
                     type="text"
                     matInput
                     [maxlength]="postcodeMaximumLength">
              <mat-error class="flex items-center" *ngIf="moveFormGroup.controls.newPostcode.errors?.required">
                <mat-icon>error</mat-icon>
                Bitte geben Sie Ihre Postleitzahl an.
              </mat-error>
              <mat-error class="flex items-center" *ngIf="moveFormGroup.controls.newPostcode.errors?.minlength">
                <mat-icon>error</mat-icon>
                Die Postleitzahl darf minimal {{postcodeMaximumLength}} Stellen haben.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex w-full md:flex-1">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Ort</mat-label>
              <input formControlName="newCity"
                     type="text"
                     matInput
                     [maxlength]="cityMaximumLength"
                     [matAutocomplete]="autoCity">
              <mat-autocomplete autoActiveFirstOption #autoCity="matAutocomplete">
                <mat-option *ngFor="let option of filteredCities" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
              <mat-error class="flex justify-center" *ngIf="moveFormGroup.controls.newCity.errors?.required">
                <mat-icon>error</mat-icon>
                Bitte geben Sie Ihren Wohnort an.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-col justify-between md:flex-row">
          <div class="mr-5 mb-5 flex w-full md:mb-0 md:flex-1">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Straße</mat-label>
              <input formControlName="newStreet"
                     type="text"
                     matInput
                     [maxlength]="streetMaximumLength"
                     [matAutocomplete]="autoStreet">
              <mat-autocomplete #autoStreet="matAutocomplete">
                <mat-option *ngFor="let option of filteredStreets" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
              <mat-error class="flex items-center" *ngIf="moveFormGroup.controls.newStreet.errors?.required">
                <mat-icon>error</mat-icon>
                Bitte geben Sie Ihre Straße an.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mr-5 flex w-full md:w-4/12">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Hausnummer</mat-label>
              <input formControlName="newHouseNumber"
                     type="text"
                     matInput
                     [maxlength]="houseNumberMaximumLength">
              <mat-error class="flex justify-center" *ngIf="moveFormGroup.controls.newHouseNumber.errors?.required">
                <mat-icon>error</mat-icon>
                Bitte geben Sie Ihre Hausnummer an.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex w-full md:w-4/12">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Zählernummer </mat-label>
              <input formControlName="newMeterNumber"
                     type="text"
                     matInput
                     [maxlength]="meterNumberMaximumLength">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="mt-5 flex w-full flex-col md:mt-0 md:flex-1">
        <mat-form-field appearance="fill" floatLabel='always' class="w-full">
          <mat-label>Einzugsdatum</mat-label>
          <input placeholder="TT.MM.JJJJ" matInput [matDatepicker]="pickerMoveIn" formControlName="moveInDate">
          <mat-datepicker-toggle matSuffix [for]="pickerMoveIn"></mat-datepicker-toggle>
          <mat-datepicker #pickerMoveIn></mat-datepicker>
          <mat-error *ngIf="moveFormGroup.controls.moveInDate.errors?.required">
            Pflichtfeld
          </mat-error>
          <mat-error class="flex items-center" *ngIf="moveFormGroup.controls.moveInDate.errors?.outOfRange">
            <mat-icon>error</mat-icon>
            Geben Sie bitte ein Datum zwischen {{minDate | date: 'dd.MM.yyyy'}}
            und {{maxDate | date: 'dd.MM.yyyy'}} an.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-between items-center !mt-10">
      <div class="w-full md:w-auto">
        <app-button caption="Absenden" color="primary" type="submit" [progressBarVisible]="isSaveProcessOngoing"></app-button>
      </div>
    </div>
  </form>
</div>
