import { AfterContentInit, Component, ContentChildren, Input, QueryList } from "@angular/core";
import { HistoryEntryState } from "../enumerations/history-entry-state";
import { HistoryEntryComponent } from "./history-entry.component";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html'
})
export class HistoryComponent implements AfterContentInit {
  @ContentChildren(HistoryEntryComponent) entries?: QueryList<HistoryEntryComponent>;

  @Input() shouldUseDefaultExpansionSettings: boolean = true;

  ngAfterContentInit() {
    setTimeout(() => {
      if (!this.entries?.length) {
        return;
      }
      this.prepareLineVisibility(this.entries);
      if (this.shouldUseDefaultExpansionSettings) {
        this.setDefaultExpansionSettings(this.entries);
      }
    });
  }

  private prepareLineVisibility(entries: QueryList<HistoryEntryComponent>) {
    entries.forEach((entry) => {
      entry.showLine = true;
    });
    entries.last.showLine = false;
  }

  private setDefaultExpansionSettings(entries: QueryList<HistoryEntryComponent>) {
    entries.forEach((entry) => {
      entry.isExpanded = false;
      entry.isExpandable = entry.state !== HistoryEntryState.Upcoming;
    });
    const lastNotUpcoming = entries.toArray().reverse().find(entry => entry.state !== HistoryEntryState.Upcoming);
    if (lastNotUpcoming) {
      lastNotUpcoming.isExpanded = true;
    }
  }

}
