<div class="mx-auto px-4 py-8 flex-1 flex flex-col">
  <ng-container *ngIf="(lastOrderCorrection.isInValueState || lastOrderCorrection.isInNoValueState) && orderCorrectionAvailability.isInValueState && orderCorrectionAvailability.value && orderCorrectionFormGroup && defaultOrderCorrection">
    <ng-container *ngIf="!orderCorrectionAvailability.value.isAvailable">
      <app-notification [notificationType]="NotificationType.Information" notification="Der Auftrag kann nicht mehr geändert werden."></app-notification>
    </ng-container>
    <ng-container *ngIf="orderCorrectionAvailability.value.isAvailable && selectedContract?.deliveryInformation?.energyType">
      <form #orderCorrectionForm="ngForm" [formGroup]="orderCorrectionFormGroup" class="flex-1 flex flex-col justify-between">
        <mat-horizontal-stepper [linear]="true" #stepper class="!flex !flex-col !flex-1">
          <mat-step *ngIf="settings.isStartPageEnabled">
            <div class="introWrapper">
              <span class="block text-center">
                <mat-icon class="material-symbols-outlined !text-[70px] !w-[72px] !h-[70px]">quick_reference_all</mat-icon>
              </span>
              <h1 class="text-center">Auftragsdaten</h1>
              <div *ngIf="settings.isTriggeredByErrorHandling" class="text-center">
                Bitte überprüfen Sie die vorliegenden Auftragsdaten.
              </div>
              <div *ngIf="!settings.isTriggeredByErrorHandling" class="text-center">
                Hier können Sie Änderungen an Ihren Auftragsdaten vornehmen.
              </div>
            </div>
            <div class="buttonWrapper mt-6 md:mt-10">
              <div class="flex w-full justify-center">
                <div class="w-full md:w-1/2">
                  <app-button color="primary" caption="Start" (click)="stepper.next()"></app-button>
                </div>
              </div>
              <span class="block text-center mat-body-2 my-3 primaryColor cursor-pointer" (click)="cancel()" color="primary">Auf später verschieben</span>
            </div>
          </mat-step>
          <mat-step *ngIf="settings.isMeterPageEnabled" #meterStep>
            <div class="introWrapper pb-5">
              <h1 class="text-center">Zählernummer / Marktlokations-ID</h1>
              <div *ngIf="settings.isTriggeredByErrorHandling" class="text-center">
                Bitte überprüfen Sie ihre hier Ihre Zählernummer und/oder Marktlokations-ID und nehmen Sie die notwendigen Anpassungen vor.
              </div>
              <div *ngIf="!settings.isTriggeredByErrorHandling" class="text-center">
                Hier haben Sie die Möglichkeit Ihre Zählernummer und/oder Marktlokations-ID anzupassen.
              </div>
              <div class="w-full flex flex-col md:flex-row md:flex-1 my-5 md:gap-x-5">
                <div class="w-full md:flex-1">
                  <mat-form-field class="w-full" appearance="fill">
                    <mat-label>Zählernummer</mat-label>
                    <input formControlName="meterNumber"
                           type="text"
                           matInput>
                    <mat-error *ngIf="orderCorrectionFormGroup.controls.meterNumber.errors?.maxlength">
                      Maximal {{maxLengthMeterNumber}} Zeichen
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="w-full md:flex-1 mt-5 md:mt-0">
                  <mat-form-field class="w-full" appearance="fill">
                    <mat-label>Marktlokations-ID</mat-label>
                    <input formControlName="marketLocation"
                           type="text"
                           matInput>
                    <mat-error *ngIf="orderCorrectionFormGroup.controls.marketLocation.errors?.pattern">
                      Genau 11 Zahlen
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <app-meter-faq [energyType]="selectedContract!.deliveryInformation!.energyType"></app-meter-faq>
            </div>
            <div class="buttonWrapper flex flex-row justify-between w-full items-center mt-6 md:mt-10">
              <span class="primaryColor cursor-pointer font-weight-bold" (click)="stepper.previous()">Zurück</span>
              <app-button class="!w-auto" color="primary" caption="Weiter" (click)="stepper.next()"
                          [disabled]="orderCorrectionFormGroup.controls.meterNumber.invalid ||
                          orderCorrectionFormGroup.controls.marketLocation.invalid"></app-button>
            </div>
          </mat-step>
          <mat-step *ngIf="settings.isDeliveryAddressPageEnabled" #deliveryAddressStep>
            <div class="introWrapper">
              <h1 class="text-center">Lieferstelle</h1>
              <div *ngIf="settings.isTriggeredByErrorHandling" class="text-center">
                Bitte überprüfen Sie die Adresse an der Sie mit Energie beliefert werden möchten.
              </div>
              <div *ngIf="!settings.isTriggeredByErrorHandling" class="text-center">
                Ist dies die Adresse an der Sie mit Energie beliefert werden möchten? Falls nicht, nehmen Sie bitte die notwendigen Anpassungen vor.
              </div>
              <div class="flex flex-col md:flex-row gap-x-5 mt-5 mb-0 md:mb-5">
                <div class="w-full md:w-2/3">
                  <mat-form-field class="w-full" appearance="fill">
                    <mat-label>Straße</mat-label>
                    <input formControlName="deliveryStreet"
                           type="text"
                           matInput>
                    <mat-error *ngIf="orderCorrectionFormGroup.controls.deliveryStreet.errors?.maxlength">
                      Maximal {{maxLengthDeliveryStreet}} Zeichen
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="w-full md:flex-1">
                  <mat-form-field class="w-full" appearance="fill">
                    <mat-label>Hausnummer</mat-label>
                    <input formControlName="deliveryHouseNumber"
                           type="text"
                           matInput>
                    <mat-error *ngIf="orderCorrectionFormGroup.controls.deliveryHouseNumber.errors?.maxlength">
                      Maximal {{maxLengthDeliveryHouseNumber}} Zeichen
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-col md:flex-row gap-x-5 mb-0 md:mb-5">
                <div class="w-full md:flex-1">
                  <mat-form-field class="w-full" appearance="fill">
                    <mat-label>Postleitzahl</mat-label>
                    <input formControlName="deliveryPostcode"
                           type="text"
                           matInput>
                    <mat-error *ngIf="orderCorrectionFormGroup.controls.deliveryPostcode.errors?.maxlength">
                      Maximal {{maxLengthDeliveryPostcode}} Zeichen
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="w-full md:w-2/3">
                  <mat-form-field class="w-full" appearance="fill">
                    <mat-label>Stadt</mat-label>
                    <input formControlName="deliveryCity"
                           type="text"
                           matInput>
                    <mat-error *ngIf="orderCorrectionFormGroup.controls.deliveryCity.errors?.maxlength">
                      Maximal {{maxLengthDeliveryCity}} Zeichen
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="buttonWrapper flex flex-row justify-between w-full items-center mt-6 md:mt-10">
              <span class="primaryColor cursor-pointer font-weight-bold" (click)="stepper.previous()">Zurück</span>
              <app-button class="!w-auto" color="primary" caption="Weiter" (click)="stepper.next()"
                          [disabled]="orderCorrectionFormGroup.controls.deliveryStreet.invalid ||
                          orderCorrectionFormGroup.controls.deliveryHouseNumber.invalid ||
                          orderCorrectionFormGroup.controls.deliveryPostcode.invalid ||
                          orderCorrectionFormGroup.controls.deliveryCity.invalid"></app-button>
            </div>
          </mat-step>
          <mat-step *ngIf="settings.isDeliveryStartPageEnabled" #deliveryStartStep>
            <div class="introWrapper">
              <h1 class="text-center">Lieferbeginn</h1>
              <div class="text-center">
                Sie möchten zu einem Wunschtermin beliefert werden? Wir prüfen gerne, ob der Lieferantenwechsel zu diesem Zeitpunkt für Sie möglich ist.
              </div>
              <div class="flex flex-col w-full mt-5">
                <mat-form-field class="w-full" appearance="fill" floatLabel='always'>
                  <mat-label>Lieferbeginn</mat-label>
                  <input placeholder="TT.MM.YYYY" matInput [matDatepicker]="pickerRequestedDeliveryDate" formControlName="requestedDeliveryStartDate">
                  <mat-datepicker-toggle matSuffix [for]="pickerRequestedDeliveryDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerRequestedDeliveryDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="w-full">
                <mat-checkbox color="primary" formControlName="isMove">
                  <span class="w-full">Das angegebene Datum ist mein Einzugstermin</span>
                </mat-checkbox>
              </div>
            </div>
            <div class="buttonWrapper flex flex-row justify-between w-full items-center mt-6 md:mt-10">
              <span class="primaryColor cursor-pointer font-weight-bold" (click)="stepper.previous()">Zurück</span>
              <app-button class="!w-auto" color="primary" caption="Weiter" (click)="stepper.next()"></app-button>
            </div>
          </mat-step>
          <mat-step *ngIf="settings.isPreviousSupplierPageEnabled" #previousSupplierStep>
            <div class="introWrapper">
              <h1 class="text-center">Kündigung des bisherigen Vertrags</h1>
              <div class="text-center mb-5">
                Bitte teilen Sie uns mit, ob wir Ihren Vorversorger für Sie kündigen sollen, indem Sie eine der folgenden Optionen auswählen.
              </div>
              <mat-form-field appearance="fill" class="w-full">
                <mat-label>Status</mat-label>
                <mat-select formControlName="previousSupplierTerminationState">
                  <mat-option [value]="PreviousSupplierTerminationState.RequestTermination">Ich möchte, dass {{ environment.brandName }} für mich kündigt</mat-option>
                  <mat-option [value]="PreviousSupplierTerminationState.AlreadyTerminated">Ich habe bereits gekündigt</mat-option>
                  <mat-option [value]="PreviousSupplierTerminationState.NoPreviousSupplier">Ich bin neu eingezogen und habe keinen Vorversorger</mat-option>
                </mat-select>
              </mat-form-field>
              <ng-container *ngIf="orderCorrectionFormGroup.controls.previousSupplierTerminationState.value === PreviousSupplierTerminationState.AlreadyTerminated">
                <div class="w-full flex flex-col md:flex-row md:flex-wrap mt-5 gap-x-5">
                  <div class="w-full md:flex-1 mt-5 md:mt-0">
                    <mat-form-field class="w-full" appearance="fill">
                      <mat-label>Name des Vorversorgers</mat-label>
                      <input formControlName="previousSupplierName"
                             type="text"
                             matInput>
                      <mat-error *ngIf="orderCorrectionFormGroup.controls.previousSupplierName.errors?.maxlength">
                        Maximal {{maxLengthPreviousSupplierName}} Zeichen
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-full md:w-4/12">
                    <mat-form-field class="w-full" appearance="fill" floatLabel='always'>
                      <mat-label>Vorversorger gekündigt zum</mat-label>
                      <input placeholder="TT.MM.YYYY" matInput [matDatepicker]="pickerTerminationDate" formControlName="previousSupplierTerminationDate">
                      <mat-datepicker-toggle matSuffix [for]="pickerTerminationDate"></mat-datepicker-toggle>
                      <mat-datepicker #pickerTerminationDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="w-full mt-2">
                  <mat-checkbox color="primary" formControlName="terminationConfirmationReceived">
                    Kündigungsbestätigung erhalten
                  </mat-checkbox>
                </div>
              </ng-container>
              <ng-container *ngIf="orderCorrectionFormGroup.controls.previousSupplierTerminationState.value === PreviousSupplierTerminationState.RequestTermination">
                <div class="w-full flex flex-col md:flex-row md:flex-wrap mt-5 gap-x-5">
                  <div class="w-full md:flex-1 mt-5 md:mt-0">
                    <mat-form-field class="w-full" appearance="fill">
                      <mat-label>Name des Vorversorgers</mat-label>
                      <input formControlName="previousSupplierName"
                             type="text"
                             matInput>
                      <mat-error *ngIf="orderCorrectionFormGroup.controls.previousSupplierName.errors?.maxlength">
                        Maximal {{maxLengthPreviousSupplierName}} Zeichen
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-full md:w-4/12">
                    <mat-form-field class="w-full" appearance="fill" floatLabel='always'>
                      <mat-label>Vorversorger kündigen zum</mat-label>
                      <input placeholder="TT.MM.YYYY" matInput [matDatepicker]="pickerTerminationDate" formControlName="previousSupplierTerminationDate">
                      <mat-datepicker-toggle matSuffix [for]="pickerTerminationDate"></mat-datepicker-toggle>
                      <mat-datepicker #pickerTerminationDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="orderCorrectionFormGroup.controls.previousSupplierTerminationState.value === PreviousSupplierTerminationState.AlreadyTerminated">
                <div class="infoWrapper border border-gray-300 rounded-lg p-4 mt-6 w-full">
                  <p>Wenn Sie keine Kündigungsbestätigung erhalten haben, beauftragen Sie uns gerne mit der Kündigung Ihres Vorversorgers.</p>
                </div>
              </ng-container>
              <ng-container *ngIf="orderCorrectionFormGroup.controls.previousSupplierTerminationState.value === PreviousSupplierTerminationState.RequestTermination">
                <div class="infoWrapper border border-gray-300 rounded-lg p-4 mt-6 w-full">
                  <p>Bei einem Sonderkündigungsrecht z.B. aufgrund einer Preiserhöhung müssen Sie Ihre Kündigung selbst aussprechen.</p>
                </div>
              </ng-container>
            </div>
            <div class="buttonWrapper flex flex-row justify-between w-full items-center mt-6 md:mt-10">
              <span class="primaryColor cursor-pointer font-weight-bold" (click)="stepper.previous()">Zurück</span>
              <app-button class="!w-auto" color="primary" caption="Weiter" (click)="stepper.next()"
                          [disabled]="orderCorrectionFormGroup.controls.previousSupplierName.invalid"></app-button>
            </div>
          </mat-step>
          <mat-step *ngIf="settings.isContractorPageEnabled" #contractorStep>
            <div class="introWrapper">
              <h1 class="text-center">Zusätzliche Vertragspartner</h1>
              <div class="text-center mb-5">
                Für einen erfolgreichen Lieferantenwechsel ist es wichtig, dass der Name der Vertragspartner mit den hinterlegten Daten beim Vorversorger und Netzbetreiber übereinstimmen.
              </div>
              <h2>Erster zusätzlicher Vertragspartner</h2>
              <div class="w-full mt-5">
                <mat-form-field class="w-full" appearance="fill">
                  <mat-label>Vorname</mat-label>
                  <input formControlName="firstDeviatingContractorFirstName"
                         type="text"
                         matInput>
                  <mat-error *ngIf="orderCorrectionFormGroup.controls.firstDeviatingContractorFirstName.errors?.maxlength">
                    Maximal {{maxLengthDeviatingContractorFirstName}} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="w-full mt-5 md:mt-0 mb-5">
                <mat-form-field class="w-full" appearance="fill">
                  <mat-label>Nachname</mat-label>
                  <input formControlName="firstDeviatingContractorLastName"
                         type="text"
                         matInput>
                  <mat-error *ngIf="orderCorrectionFormGroup.controls.firstDeviatingContractorLastName.errors?.maxlength">
                    Maximal {{maxLengthDeviatingContractorLastName}} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>
              <h2>Zweiter zusätzlicher Vertragspartner</h2>
              <div class="w-full mt-5">

                <mat-form-field class="w-full" appearance="fill">
                  <mat-label>Vorname</mat-label>
                  <input formControlName="secondDeviatingContractorFirstName"
                         type="text"
                         matInput>
                  <mat-error *ngIf="orderCorrectionFormGroup.controls.secondDeviatingContractorFirstName.errors?.maxlength">
                    Maximal {{maxLengthDeviatingContractorFirstName}} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="w-full mt-5 md:mt-0">
                <mat-form-field class="w-full" appearance="fill">
                  <mat-label>Nachname</mat-label>
                  <input formControlName="secondDeviatingContractorLastName"
                         type="text"
                         matInput>
                  <mat-error *ngIf="orderCorrectionFormGroup.controls.secondDeviatingContractorLastName.errors?.maxlength">
                    Maximal {{maxLengthDeviatingContractorLastName}} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="infoWrapper border border-gray-300 rounded-lg p-4 mt-6 w-full">
              <p>
                Sollten Sie beabsichtigen einen weiteren Vertragspartner über das obenstehende Formular hinzuzufügen, beachten sie bitte, dass dieser auch über das angefügte <a class="font-bold underline" href="{{ environment.membershipApplicationFormUrl }}" target="_blank"> Beitrittsformular (PDF)</a> angemeldet werden muss. Dazu laden sie das <a class="font-bold mx-1 underline" href="{{ environment.membershipApplicationFormUrl }}" target="_blank"> Beitrittsformular</a> herunter und senden es unterschrieben an uns zurück.
              </p>
            </div>
            <div class="buttonWrapper flex flex-row justify-between w-full items-center mt-6 md:mt-10">
              <span class="primaryColor cursor-pointer font-weight-bold" (click)="stepper.previous()">Zurück</span>
              <app-button class="!w-auto" color="primary" caption="Weiter" (click)="stepper.next()"
                          [disabled]="orderCorrectionFormGroup.controls.firstDeviatingContractorFirstName.invalid ||
                          orderCorrectionFormGroup.controls.firstDeviatingContractorLastName.invalid ||
                          orderCorrectionFormGroup.controls.secondDeviatingContractorFirstName.invalid ||
                          orderCorrectionFormGroup.controls.secondDeviatingContractorLastName.invalid"></app-button>
            </div>
          </mat-step>
          <mat-step>
            <div class="introWrapper">
              <h1 class="text-center">Fast geschafft</h1>
              <div class="text-center">
                Mit Absenden der Daten übernimmt unser Kundenservice die weitere Bearbeitung Ihrer Änderungswünsche.<br /> Sollten noch Rückfragen bestehen setzen wir uns mit Ihnen in Verbindung. Wir halten Sie über den aktuellen Status auf dem Laufenden.
              </div>
            </div>
            <div class="buttonWrapper flex flex-col mt-6 md:mt-10">
              <div class="flex w-full justify-center">
                <div class="w-full md:w-1/2 mb-5">
                  <app-button class="mb-6" color="primary" caption="Angaben absenden" [progressBarVisible]="orderCorrectionSaveOperation.isInLoadingState" (click)="save()"></app-button>
                </div>
              </div>
              <span class="mb-4 text-center primaryColor cursor-pointer font-weight-bold" (click)="stepper.previous()">Zurück</span>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
        <div class="flex justify-center items-center">
          <div *ngFor="let step of stepper.steps">
            <div *ngIf="step !== stepper.selected" class="w-2 mx-2 h-2 bg-gray-200 rounded-full"></div>
            <div *ngIf="step === stepper.selected" class="w-2 mx-2 h-2 bg-gray-400 rounded-full"></div>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="lastOrderCorrection.isInErrorState || orderCorrectionAvailability.isInErrorState || orderCorrectionAvailability.isInNoValueState">
    <app-notification [notificationType]="NotificationType.Error" notification="Ihre Auftragsdaten können zurzeit nicht angezeigt werden – Bitte versuchen Sie es später erneut."></app-notification>
  </ng-container>
  <ng-container *ngIf="lastOrderCorrection.isInLoadingState || orderCorrectionAvailability.isInLoadingState">
    <div class="flex justify-center w-full">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</div>
