<table *ngIf="contract" class="w-full" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="meterNumber">
    <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Zählernummer</th>
    <td mat-cell *matCellDef="let meterReading">{{meterReading.meterNumber}}</td>
  </ng-container>
  <ng-container matColumnDef="readingDate">
    <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Zählerstandsdatum</th>
    <td mat-cell *matCellDef="let meterReading">{{meterReading.readingDate|date:'mediumDate'}}</td>
  </ng-container>
  <ng-container matColumnDef="readingValue">
    <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Zählerstand</th>
    <td mat-cell *matCellDef="let meterReading">
      <span>{{meterReading.readingValue}}</span>
      <span *ngIf="contract.deliveryInformation.energyType === EnergyType.Electricity"> kWh</span>
      <span *ngIf="contract.deliveryInformation.energyType === EnergyType.Gas"> m³</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="information">
    <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Zählerstandsinformation</th>
    <td mat-cell *matCellDef="let meterReading">{{meterReading.information}}</td>
  </ng-container>
  <ng-container matColumnDef="readingFrom">
    <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Erfasst durch</th>
    <td mat-cell *matCellDef="let meterReading">{{meterReading.readingFrom}}</td>
  </ng-container>
  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Erfassungsdatum</th>
    <td mat-cell *matCellDef="let meterReading">{{meterReading.createdAt|date:'mediumDate'}}</td>
  </ng-container>
  <ng-container matColumnDef="isImported">
    <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Geprüft</th>
    <td mat-cell *matCellDef="let meterReading">
      <span *ngIf="meterReading.isImported">Ja</span>
      <span *ngIf="!meterReading.isImported">Nein</span>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [hidden]="!contract" [pageSizeOptions]="[5]" showFirstLastButtons></mat-paginator>
