<div class="flex">
  <h1 class="mat-title">Gesamtbilanz</h1>
</div>
<div class="flex flex-col w-full">
  <p>
    Die Gesamtbilanz entspricht der Summe Ihrer Monatsbilanzen seit der letzen Abrechnung. Sie basiert auf analytisch ermittelten Daten und zeigt Trends in der allgemeinen Kontounter- oder -überzahlung, nicht Ihren aktuellen Kontostand.
  </p>
</div>
<div class="flex mt-8">
  <button mat-dialog-close class="!outline-0 !h-auto primaryColor mat-body-2">
    Schließen
  </button>
</div>
