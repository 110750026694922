import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Contract } from "../../shared/interfaces/contract";
import { switchMap, takeUntil } from "rxjs/operators";
import { combineLatest, of, Subject } from "rxjs";
import { CustomerSelectionService } from "../../shared/services/customer-selection.service";
import { EnergyType } from "../../shared/enumerations/energy-type";
import { environment } from "../../../../environments/environment";
import { Meter } from '../../meter-reading/interfaces/meter';
import { MeterReadingService } from '../../meter-reading/services/meter-reading.service';
import { PriceDetail } from '../../price/interfaces/price-detail';
import * as lodash from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { TariffType } from '../../price/enumerations/tariff-type';
import { PriceAutoLoadService } from '../../price/services/price-auto-load.service';
import { OrderService } from '../../order-correction/services/order.service';
import { ObservableDisplayState } from '../../observable-status/enumerations/observable-display-state';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { determineGroupedStatus, withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { NotificationType } from "../../shared/enumerations/notification-type";
import { OrderStatusTimeline } from "../../order-correction/interfaces/orders-status-timeline";
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent } from '../../information-dialog/components/information-dialog.component';
import { HistoryEntryState } from '../../history/enumerations/history-entry-state';
import { OrderStatusErrorReason } from '../../order-correction/enumerations/order-status-error-reason';
import { OrderCorrectionStepperDialogComponent } from '../../order-correction/components/order-correction-stepper-dialog.component';
import { OrderCorrectionSettings } from '../../order-correction/interfaces/order-correction-settings';
import { OrderCorrectionStepperSettings } from '../../order-correction/interfaces/order-correction-stepper-settings';

@Component({
  selector: 'app-contract-overview',
  templateUrl: './contract-overview.component.html'
})
export class ContractOverviewComponent implements OnInit, OnDestroy {

  @HostBinding('class') flexClass = 'flex-1';

  private refresh$: Subject<void> = new Subject<void>();
  private destroyed$: Subject<void> = new Subject<void>();

  EnergyType = EnergyType;
  TariffType = TariffType;
  ObservableDisplayState = ObservableDisplayState;
  NotificationType = NotificationType;
  HistoryEntryState = HistoryEntryState;
  OrderStatusErrorReason = OrderStatusErrorReason;

  lodash = lodash;
  environment = environment;
  selectedContract: Contract | null = null;
  meter: Meter | null = null;
  orderCorrectionSettings: OrderCorrectionSettings | null = null;
  currentPrice: PriceDetail | null = null; /* TODO: PriceAutoLoadService doesn't support ObservableDisplayState by now */
  orderStatusTimeline: OrderStatusTimeline | null = null;

  meterLoadingState: ObservableDisplayState = ObservableDisplayState.None;
  orderCorrectionSettingsLoadingState: ObservableDisplayState = ObservableDisplayState.None;
  orderStatusTimelineLoadingState: ObservableDisplayState = ObservableDisplayState.None;
  groupedLoadingState: ObservableDisplayState = ObservableDisplayState.None;

  constructor(
    public priceAutoLoadService: PriceAutoLoadService,
    private customerSelectionService: CustomerSelectionService,
    private meterReadingService: MeterReadingService,
    private orderService: OrderService,
    protected router: Router,
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    let shouldOpenOrderCorrectionDialog = this.activatedRoute.snapshot.queryParamMap.get('orderCorrection') === 'true';
    let isDialogOpened = false;
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of([
              ObservableStatus.none<Meter>(),
              ObservableStatus.none<OrderCorrectionSettings>(),
              ObservableStatus.none<OrderStatusTimeline>()
            ] as [ObservableStatus<Meter>, ObservableStatus<OrderCorrectionSettings>, ObservableStatus<OrderStatusTimeline>])
          }
          return combineLatest([
            withNormalLoadingStatus(
              this.meterReadingService.getActiveMeter(this.selectedContract.contractId)),
            withNormalLoadingStatus(
              this.orderService.getOrderCorrectionSettings(this.selectedContract.contractId)),
            withNormalLoadingStatus(
              this.orderService.getOrderStatusTimeline(this.selectedContract.contractId))
          ]);
        }),
        takeUntil(this.destroyed$)
      ).subscribe(([meterStatus, orderCorrectionSettingsStatus, orderStatusTimeline]) => {
        this.meterLoadingState = meterStatus.displayState;
        this.orderCorrectionSettingsLoadingState = orderCorrectionSettingsStatus.displayState;
        this.orderStatusTimelineLoadingState = orderStatusTimeline.displayState;
        this.groupedLoadingState = determineGroupedStatus([this.meterLoadingState, this.orderCorrectionSettingsLoadingState, this.orderStatusTimelineLoadingState]);
        this.meter = meterStatus.value;
        this.orderCorrectionSettings = orderCorrectionSettingsStatus.value;
        this.orderStatusTimeline = orderStatusTimeline.value;
        if (shouldOpenOrderCorrectionDialog && this.orderCorrectionSettings && !isDialogOpened)
        {
          this.openOrderCorrectionDialog(this.orderCorrectionSettings.stepperSettings);
          isDialogOpened = true;
        }
      });
    this.priceAutoLoadService
      .currentPrice$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(currentPrice => {
        this.currentPrice = currentPrice;
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

  openCalculatedYearlyConsumptionDialog(): void {
    this.matDialog.open(InformationDialogComponent, {
      data: {
        title: "Errechneter Verbrauch",
        message: "Der errechnete Verbrauch basiert auf einer Prognose, die mithilfe der letzten bei uns erfassten Zählerstände erstellt wurde."
      },
    });
  }

  openCustomerDeclaredYearlyConsumptionDialog(): void {
    this.matDialog.open(InformationDialogComponent, {
      data: {
        title: "Kundenangabe",
        message: "Die Kundenangabe basiert auf dem Verbrauch, den Sie uns mitgeteilt haben."
      },
    });
  }

  getPreviousSupplierLabel(): string | null {
    switch (this.selectedContract?.deliveryInformation.energyType) {
      case EnergyType.Gas:
        return 'Gasversorger';
      case EnergyType.Electricity:
        return 'Stromversorger';
      default:
        return null;
    }
  }

  openOrderCorrectionDialog(settings: OrderCorrectionStepperSettings): void {
    this.matDialog.open(OrderCorrectionStepperDialogComponent, {
      data: settings,
      panelClass: ['md:w-2/3', '!max-w-[100vw]', 'flex', 'flex-col', 'justify-between', 'orderCorrection', '!rounded-0', 'min-h-screen', 'md:min-h-0']
    });
  }

}
