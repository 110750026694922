<div class="container mx-auto px-4 py-8">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Zahlungsweise anpassen
    </h1>
    <span class="mat-title !my-0">
      Zahlung per
    </span>
  </mat-card-title>
  <ng-container *ngIf="originalPaymentInformation && accountInformation && paymentInformationFormGroup && selectedContract">
    <form (ngSubmit)="save()" [formGroup]="paymentInformationFormGroup" #paymentInformationForm="ngForm" class="mt-2">
      <fieldset [disabled]="isSaveProcessOngoing">
        <div class="flex-col md:flex-row">
          <div class="w-full md:w-8/12 flex-col md:flex-row">
            <div class="w-full mb-5">
              <mat-form-field appearance="fill" class="w-full">
                <mat-label>Zahlungsart</mat-label>
                <mat-select formControlName="paymentMethod">
                  <mat-option *ngFor="let paymentMethod of paymentMethodModels"
                              [value]="paymentMethod.value">{{paymentMethod.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <ng-container *ngIf="paymentInformationFormGroup.controls.paymentMethod.value === PaymentMethod.DirectDebit">
              <div class="w-full flex flex-col md:flex-row gap-x-5">
                <div class="w-full md:flex-1 mb-5 md:mb-0">
                  <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Kontoinhaber</mat-label>
                    <input type="text" matInput formControlName="accountHolder">
                    <mat-error *ngIf="paymentInformationFormGroup.controls.accountHolder.errors?.required">Pflichtfeld</mat-error>
                    <mat-error *ngIf="paymentInformationFormGroup.controls.accountHolder.errors?.maxlength">Maximal {{accountHolderMaxLength}} Zeichen</mat-error>
                    <mat-error *ngIf="paymentInformationFormGroup.controls.accountHolder.errors?.accountHolderInvalidCharacters">Ungültige Zeichen</mat-error>
                  </mat-form-field>
                </div>
                <div class="w-full md:flex-1">
                  <mat-form-field appearance="fill" class="w-full">
                    <mat-label>IBAN</mat-label>
                    <input matInputBlurValue [blurValue]="paymentInformationService.blurIban(paymentInformationFormGroup!.controls.iban.value)" matInput formControlName="iban">
                    <mat-error *ngIf="paymentInformationFormGroup.controls.iban.errors?.required">Pflichtfeld</mat-error>
                    <mat-error *ngIf="paymentInformationFormGroup.controls.iban.errors?.iban">IBAN ungültig</mat-error>
                    <mat-error *ngIf="paymentInformationFormGroup.controls.iban.errors?.maxlength">Maximal {{ibanMaxLength}} Zeichen</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="paymentInformationFormGroup.controls.paymentMethod.value === PaymentMethod.BankTransfer">
            <div class="flex flex-col md:flex-row gap-x-5 mt-10">
              <div class="w-full md:w-8/12">
                <h2 class="mat-body-2 !my-0">
                  <span class="block">Bitte überweisen Sie den monatlichen Zahlbetrag auf dieses Konto.</span>
                  <span *ngIf="epcQrCode" class="hidden md:block">Nutzen Sie dazu auch gerne den EPC-QR-Code.</span>
                </h2>
                <div class="flex flex-col w-full mt-5">
                  <div class="mb-2"><span class="mat-body-2">BIC:</span> {{accountInformation.bic}}</div>
                  <div class="mb-2"><span class="mat-body-2">IBAN:</span> {{accountInformation.iban}}</div>
                  <div class="mb-2"><span class="mat-body-2">Kreditinstitut:</span> {{accountInformation.bankName}}</div>
                  <div class="mb-2"><span class="mat-body-2">Empfänger:</span> {{accountInformation.companyName}}</div>
                  <div class="mb-2"><span class="mat-body-2">Verwendungszweck:</span> {{selectedContract.contractId}}</div>
                  <div *ngIf="monthlyPaymentDetail" class="mb-2"><span class="mat-body-2">Betrag:</span>{{monthlyPaymentDetail.nextPayment.currentMonthlyPayment|number}} €</div>
                </div>
              </div>
              <div *ngIf="epcQrCode" class="w-full hidden md:flex md:flex-col md:flex-1">
                <h2 class="mat-body-2 !my-0">
                  Einfach per Bank App einscannen:
                </h2>
                <qrcode
                  class="block mt-5"
                  [qrdata]="epcQrCode"
                  [allowEmptyString]="true"
                  colorDark='#000000ff'
                  colorLight='#ffffffff'
                  elementType='canvas'
                  errorCorrectionLevel='M'
                  [imageHeight]=75
                  [imageWidth]=75
                  [margin]=0
                  [scale]=1
                  title='EPC-QR-Code für Ihre Banking-App'
                  [width]=200>
                </qrcode>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="flex w-full mt-10">
          <div class="w-full md:w-auto">
            <app-button caption="Absenden" color="primary" type="submit" [progressBarVisible]="isSaveProcessOngoing"></app-button>
          </div>
        </div>
      </fieldset>
    </form>
  </ng-container>
  <ng-container *ngIf="!originalPaymentInformation || !accountInformation || !paymentInformationFormGroup || !selectedContract">
    <div class="flex justify-center w-full">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</div>
