import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-dashboard-credit-settlement-card',
  templateUrl: './dashboard-credit-settlement-card.component.html'
})
export class DashboardCreditSettlementCardComponent {

  environment = environment;

}
