<ng-container *ngIf="data">
  <div class="flex justify-between">
    <h1 class="mat-title">Verbrauchskosten</h1>
  </div>
  <div class="flex flex-col w-full">
    <p>
      Ihre Verbrauchskosten ergeben sich aus Ihrem prognostiziertem Verbrauch multipliziert mit dem durchschnittlichem Arbeitspreis des jeweiligen Monats.
    </p>
    <div>
      <div class="priceWrapper w-full flex mt-4 items-start md:items-center">
        <div class="apWrapper flex flex-col bg-[#F7F7F7] p-3 rounded mr-5 text-center">
          <span class="mat-headline !my-0">{{ data.workingPriceInCentGross | number:'1.2-2' }}</span>
          <span class="text-[10px] leading-4">Ø ct/kWh</span>
        </div>
        <div class="sumRow flex flex-1 flex-row flex-wrap">
          <div class="upperRow w-full flex flex-wrap items-end">
            <span class="mat-headline !my-0 w-full md:w-auto">{{ data.consumptionPriceInEuroGross | number:'1.2-2' }} €</span>
            <span class="text-[#C8C8C8] md:ml-2">({{ data.monthlyConsumptionInKwh }} kWh)</span>
          </div>
          <div class="bottomRow w-full flex flex-wrap">
            <span class="w-full md:w-auto">Verbrauchspreis</span>
            <span class="md:ml-1">{{ accountStatementService.createDate(data) | date:'MMMM y' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex mt-8">
    <button mat-dialog-close class="!outline-0 !h-auto primaryColor mat-body-2">
      Schließen
    </button>
  </div>
</ng-container>
