<div class="flex flex-col w-full my-14">
  <p class="border-b mat-title">
    Die ENSTROGA Energie-Floater: Aktuelle Beschaffungspreise der Basiskomponenten Strom und Gas
  </p>
  <ng-container *ngIf="dayaheadBasePricesOverview.isInValueState && dayaheadBasePricesOverview.value">
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 my-2">
        <span class="mat-title">STROM</span><br>
        <span>Vormonat: {{ dayaheadBasePricesOverview.value.lastMonthElectricity.averagePriceInCentPerKwhNet | number  }} ct/kWh* </span><br>
        <span>laufender Monat: {{ dayaheadBasePricesOverview.value.currentMonthElectricity.averagePriceInCentPerKwhNet | number }} ct/kWh*</span>
      </div>
      <div class="w-full md:w-1/2 my-2">
        <span class="mat-title">GAS</span><br>
        <span>Vormonat: {{ dayaheadBasePricesOverview.value.lastMonthGas.averagePriceInCentPerKwhNet | number }} ct/kWh* </span><br>
        <span>laufender Monat: {{ dayaheadBasePricesOverview.value.currentMonthGas.averagePriceInCentPerKwhNet | number }} ct/kWh*</span>
      </div>
      <div class="w-full">
        <span class="flex my-3">Stand: {{ dayaheadBasePricesOverview.value.recordedAt | date:'mediumDate' }}</span>
        <p class="text-[#C8C8C8]">
          *Durchschnittswert der EEX Spot-Preise (Day-Ahead) die von ENSTROGA innerhalb des Beschaffungsmonats zugrunde gelegt werden. Quelle: https://www.eex.com/. Preise exklusive vertraglich vereinbartem Lastprofilfaktor und Fixpreiskomponente. An den örtlichen Netzbetreiber zu entrichtenden Systemnutzungsentgelte, Steuern, Gebühren und Abgaben auf Netz und Energie, die KWK-Pauschale, Herkunftsnachweise, die Ökostrompauschale und den Ökostromförderbeitrag sind nicht enthalten.
        </p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="dayaheadBasePricesOverview.isInNoValueState || dayaheadBasePricesOverview.isInErrorState">
    <app-notification [notificationType]="NotificationType.Error" notification="Beschaffungspreise nicht verfügbar – Bitte versuchen Sie es später erneut."></app-notification>
  </ng-container>
  <ng-container *ngIf="dayaheadBasePricesOverview.isInLoadingState">
    <div class="flex flex-col items-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</div>
