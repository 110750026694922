import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EnergyType } from '../../shared/enumerations/energy-type';
import { PriceAutoLoadService } from '../../price/services/price-auto-load.service';
import { map, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Contract } from '../../shared/interfaces/contract';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { PriceDetail } from '../../price/interfaces/price-detail';
import { PriceService } from '../../price/services/price.service';
import { DailyPriceSnapshot } from '../../price/interfaces/daily-price-snapshot';
import { TariffType } from '../../price/enumerations/tariff-type';


@Component({
  selector: 'app-dashboard-tariff-card',
  templateUrl: './dashboard-tariff-card.component.html'
})
export class DashboardTariffCardComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  environment = environment;
  EnergyType = EnergyType;
  TariffType = TariffType;
  selectedContract: Contract | null = null;
  currentPrice: PriceDetail | null = null;
  dailyPriceSnapshot: DailyPriceSnapshot | null = null;
  isLoading: boolean = false;

  constructor(public priceService: PriceService, private customerSelectionService: CustomerSelectionService, public priceAutoLoadService: PriceAutoLoadService) {
  }

  ngOnInit(): void {
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
      });
    this.priceAutoLoadService.currentPrice$
      .pipe(takeUntil(this.destroyed$))
      .pipe(
        switchMap(currentPrice => {
          this.isLoading = true;
          if (currentPrice?.tariffType === TariffType.Index || currentPrice?.tariffType === TariffType.IndexHybrid) {
            return this.priceService.getDailyPriceSnapshotForToday(this.selectedContract!.contractId)
              .pipe(
                map(dailyPriceSnapshot => ({ currentPrice, dailyPriceSnapshot }))
              );
          } else {
            return of({ currentPrice, dailyPriceSnapshot: null });
          }
        })
      )
      .subscribe(result => {
        this.currentPrice = result.currentPrice;
        this.dailyPriceSnapshot = result.dailyPriceSnapshot;
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
