import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {KeyLabelViewModel} from "../interfaces/key-label-view-model";
import {Salutation} from "../enumerations/salutation";
import {CustomerType} from "../enumerations/customer-type";

@Injectable({
  providedIn: 'root'
})
export class ContactSelectionService {
  private selectedCustomerType$: BehaviorSubject<CustomerType | null> = new BehaviorSubject<CustomerType | null>(null);
  customerTypeSelected$ = this.selectedCustomerType$.asObservable();
  private readonly CUSTOMER_TYPE_KEY = 'SelectedCustomerType';

  constructor() {
    const selectedCustomerType = sessionStorage.getItem(this.CUSTOMER_TYPE_KEY);

    if (selectedCustomerType)
      this.selectedCustomerType$.next(JSON.parse(selectedCustomerType));
  }

  selectCustomerType(customerType: CustomerType) {
    sessionStorage.setItem(this.CUSTOMER_TYPE_KEY, JSON.stringify(customerType));
    this.selectedCustomerType$.next(customerType);
  }

  resetSelection() {
    sessionStorage.removeItem(this.CUSTOMER_TYPE_KEY);
    this.selectedCustomerType$.next(null);
  }

  getSalutationViewModels(): KeyLabelViewModel<Salutation>[] {
    return [
      {key: Salutation.None, label: 'Keine Angabe'},
      {key: Salutation.Mister, label: 'Herr'},
      {key: Salutation.Misses, label: 'Frau'},
      {key: Salutation.Family, label: 'Familie'}
    ];
  }
}
