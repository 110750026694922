import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../confirm-dialog/components/confirm-dialog.component";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  environment = environment;
  @Input() showTariff: boolean = false;

  logout() {
    this.authService.logout();
    this.router.navigate([this.environment.loginUrl]);
  }

  openDialog(message: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '280px',
      data: { message: message },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.logout();
      }
    });
  }

  constructor(public authService: AuthService, private router: Router, public dialog: MatDialog) {
  }

}
