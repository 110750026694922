import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { forkJoin, of, Subject, timer } from 'rxjs';
import { catchError, takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PaymentMethodModel } from '../interfaces/payment-method-model';
import { Contract } from '../../shared/interfaces/contract';
import { PaymentMethod } from '../enumerations/payment-method';
import { PaymentInformation } from '../interfaces/payment-information';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { PaymentInformationService } from '../services/payment-information.service';
import { environment } from '../../../../environments/environment';
import { BrandService } from '../services/brand.service';
import { AccountInformation } from '../interfaces/account-information';
import { MonthlyPaymentDetail } from "../../monthly-payment/interfaces/monthly-payment-detail";
import { MonthlyPaymentService } from "../../monthly-payment/services/monthly-payment.service";
import { CustomValidators } from '../../shared/validators/custom-validators';

@Component({
  selector: 'app-payment-information-edit',
  templateUrl: './payment-information-edit.component.html'
})
export class PaymentInformationEditComponent implements OnInit, OnDestroy {

  paymentMethodModels: PaymentMethodModel[] | null = null;
  private refresh$: Subject<void> = new Subject<void>();
  private destroyed$: Subject<void> = new Subject<void>();
  selectedContract: Contract | null = null;
  accountInformation: AccountInformation | null = null;
  @ViewChild('paymentInformationForm') paymentInformationForm: NgForm | null = null;
  PaymentMethod = PaymentMethod;
  paymentInformationFormGroup: FormGroup | null = null;
  originalPaymentInformation: PaymentInformation | null = null;
  epcQrCode: string | null = null;
  monthlyPaymentDetail: MonthlyPaymentDetail | null = null;
  isSaveProcessOngoing = false;
  environment = environment;

  accountHolderMaxLength : number = 50;
  ibanMaxLength : number = 34;

  constructor(
    private brandService: BrandService,
    private customerSelectionService: CustomerSelectionService,
    public paymentInformationService: PaymentInformationService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public monthlyPaymentService: MonthlyPaymentService) {
  }

  ngOnInit(): void {
    this.paymentMethodModels = this.paymentInformationService.getAllPossiblePaymentMethods();
    this.paymentInformationFormGroup = this.formBuilder.group({
      paymentMethod: [null, [Validators.required]],
      accountHolder: [null, [Validators.required, Validators.maxLength(this.accountHolderMaxLength), CustomValidators.accountHolder]],
      iban: [null, [Validators.required, CustomValidators.iban, Validators.maxLength(this.ibanMaxLength)]],
    });
    this.refresh$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.originalPaymentInformation = null;
        this.epcQrCode = null;
        this.accountInformation = null;
        this.monthlyPaymentDetail = null;
        if (!this.selectedContract) {
          return;
        }
        forkJoin(
          {
            loadAccontInformation: this.brandService
              .getAccountInformation(this.selectedContract.deliveryInformation.energyType)
              .pipe(catchError(() => of(false))),
            loadPaymentInformation: this.paymentInformationService
              .getPaymentInformation(this.selectedContract.contractId)
              .pipe(catchError(() => of(false))),
            loadMonthlyPaymentDetail: this.monthlyPaymentService.getMonthlyPaymentDetail(this.selectedContract.contractId)
              .pipe(catchError(() => of(false))),
            awaitMinimumLoadingTime: timer(environment.minimumLoadingTimeInMilliseconds)
          }
        ).subscribe(observer => {
          if (observer.loadPaymentInformation !== false) {
            this.originalPaymentInformation = observer.loadPaymentInformation as PaymentInformation;
            this.paymentInformationFormGroup!.patchValue(this.originalPaymentInformation);
          }
          if (observer.loadAccontInformation !== false) {
            this.accountInformation = observer.loadAccontInformation as AccountInformation;
          }
          if (observer.loadMonthlyPaymentDetail !== false) {
            this.monthlyPaymentDetail = observer.loadMonthlyPaymentDetail as MonthlyPaymentDetail;
          }
          if (this.selectedContract && this.monthlyPaymentDetail && this.accountInformation) {
            this.epcQrCode = this.getEpcQrCode(this.selectedContract, this.accountInformation, this.monthlyPaymentDetail);
          }
        });
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save(): void {
    /*TODO: Hack entfernen. Vernünftige Möglichkeit finden variable Validatoren einzubinden */
    if ((this.paymentInformationForm!.invalid && this.paymentInformationFormGroup?.controls.paymentMethod.value !== PaymentMethod.BankTransfer) || this.isSaveProcessOngoing) {
      return;
    }
    this.isSaveProcessOngoing = true;
    let createdPaymentInformation = {
      accountHolder: this.paymentInformationFormGroup?.controls.accountHolder.value,
      iban: this.paymentInformationFormGroup?.controls.iban.value,
      paymentMethod: this.paymentInformationFormGroup?.controls.paymentMethod.value,
    } as PaymentInformation;
    forkJoin(
      {
        updatePaymentInformation: this.paymentInformationService.updatePaymentInformation(this.selectedContract!.contractId, createdPaymentInformation)
          .pipe(catchError(() => of(false))),
        awaitMinimumProgressTime: timer(environment.minimumProgressTimeInMilliseconds)
      }
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(observer => {
        if (observer.updatePaymentInformation === false) {
          this.snackBar.open('Die Zahlungsinformationen konnten nicht gespeichert werden!', 'OK', {
            panelClass: 'snack-danger'
          });
        } else {
          this.snackBar.open('Zahlungsinformationen erfolgreich gespeichert.', 'OK', {
            panelClass: 'snack-success'
          });
          this.refresh();
        }
      }).add(() => {
        this.isSaveProcessOngoing = false;
      });
  }

  clear(): void {
    this.paymentInformationForm!.resetForm(this.originalPaymentInformation);
  }

  refresh(): void {
    this.refresh$.next();
  }

  getEpcQrCode(contract: Contract, accountInformation: AccountInformation, monthlyPaymentDetail: MonthlyPaymentDetail): string {
    let result = 'BCD\n' +
      '002\n' +
      '1\n' +
      'SCT\n' +
      accountInformation.bic + '\n' +
      accountInformation.companyName + '\n' +
      accountInformation.iban + '\n' +
      'EUR' + monthlyPaymentDetail.nextPayment.currentMonthlyPayment.toFixed(2) + '\n' +
      '\n' +
      '\n' +
      contract.contractId + '\n' +
      '\n';
    return result;
  }

}
