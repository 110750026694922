import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import moment from "moment";


@Injectable()
export class GermanDateAdapter extends DateAdapter<string> {

  private dateFormat: string = 'YYYY-MM-DD'

  getYear(date: string): number {
    let parsedDate = moment(date, this.dateFormat);
    return parsedDate.year();
  }

  getMonth(date: string): number {
    let parsedDate = moment(date, this.dateFormat);
    return parsedDate.month();
  }

  getDate(date: string): number {
    let parsedDate = moment(date, this.dateFormat);
    return parsedDate.date();
  }

  getDayOfWeek(date: string): number {
    let parsedDate = moment(date, this.dateFormat);
    return parsedDate.day();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
    }
    return ["Jan.", "Feb.", "Mär.", "Apr.", "Mai", "Jun.", "Jul.", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."];
  }

  getDateNames(): string[] {
    return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
    }
    return ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
  }

  getYearName(date: string): string {
    let parsedDate = moment(date, this.dateFormat);
    return `${parsedDate.year()}`;
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  getNumDaysInMonth(date: string): number {
    let parsedDate = moment(date, this.dateFormat);
    return parsedDate.daysInMonth();
  }

  clone(date: string): string {
    return `${date}`;
  }

  createDate(year: number, month: number, date: number): string {
    let parsedDate = moment([year, month, date]);
    return parsedDate.format(this.dateFormat);
  }

  today(): string {
    let parsedDate = moment();
    return parsedDate.format(this.dateFormat);
  }

  parse(value: any, parseFormat: any): string | null {
    if (value === '' || value === null) {
      return null;
    }
    let parsedDate = moment(value, parseFormat);
    if (parsedDate.isValid()) {
      return parsedDate.format(this.dateFormat);
    }
    return 'Invalid';
  }

  format(date: string, displayFormat: any): string {
    let parsedDate = moment(date, this.dateFormat);
    return parsedDate.format(displayFormat);
  }

  addCalendarYears(date: string, years: number): string {
    let parsedDate = moment(date, this.dateFormat).add(years, 'years');
    return parsedDate.format(this.dateFormat);
  }

  addCalendarMonths(date: string, months: number): string {
    let parsedDate = moment(date, this.dateFormat).add(months, 'months');
    return parsedDate.format(this.dateFormat);
  }

  addCalendarDays(date: string, days: number): string {
    let parsedDate = moment(date, this.dateFormat).add(days, 'days');
    return parsedDate.format(this.dateFormat);
  }

  toIso8601(date: string): string {
    let parsedDate = moment(date, this.dateFormat);
    return parsedDate.format(this.dateFormat);
  }

  isDateInstance(obj: any): boolean {
    if (!obj) {
      return false;
    }
    let parsedDate = moment(obj, this.dateFormat);
    return parsedDate.isValid();
  }

  isValid(date: string): boolean {
    if (date === '') {
      return true;
    }
    let parsedDate = moment(date, this.dateFormat);
    return parsedDate.isValid();
  }

  invalid(): string {
    return 'Invalid';
  }

}
