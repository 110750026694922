<div class="container flex flex-col mx-auto px-4 py-8">
  <div class="mat-subheading-2 w-full">
    <span>Tarif </span><span *ngIf="currentPrice" class="primaryColor">{{currentPrice.tariffName}}</span>
  </div>
  <div class="w-full lg:w-8/12">
    <ng-container *ngIf="!priceAutoLoadService.isLoading && !isLoading">
      <ng-container *ngIf="currentPrice && dailyPriceSnapshots && actualDailyPriceSnapshot">
        <div *ngIf="priceService.checkIfInFixedPricePeriod(currentPrice)" class="my-5 primaryColorFaded p-4 w-full rounded-[20px] p-4">
          <h2>Ihr Preis im ersten Monat</h2>
          <span>
            Bis zum {{currentPrice.tariffFixedPriceEndDate | date : 'mediumDate'}} ist die Preisfixierung noch aktiv. Ihr aktueller Arbeitspreis ist {{currentPrice.workingPriceInEuroPerKwhGross * 100 | number : '1.2-2'}} ct/kWh. Der Grundpreis beträgt {{currentPrice.basicPriceInEuroGross | number : '1.2-2'}} €/Monat.
          </span>
        </div>
        <ng-container *ngIf="actualDailyPriceSnapshot && tariffFormGroup">
          <app-daily-price-spiral [dailyPriceSnapshot]="actualDailyPriceSnapshot" [currentPrice]="currentPrice" [showTotal]="tariffFormGroup!.controls!.includeTaxesAndDuties!.value" [displayInCard]="false" [class]="wrapperClass">
          </app-daily-price-spiral>
          <ng-container *ngIf="!priceService.checkIfInFixedPricePeriod(currentPrice)">
            <span *ngIf="chartRangeAverage" class="mt-5 w-full flex">
              Der durchschnittliche Arbeitspreis im von Ihnen ausgewählten Zeitraum beträgt {{chartRangeAverage | roundUpDecimal : 1 | number:'1.1-1'}} ct/kWh.
            </span>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <div [hidden]="!currentPrice || !dailyPriceSnapshots || (currentPrice && priceService.checkIfInFixedPricePeriod(currentPrice))" class="w-full mt-10">
      <canvas id="priceChart" class="w-full">{{ chart }}</canvas>
      <div class="w-full my-3 text-right">
        Quelle: <a href="https://www.smard.de/home/datennutzung" target="_blank"> <span class="text-[#707070] underline">Bundesnetzagentur | SMARD.de</span></a>
      </div>
    </div>
    <form *ngIf="tariffFormGroup" [formGroup]="tariffFormGroup">
      <ng-container *ngIf="!priceAutoLoadService.isLoading && !isLoading">
        <ng-container *ngIf="currentPrice && dailyPriceSnapshots && selectedContract && !priceService.checkIfInFixedPricePeriod(currentPrice)">
          <div class="my-4 p-2 flex justify-between w-full bg-[#F7F7F7]">
            <mat-button-toggle-group class="w-full" formControlName="chartRange">
              <mat-button-toggle [value]="ChartRange.Year" class="flex-1">1 Jahr</mat-button-toggle>
              <mat-button-toggle [value]="ChartRange.Month" class="flex-1">1 Monat</mat-button-toggle>
              <mat-button-toggle [value]="ChartRange.Week" class="flex-1">1 Woche</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="text-[#808080]">
            <span>
              Seit dem {{currentPrice.dateFrom | date: 'mediumDate'}} beliefert {{environment.brandName}} Sie mit {{selectedContract.deliveryInformation.energyType === EnergyType.Electricity ? 'Strom' : 'Gas'}} in {{selectedContract.deliveryAddress.location.city}}.
              Ihr Vertrag hat eine Laufzeit von  {{currentPrice.contractTerm | time: false}}. Die Kündigungsfrist beträgt {{currentPrice.noticePeriod | time: false}} zum Ende der Vertragslaufzeit.
            </span>
          </div>
          <div class="my-4 py-4 px-3 w-full bg-[#F7F7F7]">
            <div class="flex justify-between w-full bg-[#F7F7F7]">
              <div class="w-auto">
                <p class="w-full mat-subheading-2 font-bold !mb-0">
                  Preisbestandteile
                </p>

              </div>
              <mat-slide-toggle color="primary" formControlName="includeTaxesAndDuties"></mat-slide-toggle>
            </div>

            <ng-container *ngIf="priceCompositionGroupStatus">
              <div class="space-y-2" *ngIf="priceCompositionGroupStatus.isInValueState && tariffFormGroup.controls.includeTaxesAndDuties.value">
                <p class="w-full text-[#808080] !mt-4">
                  <span>
                    Preise im {{ actualDailyPriceSnapshot?.day | date : 'MMMM YYYY' }}
                  </span>
                </p>
                <div *ngFor="let priceComponent of priceComponents">
                  <div class="flex justify-between" *ngIf="priceComponent.priceCompositionType === PriceComponentType.Stromsteuer">
                    <span>Stromsteuer</span>
                    <span>{{ priceComponent.priceCompositionDetailInEuroGross | number:'1.2-2' }} ct/kWh</span>
                  </div>
                  <div class="flex justify-between" *ngIf="priceComponent.priceCompositionType === PriceComponentType.KWK">
                    <span>KWK</span>
                    <span>{{ priceComponent.priceCompositionDetailInEuroGross | number:'1.2-2' }} ct/kWh</span>
                  </div>
                  <div class="flex justify-between" *ngIf="priceComponent.priceCompositionType === PriceComponentType.Netznutzungsentgelt">
                    <span>Netznutzungsentgelt</span>
                    <span>{{ priceComponent.priceCompositionDetailInEuroGross | number:'1.2-2' }} ct/kWh</span>
                  </div>
                  <div class="flex justify-between" *ngIf="priceComponent.priceCompositionType === PriceComponentType.Konzessionsabgabe">
                    <span>Konzessionsabgabe</span>
                    <span>{{ priceComponent.priceCompositionDetailInEuroGross | number:'1.2-2' }} ct/kWh</span>
                  </div>
                  <div class="flex justify-between" *ngIf="priceComponent.priceCompositionType === PriceComponentType.Offshore">
                    <span>Offshore</span>
                    <span>{{ priceComponent.priceCompositionDetailInEuroGross | number:'1.2-2' }} ct/kWh</span>
                  </div>
                  <div class="flex justify-between" *ngIf="priceComponent.priceCompositionType === PriceComponentType.NEV">
                    <span>NEV</span>
                    <span>{{ priceComponent.priceCompositionDetailInEuroGross | number:'1.2-2' }} ct/kWh</span>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="priceCompositionGroupStatus.isInLoadingState">
                <div class="flex justify-center w-full">
                  <mat-spinner></mat-spinner>
                </div>
              </ng-container>
              <ng-container *ngIf="priceCompositionGroupStatus.isInErrorState">
                <app-notification [notificationType]="NotificationType.Error" notification="Technischer Fehler, bitte später erneut versuchen."></app-notification>
              </ng-container>
              <ng-container *ngIf="priceCompositionGroupStatus.isInNoValueState">
                <app-notification [notificationType]="NotificationType.Warning" notification="Keine Preisinformationen verfügbar."></app-notification>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </form>
    <ng-container *ngIf="priceAutoLoadService.isLoading || isLoading">
      <div class="flex justify-center w-full">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="currentPrice && priceService.checkIfInFixedPricePeriod(currentPrice)">
      <app-notification [notificationType]="NotificationType.Information" notification="Hier sehen Sie in Zukunft Ihre Daten zum Tarif." class="my-5"></app-notification>
    </ng-container>
  </div>
</div>
