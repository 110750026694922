import {Component, HostBinding, Inject, Input, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderCorrectionNotification } from '../interfaces/order-correction-notification';
import { OrderCorrectionStepperSettings } from '../interfaces/order-correction-stepper-settings';
import { OrderCorrectionStepperDialogComponent } from './order-correction-stepper-dialog.component';
import { OrderCorrectionStepperComponent } from './order-correction-stepper.component';
import { OrderStatusTimeline } from "../interfaces/orders-status-timeline";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-order-correction-notification',
  templateUrl: './order-correction-notification.component.html',
  styleUrls: ['./order-correction-notification.component.scss']
})
export class OrderCorrectionNotificationComponent {

  constructor(private matDialog: MatDialog) {
  }

  environment = environment;

  @Input() notification: OrderCorrectionNotification | null = null;
  @Input() timeline: OrderStatusTimeline | null = null;

  openOrderCorrectionDialog(): void {
    if (!this.notification) {
      return;
    }
    this.matDialog.open(OrderCorrectionStepperDialogComponent, {
      data: this.notification.stepperSettings,
      panelClass: ['md:w-2/3', '!max-w-[100vw]', 'flex', 'flex-col', 'justify-between', 'orderCorrection', '!rounded-0', 'min-h-screen', 'md:min-h-0']
    });
  }

}
