import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountStatementEntry } from "../interfaces/account-statement-entry";
import { AccountStatementService } from "../services/account-statement.service";

@Component({
  selector: 'consumption-price-dialog',
  templateUrl: './consumption-price-dialog.component.html',
})
export class ConsumptionPriceDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AccountStatementEntry | null, public accountStatementService: AccountStatementService) {
  }
}
