import {Component, Input} from '@angular/core';
import {Favourite} from "../interfaces/favourite";
import {Router} from "@angular/router";
import {FavouriteType} from "../enumerations/favourite-type";

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html'
})
export class FavouritesComponent {
  @Input() favourites: Favourite[] = [];

  getRouteLabelDisplay(routeLabel: FavouriteType): string {
    switch (routeLabel) {
      case FavouriteType.SameTab:
        return "FAQ";
      case FavouriteType.NewTab:
        return "Kundenportal";
      case FavouriteType.NewWindow:
        return "Extern";
      case FavouriteType.Topic:
        return "Thema";
      case FavouriteType.Question:
        return "Frage";
      default:
        return "?";
    }
  }

  constructor(private router: Router) {
  }

  openFavourite(favourite: Favourite): void {
    switch (favourite.type) {
      case FavouriteType.SameTab:
        this.router.navigate(['', favourite.link]);
        break;

      case FavouriteType.NewTab:
        const url = this.router.serializeUrl(this.router.createUrlTree([favourite.link]));

        window.open(url, '_blank');
        break;

      case FavouriteType.NewWindow:
        const url1 = this.router.serializeUrl(this.router.createUrlTree([favourite.link]));

        window.open(url1, '_blank', 'location=1,status=1,scrollbars=1');
        break;

      case FavouriteType.Topic:
        this.router.navigate(['/faq/topics', favourite.link]);
        break;

      case FavouriteType.Question:
        alert('Das muss Jürgen sich noch überlegen');
        break;
    }
    // if (favourite.type === FavouriteType.NewTab) {
    //   const url = this.router.serializeUrl(this.router.createUrlTree([favourite.link]));
    //   window.open(url, '_blank');
    // } else {
    //   this.router.navigate(['/faq/' + favourite.link]);
    // }
  }

  protected readonly FavouriteType = FavouriteType;
}
