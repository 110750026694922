import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { NoteService } from "../services/note.service";
import { NoteCreation } from "../../shared/interfaces/note-creation";
import { catchError, forkJoin, of, Subject, takeUntil, timer } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomerSelectionService } from "../../shared/services/customer-selection.service";
import { Contract } from "../../shared/interfaces/contract";
import { environment } from "../../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactService} from "../services/contact.service";
import { ContactCategory } from '../interfaces/contact-category';
import {ContactReason} from "../interfaces/contact-reason";

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit, OnDestroy {

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();
  contactFormGroup: FormGroup | null = null;
  @ViewChild('contactForm') contactForm: NgForm | null = null;
  showSubCategories: boolean = false;
  showTextarea: boolean = false;
  possibleCategories: ContactCategory[] | null = null;
  isSaveProcessOngoing: boolean = false;
  selectedContract: Contract | null = null;
  maximumMessageLength: number = 500;
  environment = environment;
  selectedCategory: ContactCategory | null = null;
  selectedReason: ContactReason | null = null;
  @HostBinding('class') flexClass = 'flex-1';

  constructor(private contactService: ContactService,
    private noteService: NoteService,
    private snackBar: MatSnackBar,
    private customerSelectionService: CustomerSelectionService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() : void {
    this.contactFormGroup = this.formBuilder.group({
      mainCategory: [null, []],
      subCategory: [null, []],
      contactMessage: [null, [Validators.required, Validators.maxLength(this.maximumMessageLength)]]
    });
    this.refresh$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (!this.selectedContract) {
          return;
        }
        forkJoin(
          {
            loadContactCategories: this.contactService
              .getContactCategories()
              .pipe(catchError(() => of(false))),
            awaitMinimumLoadingTime: timer(environment.minimumLoadingTimeInMilliseconds)
          }
        ).subscribe(observer => {
          if (observer.loadContactCategories !== false) {
            this.possibleCategories = observer.loadContactCategories as ContactCategory[];
            this.contactService.replacePlaceholders(this.possibleCategories, environment);
          }
        });
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onMainCategoryChange(): void {
    this.contactFormGroup!.controls.contactMessage.setErrors(null);
    this.showTextarea = false;
    this.showSubCategories = false;
  }

  onSubCategoryChange() : void {
    this.contactFormGroup!.controls.contactMessage.setErrors(null);
    this.showTextarea = true;
  }

  onShowSubCategories(): void {
    this.showSubCategories = true;
  }

  refresh(): void {
    this.refresh$.next();
  }


  save(): void {
    if (this.contactForm!.invalid || this.isSaveProcessOngoing) {
      return;
    }
    this.isSaveProcessOngoing = true;
    let contractId = this.selectedContract!.contractId;
    let createdNote: NoteCreation = {
      contractId: contractId,
      isClosed: false,
      reason: this.selectedReason?.xiqReason ?? "",
      text: "Thema:" + this.selectedCategory?.selectionText +
        "\nAnliegen: " + this.selectedReason?.selectionText +
        "\n" + this.contactFormGroup!.controls.contactMessage.value ?? "",
      resubmissionDate: null,
    }
    forkJoin(
      {
        createNote: this.noteService.createNote(contractId, createdNote)
          .pipe(catchError(() => of(false))),
        awaitMinimumProgressTime: timer(environment.minimumProgressTimeInMilliseconds)
      }
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(observer => {
        if (observer.createNote === false) {
          this.snackBar.open('Die Nachricht konnte nicht verschickt werden!', 'OK', {
            panelClass: 'snack-danger'
          });
        } else {
          this.router.navigate([environment.contactMessageSent], { relativeTo: this.route });
        }
      }).add(() => {
        this.isSaveProcessOngoing = false;
      });
  }
}
