import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { environment } from '../../../environments/environment';
import { PaymentInformationComponent } from './components/payment-information.component';

const routes: Routes = [
  {
    title: 'Zahlungsinformationen | Kundenportal | ' + environment.brandName,
    path: environment.paymentInformationUrl,
    component: PaymentInformationComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PaymentInformationRoutingModule { }
