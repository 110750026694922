import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { DayaheadBasePricesOverview } from '../interfaces/dayahead-base-prices-overview';
import { DayaheadBasePriceService } from '../services/dayahead-base-price.service';
import { ObservableStatus } from '../../observable-status/classes/observable-status';

@Component({
  selector: 'app-dayahead-base-prices-overview',
  templateUrl: './dayahead-base-prices-overview.component.html',
})
export class DayaheadBasePricesOverviewComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  private refresh$ = new Subject<void>();

  environment = environment;
  NotificationType = NotificationType;

  dayaheadBasePricesOverview: ObservableStatus<DayaheadBasePricesOverview> = ObservableStatus.none<DayaheadBasePricesOverview>();

  constructor(
    private dayaheadBasePriceService: DayaheadBasePriceService
  ) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          return withNormalLoadingStatus(
            this.dayaheadBasePriceService.getDayaheadBasePricesOverview(environment.dayaheadPriceOverviewCountry)
          );
        }),
        takeUntil(this.destroyed$)
    ).subscribe(dayaheadBasePricesOverview => {
      this.dayaheadBasePricesOverview = dayaheadBasePricesOverview;    
    });
    this.refresh();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh() {
    this.refresh$.next();
  }

}
