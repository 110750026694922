import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, map, mergeMap, Observable, Subject, takeUntil } from 'rxjs';
import { Contract } from '../interfaces/contract';
import { User } from '../interfaces/user';
import { CustomerSelectionService } from "./customer-selection.service";
import * as jose from 'jose';
import { filter } from 'rxjs/operators';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class AuthService implements OnDestroy {

  private user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(this.getUserFromStorage());
  userChanged$ = this.user$.asObservable();
  private destroyed$: Subject<void> = new Subject<void>();

  get user(): User | null {
    return this.user$.value;
  }

  private set user(value: User | null) {
    if (this.user$.value !== value) {
      this.user$.next(value);
    }
  }

  private contract$: BehaviorSubject<Contract | null> = new BehaviorSubject<Contract | null>(this.getContractFromStorage());
  contractChanged$ = this.contract$.asObservable();

  get contract(): Contract | null {
    return this.contract$.value;
  }

  private set contract(value: Contract | null) {
    if (this.contract$.value !== value) {
      if (value) {
        this.customerSelectionService.selectContract(value);
      } else {
        this.customerSelectionService.resetSelection();
      }
      this.contract$.next(value);
    }
  }

  get jwt(): string | null {
    return sessionStorage.getItem('jwt');
  }

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private customerSelectionService: CustomerSelectionService, private router: Router) {
    interval(10000)
      .pipe(
        takeUntil(this.destroyed$),
        filter(() => !!this.isAuthenticated())
      )
      .subscribe(() => {
        let decoded = jose.decodeJwt(this.jwt!);
        let expirationDate = new Date(decoded.exp! * 1000);
        if (expirationDate < new Date()) {
          this.logout();
          this.router.navigate(['']);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getContractFromStorage(): Contract | null {
    let contractJson = sessionStorage.getItem('contract');
    if (!contractJson) {
      return null;
    }
    return JSON.parse(contractJson);
  }

  private getUserFromStorage(): User | null {
    let userJson = sessionStorage.getItem('user');
    if (!userJson) {
      return null;
    }
    return JSON.parse(userJson);
  }

  isAuthenticated(): boolean {
    return this.user != null;
  }

  login(jwt: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${jwt}`);
    return this.httpClient.get<User>(`${this.baseUrl}api/users/current`, { headers: headers }).pipe(mergeMap(user => {
      return this.httpClient.get<Contract>(`${this.baseUrl}api/contracts/${user.contractId}`, { headers: headers }).pipe(map(contract => {
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('contract', JSON.stringify(contract));
        sessionStorage.setItem('jwt', jwt);
        this.user = user;
        this.contract = contract;
      }));
    }));
  }

  logout(): void {
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('contract');
    this.user = null;
    this.contract = null;
  }

}
