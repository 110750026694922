import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { Move } from '../interfaces/move';

@Injectable({
  providedIn: 'root'
})
export class MoveService {

  constructor(private authService: AuthService, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  createMove(move: Move, contractId: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.post<any>(`${this.baseUrl}api/move/${contractId}`, move, { headers: headers });
  }

}
