import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class isValidators {
  static date(max: number, min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null) {
        return null;
      }
      let minDate = new Date(new Date(min).toDateString());
      let maxDate = new Date(new Date(max).toDateString());
      let controlDate = new Date(new Date(control.value).toDateString());
      let isValid = controlDate <= maxDate && controlDate >= minDate;
      let error = {
        'outOfRange': true
      };
      return isValid ? null : error;
    }
  }

  static isCity(cityNull: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null) {
        return null;
      }

      let error = {
        'error': true
      };

      return cityNull ? error : null;
    }
  }
}
