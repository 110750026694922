<mat-card class="!hidden lg:!block flex-1">
  <mat-card-content class="!flex flex-col h-full">
    <a routerLink="/{{ environment.moveUrl }}" class="flex items-center justify-between">
      <span class="flex">
        <mat-icon class="material-symbols-outlined mr-1" color="primary"> edit_location_alt </mat-icon>
        <h1 class="mat-subheading-2 !my-0">Umzug melden</h1>
      </span>
      <span class="flex">
        <mat-icon color="primary" class="self-end">chevron_right</mat-icon>
      </span>
    </a>
    <div class="cardContent border rounded-md p-2 mt-4 flex-1">
      <span class="block">{{ authService.contract?.customerAddress?.person?.firstName || "" }} {{ authService.contract?.customerAddress?.person?.lastName || "" }}</span>
      <span class="block">{{ authService.contract?.customerAddress?.location?.street || "" }} {{ authService.contract?.customerAddress?.location?.houseNumber || "" }}</span>
      <span class="block">{{ authService.contract?.customerAddress?.location?.postcode || "" }} {{ authService.contract?.customerAddress?.location?.city || "" }}</span>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="lg:!hidden !py-8">
  <mat-card-content>
    <a routerLink="/{{ environment.moveUrl }}" class="flex flex-col justify-center">
      <h1 color="primary" class="mat-subheading-2 text-center !my-0">
        <mat-icon color="primary" class="material-symbols-outlined" style="transform: scale(1.5)">edit_location_alt</mat-icon>
        <span class="block mt-2">Umzug</span>
      </h1>
    </a>
  </mat-card-content>
</mat-card>
