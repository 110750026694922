import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { ReceiptOfPayment } from '../interfaces/receipt-of-payment';
import {AuthService} from "../../shared/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getReceiptsOfPayment(contractId: string): Observable<ReceiptOfPayment[]> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<ReceiptOfPayment[]>(`${this.baseUrl}api/payments/receipts/${contractId}`, { headers: headers });
  }

}
