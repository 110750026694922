<div mat-dialog-content>
  <p class="mat-body-2">{{data.message}}</p>
</div>
<div mat-dialog-actions>
  <div class="flex">
    <a class="mr-5" (click)="save()">Ja</a>
    <a (click)="close()">Nein</a>
  </div>
</div>

