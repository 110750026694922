<div class="container mx-auto px-4 py-8">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Guthabenauszahlung
    </h1>
  </mat-card-title>
  <div class="flex flex-col md:flex-row">
    <div class="w-full md:flex-1 md:mr-5 mb-10 md:mb-0">
      <ng-container *ngIf="(lastCreditSettlement.isInValueState || lastCreditSettlement.isInNoValueState) && defaultCreditSettlement">
        <form (ngSubmit)="save()" #creditSettlementForm="ngForm" [formGroup]="creditSettlementFormGroup" *ngIf="creditSettlementFormGroup">
          <fieldset [disabled]="creditSettlementSaveOperation.isInLoadingState">
            <div class="block mb-5">
              Ihr Wunsch zum Umgang mit dem Guthaben Ihrer letzten Rechnung: *
            </div>
            <mat-radio-group formControlName="creditSettlementType">
              <div class="flex flex-col mb-5">
                <mat-radio-button *ngFor="let viewModel of creditSettlementTypeViewModels" [value]="viewModel.type" class="mb-3">
                  {{viewModel.label}}
                </mat-radio-button>
              </div>
              <mat-error *ngIf="creditSettlementFormGroup.controls.creditSettlementType.errors?.required && (creditSettlementFormGroup.controls.creditSettlementType.touched || creditSettlementForm.submitted)">Pflichtfeld</mat-error>
            </mat-radio-group>
            <ng-container *ngIf="creditSettlementFormGroup.controls.creditSettlementType.value === CreditSettlementType.PayoutByBankTransfer">
              <div class="flex flex-col md:flex-row md:flex-wrap">
                <div class="w-full mb-5">
                  <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Kontoinhaber</mat-label>
                    <input type="text" matInput formControlName="accountHolder">
                    <mat-error *ngIf="creditSettlementFormGroup.controls.accountHolder.errors?.required">Pflichtfeld</mat-error>
                  </mat-form-field>
                </div>
                <div class="w-full flex flex-col md:flex-row md:gap-x-5">
                  <mat-form-field appearance="fill" class="w-full mb-5 md:flex-1 md:mb-0">
                    <mat-label>IBAN</mat-label>
                    <input type="text" matInputBlurValue [blurValue]="this.creditSettlementService.blurIban(creditSettlementFormGroup.controls.iban.value)" matInput formControlName="iban">
                    <mat-error *ngIf="creditSettlementFormGroup.controls.iban.errors?.required">Pflichtfeld</mat-error>
                    <mat-error *ngIf="creditSettlementFormGroup.controls.iban.errors?.iban">IBAN ungültig</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="w-full md:flex-1">
                    <mat-label>BIC</mat-label>
                    <input type="text" matInput formControlName="bic">
                    <mat-error *ngIf="creditSettlementFormGroup.controls.bic.errors?.required">Pflichtfeld</mat-error>
                    <mat-error *ngIf="creditSettlementFormGroup.controls.bic.errors?.bic">BIC ungültig</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="creditSettlementFormGroup.controls.creditSettlementType.value === CreditSettlementType.WaiveBalance">
              <div class="flex flex-col md:flex-row gap-5 items-center border p-5 rounded mt-5">
                <div class="w-auto">
                  <img src="/assets/img/Guthabenspenden.png" alt="Guthabenspenden" width="71" height="63">
                </div>
                <div class="flex-1 text-center md:text-left">
                  <span class="mat-body-2">Gemeinsam gegen die Krise - jetzt Guthaben zur Verfügung stellen</span><br>
                  Mit Ihrer Unterstützung können wir Energiekunden helfen, die ihre Rechnungen nicht mehr bezahlen können.
                </div>
              </div>
            </ng-container>
            <div class="flex flex-col md:flex-row !mt-10">
              <div class="w-full md:w-auto">
                <app-button caption="Absenden" color="primary" type="submit" [progressBarVisible]="creditSettlementSaveOperation.isInLoadingState"></app-button>
              </div>
            </div>
          </fieldset>
        </form>
        <div class="flex flex-col mt-5">
          <span>
            * Sofern ein Guthaben in Ihrem Vertragskonto besteht, geht Ihnen dieses auf die gewünschte Auszahlungsmethode zu.
          </span>
          <span *ngIf="environment.mustSelectPayoutMethodForEachCreditSettlement">
            Bei der nächsten Abrechnung mit etwaigem Guthaben werden Sie erneut gebeten, die Auszahlungsart festzulegen.
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="lastCreditSettlement.isInErrorState">
        <app-notification [notificationType]="NotificationType.Error" notification="Ihre Auszahlungsmodalität ist zurzeit nicht verfügbar – Bitte versuchen Sie es später erneut."></app-notification>
      </ng-container>
      <ng-container *ngIf="lastCreditSettlement.isInLoadingState">
        <div class="flex justify-center">
          <mat-spinner></mat-spinner>
        </div>
      </ng-container>
    </div>
    <div class="w-full md:w-4/12">
      <app-contact-card></app-contact-card>
    </div>

  </div>
</div>
