import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NotificationComponent } from "./components/notification.component";

@NgModule({
  declarations: [
    NotificationComponent,
  ],
  exports: [
    NotificationComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
})

export class NotificationModule {}
