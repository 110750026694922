<mat-card class="!hidden lg:!block flex-1">
  <mat-card-content class="!flex flex-col h-full">
    <a routerLink="/{{ environment.contractOverviewUrl }}" class="flex items-center justify-between">
      <span class="flex">
        <mat-icon class="material-symbols-outlined mr-1" color="primary"> Description </mat-icon>
        <h1 class="mat-subheading-2 !my-0">Vertragsübersicht</h1>
      </span>
      <span class="flex">
        <mat-icon color="primary" class="self-end">chevron_right</mat-icon>
      </span>
    </a>
    <div class="cardContent border rounded-md p-2 mt-4 flex-1">
      <ng-container *ngIf="selectedContract">
        <ng-container *ngIf="selectedContract.deliveryInformation.deliveryStart">
          <span class="block">
            Lieferung startet {{ selectedContract.deliveryInformation.deliveryStart | date: 'dd.MM.yyyy'}}
          </span>
          <span *ngIf="selectedContract.deliveryInformation.deliveryEnd" class="block">
            Lieferung endet {{ selectedContract.deliveryInformation.deliveryEnd | date: 'dd.MM.yyyy'}}
          </span>
        </ng-container>
        <ng-container *ngIf="!selectedContract.deliveryInformation.deliveryStart">
          <span class="block">
            Belieferung in Bearbeitung
          </span>
        </ng-container>
      </ng-container>
      <div *ngIf="!selectedContract">
        <div class="flex justify-center w-full">
          <mat-spinner [diameter]="70"></mat-spinner>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="lg:!hidden !py-8">
  <mat-card-content>
    <a routerLink="/{{ environment.contractOverviewUrl }}" class="flex flex-col justify-center">
      <h1 color="primary" class="mat-subheading-2 text-center !my-0">
        <mat-icon color="primary" class="material-symbols-outlined" style="transform: scale(1.5)">Description</mat-icon>
        <span class="block mt-2">Vertrag</span>
      </h1>
    </a>
  </mat-card-content>
</mat-card>

