import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { takeUntil } from "rxjs/operators";
import { Contract } from "../../shared/interfaces/contract";
import { CustomerSelectionService } from "../../shared/services/customer-selection.service";
import { Subject } from "rxjs";


@Component({
  selector: 'app-dashboard-contract-overview-card',
  templateUrl: './dashboard-contract-overview-card.component.html'
})
export class DashboardContractOverviewCardComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<void> = new Subject<void>();
  selectedContract: Contract | null = null;
  environment = environment;

  constructor(private customerSelectionService: CustomerSelectionService) {
  }

  ngOnInit(): void {
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
