<div class="fadedBG">
  <div class="container mx-auto px-4 py-16">
    <mat-card-title>
      <h1 class="mat-headline text-center">
        Wie können wir Ihnen weiterhelfen?
      </h1>
    </mat-card-title>
    <div class="flex flex-wrap justify-center">
      <div class="w-full lg:w-1/2">
        <ng-container *ngIf="searchForm">
          <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
            <mat-form-field class="w-full mt-5 input-search" appearance="fill">
              <mat-label>Suche</mat-label>
              <mat-icon matPrefix>search</mat-icon>
              <input type="text"
                     formControlName="search"
                     matInput>
            </mat-form-field>
          </form>
        </ng-container>

        <div class="my-5">
          <ng-container *ngFor="let tag of searchTags">
            <a class="py-2 px-3 rounded-lg primaryCategoryBackgroundColor m-1" (click)="onTagClick(tag)">
              {{ tag.tag }}
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mx-auto px-4 py-8">
  <ng-container *ngIf="this.faqState.isInValueState">

    <ng-container *ngIf="favourites.length > 0">
      <div class="my-20 flex flex-wrap justify-center">
        <mat-card-title class="w-full">
          <h1 class="mat-headline text-center">
            Häufig gesucht
          </h1>
        </mat-card-title>
        <div class="w-full">
          <app-favourites [favourites]="favourites"></app-favourites>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="topics.length > 0">
      <div class="my-20 flex flex-wrap justify-center">
        <mat-card-title>
          <h1 class="mat-headline text-center">
            Alle Themen
          </h1>
        </mat-card-title>
        <div class="w-full">
          <app-topics [topics]="topics"></app-topics>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="topics.length === 0">
      <div class="w-full my-8">
        <app-notification [notificationType]="NotificationType.Information"
                          notification="Keine Themen verfügbar.">
        </app-notification>
      </div>
    </ng-container>
  </ng-container>

  <div class="flex flex-wrap justify-center">
    <ng-container *ngIf="this.faqState.isInLoadingState">
      <div class="m-auto flex items-center my-16 w-full z-10">
        <mat-spinner class="m-auto"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="faqState.isInErrorState">
      <div class="w-full my-8">
        <app-notification [notificationType]="NotificationType.Error"
                          notification="Die Themen sind derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.">
        </app-notification>
      </div>
    </ng-container>

    <div class="w-full text-center">
      <a href="/faq/contact">Haben Sie eine andere Frage?</a>
    </div>
  </div>

</div>

