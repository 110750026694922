import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Tag} from "../interfaces/tag";
import {ObservableStatus} from "../../observable-status/classes/observable-status";
import {Faq} from "../interfaces/faq";
import {Topic} from "../interfaces/topic";
import {Favourite} from "../interfaces/favourite";
import {FaqService} from "../services/faq.service";
import {switchMap} from "rxjs/operators";
import {withNormalLoadingStatus} from "../../observable-status/extensions/observable.extension";
import {environment} from "../../../../environments/environment";
import {NotificationType} from "../../shared/enumerations/notification-type";
import {Question} from "../interfaces/question";
import {Router} from "@angular/router";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html'
})
export class FaqComponent implements OnInit, OnDestroy {
  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  @HostBinding('class') flexClass = 'flex-1';

  NotificationType = NotificationType;
  environment = environment;

  searchForm: FormGroup | null = null;

  faqState: ObservableStatus<Faq> = ObservableStatus.none<Faq>();
  tags: Tag[] = [];
  searchTags: Tag[] = [];
  favourites: Favourite[] = [];
  topics: Topic[] = [];
  subTopics: Topic[] = [];
  questions: Question[] = [];

  constructor(private fb: FormBuilder, private faqService: FaqService, private router: Router) {
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: ['']
    });

    this.refresh$
      .pipe(
        switchMap(() => {
          return withNormalLoadingStatus(
            this.faqService.getFaq(null, null, null)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(faqState => {
      this.faqState = faqState;
      this.tags = faqState?.value?.tags || [];
      this.searchTags = faqState?.value?.searchTags || [];
      this.favourites = faqState.value?.favourites || [];
      this.topics = faqState.value?.topics || [];
      this.subTopics = faqState.value?.subTopics || [];
      this.questions = faqState.value?.questions || [];
    });

    this.refresh();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

  onSearch(): void {
    const searchValue = this.searchForm?.get('search')?.value;
  }

  onTagClick(tag: Tag) {
    this.router.navigate(['/faq/tags', tag.id.toString()]);
  }
}
