import {Component, HostBinding, OnInit} from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-move',
  templateUrl: './move-success.component.html'
})
export class MoveSuccessComponent {
  brandName: string = environment.brandName;
  homePageUrl: string = environment.homePageUrl;
  faqUrl: string = environment.faqUrl;
  customerPortalUrl: string = environment.customerPortalUrl;
  hotline: string = environment.hotline;
  @HostBinding('class') flexClass = 'flex-1';

  environment = environment;
}
