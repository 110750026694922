import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { PaymentMethod } from '../../payment-information/enumerations/payment-method';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { Contract } from '../../shared/interfaces/contract';
import { MonthlyPaymentDetail } from '../interfaces/monthly-payment-detail';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { MonthlyPaymentService } from '../services/monthly-payment.service';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus, withSlowLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { MonthlyPaymentUpdate } from '../interfaces/monthly-payment-update';


@Component({
  selector: 'app-monthly-payment-change',
  templateUrl: './monthly-payment-change.component.html'
})
export class MonthlyPaymentChangeComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<void> = new Subject<void>();
  private refresh$: Subject<void> = new Subject<void>();

  monthlyPaymentFormGroup: FormGroup | null = null;
  @ViewChild('monthlyPaymentForm') monthlyPaymentForm: NgForm | null = null;

  PaymentMethod = PaymentMethod;
  NotificationType = NotificationType;

  selectedContract: Contract | null = null;
  detail: ObservableStatus<MonthlyPaymentDetail> = ObservableStatus.none<MonthlyPaymentDetail>();
  monthlyPaymentSaveOperation: ObservableStatus<void> = ObservableStatus.none<void>();
  priceCapInEuroGross: number | null = null;
  priceCapInKwh: number | null = null;

  constructor(
    private customerSelectionService: CustomerSelectionService,
    private snackBar: MatSnackBar,
    public monthlyPaymentService: MonthlyPaymentService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of(ObservableStatus.none<MonthlyPaymentDetail>())
          }
          return withNormalLoadingStatus(
            this.monthlyPaymentService.getMonthlyPaymentDetail(this.selectedContract.contractId)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(detail => {
        this.detail = detail;
        if (this.detail.isInValueState) {
          this.monthlyPaymentFormGroup = this.formBuilder.group({
            selectedMonthlyPaymentEuroGross: [null, [Validators.required, Validators.max(Math.round(this.upperLimitEuroGross)), Validators.min(Math.round(this.lowerLimitEuroGross))]],
            monthlyPaymentKwh: [null, [Validators.required, Validators.max(Math.round(this.upperLimitKwh)), Validators.min(Math.round(this.lowerLimitKwh))]],
          });
          this.monthlyPaymentFormGroup.controls.selectedMonthlyPaymentEuroGross.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(newValue => {
              let newKwh = Math.round(this.euroGrossToKwh(newValue));
              let oldKwh = Math.round(this.monthlyPaymentFormGroup!.controls.monthlyPaymentKwh.value);
              if (Math.round(this.kwhToEuroGross(newKwh)) !== Math.round(this.kwhToEuroGross(oldKwh))) {
                this.monthlyPaymentFormGroup!.controls.monthlyPaymentKwh.patchValue(newKwh);
                this.monthlyPaymentFormGroup!.controls.monthlyPaymentKwh.markAsTouched();
              }
              this.calculatePiceCapValues();
            });
          this.monthlyPaymentFormGroup.controls.monthlyPaymentKwh.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(newValue => {
              let newEuroGross = Math.round(this.kwhToEuroGross(newValue));
              let oldEuroGross = Math.round(this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.value);
              if (newEuroGross !== oldEuroGross) {
                this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.patchValue(newEuroGross);
                this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.markAsTouched();
              }
              this.calculatePiceCapValues();
            });
          this.clear();
        }
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  incrementMonthlyPaymentEuroGross() {
    const currentValue = this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.value;
    if (currentValue !== null) {
      this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.setValue(currentValue + 5);
    }
  }

  decrementMonthlyPaymentEuroGross() {
    const currentValue = this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.value;
    if (currentValue !== null) {
      this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.setValue(currentValue - 5);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  euroGrossToKwh(euroGross: number): number {
    return this.monthlyPaymentService.calculateYearlyConsumptionWithPriceDetail(euroGross / this.detail.value!.vatFactor, this.detail.value!.priceDetail);
  }

  kwhToEuroGross(kwh: number): number {
    return this.monthlyPaymentService.calculateMonthlyCostsWithPriceDetail(kwh, this.detail.value!.priceDetail) * this.detail.value!.vatFactor;
  }

  calculatePiceCapValues(): void {
    if (!this.detail.value?.isPriceCapped) {
      this.priceCapInEuroGross = null;
      this.priceCapInKwh = null;
      return;
    }
    let dueMonthlyPaymentEuroGross = this.kwhToEuroGross(this.detail.value.yearlyConsumption);
    let selectedMonthlyPaymentEuroGross = this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.value;
    let priceCapInEuroGross = Math.max(0, dueMonthlyPaymentEuroGross - selectedMonthlyPaymentEuroGross);
    this.priceCapInEuroGross = Math.round(priceCapInEuroGross);
    let dueMonthlyPaymentInKwh = this.euroGrossToKwh(dueMonthlyPaymentEuroGross);
    let selectedMonthlyPaymentInKwh = this.euroGrossToKwh(selectedMonthlyPaymentEuroGross);
    this.priceCapInKwh = Math.max(0, dueMonthlyPaymentInKwh - selectedMonthlyPaymentInKwh);
  }

  get monthlyPaymentByConsumptionGross(): number {
    return this.monthlyPaymentService.calculateMonthlyCostsWithPriceDetail(this.detail.value!.yearlyConsumption, this.detail.value!.priceDetail) * this.detail.value!.vatFactor;
  }

  get monthlyPaymentByMinimumConsumptionGross(): number {
    return this.monthlyPaymentService.calculateMonthlyCostsWithPriceDetail(this.detail.value!.minimumYearlyConsumption, this.detail.value!.priceDetail) * this.detail.value!.vatFactor;
  }

  get monthlyPaymentKwh(): number {
    return this.euroGrossToKwh(this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.value);
  }

  set monthlyPaymentKwh(kwh: number) {
    this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.patchValue(this.kwhToEuroGross(kwh));
  }

  get upperLimitKwh(): number {
    return Math.round(this.euroGrossToKwh(Math.round(this.upperLimitEuroGross)));
  }

  get lowerLimitKwh(): number {
    return Math.round(this.euroGrossToKwh(Math.round(this.lowerLimitEuroGross)));
  }

  get upperLimitEuroGross(): number {
    return this.detail.value!.boundary.upperLimitEuro * this.detail.value!.vatFactor;
  }

  get lowerLimitEuroGross(): number {
    return this.detail.value!.boundary.lowerLimitEuro * this.detail.value!.vatFactor;
  }

  get defaultMonthlyPaymentEuroGross(): number {
    let lowerLimit = Math.min(this.upperLimitEuroGross, this.detail.value!.nextPayment.currentMonthlyPayment);
    return Math.max(this.lowerLimitEuroGross, lowerLimit);
  }

  save(): void {
    if (this.monthlyPaymentForm!.invalid || this.monthlyPaymentSaveOperation.isInLoadingState) {
      return;
    }

    let priceCapInEuroNet = null;
    if (this.priceCapInEuroGross) {
      priceCapInEuroNet = Math.round(this.priceCapInEuroGross / this.detail.value!.vatFactor);
    }
    let monthlyPaymentUpdate = {
      euroGross: this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.value,
      euroNet: Math.round(this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.value) / this.detail.value!.vatFactor,
      priceCapInEuroGross: this.priceCapInEuroGross,
      priceCapInEuroNet: priceCapInEuroNet,
      vat: this.detail.value!.vatFactor
    } as MonthlyPaymentUpdate;
    withSlowLoadingStatus(
      this.monthlyPaymentService.updateMonthlyPayment(monthlyPaymentUpdate, this.selectedContract!.contractId)
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(monthlyPaymentSaveOperation => {
        this.monthlyPaymentSaveOperation = monthlyPaymentSaveOperation;
        if (this.monthlyPaymentSaveOperation.isInErrorState) {
          this.snackBar.open('Monatlicher Zahlbetrag konnte nicht gespeichert werden!', 'OK', {
            panelClass: 'snack-danger'
          });
        } else if (this.monthlyPaymentSaveOperation.isInValueState || this.monthlyPaymentSaveOperation.isInNoValueState) {
          this.snackBar.open('Monatlicher Zahlbetrag erfolgreich gespeichert!', 'OK', {
            panelClass: 'snack-success'
          });
          this.refresh();
        }
      });
  }

  clear(): void {
    this.monthlyPaymentFormGroup!.controls.selectedMonthlyPaymentEuroGross.patchValue(this.defaultMonthlyPaymentEuroGross);
  }

  refresh(): void {
    this.refresh$.next();
  }

}
