<div>
  <mat-card [ngClass]="{'grayCard': currentPrice && (currentPrice.tariffType !== TariffType.Index && currentPrice.tariffType !== TariffType.IndexHybrid)}">
    <mat-card-content>
      <ng-container *ngIf="!priceAutoLoadService.isLoading && !isLoading">
        <div class="flex w-full items-center justify-end">
          <span *ngIf="selectedContract?.deliveryInformation?.energyType === EnergyType.Gas"
                class="color-thema flex flex-row items-center !px-1 !py-[2px] primaryColorFaded rounded">
            <mat-icon class="primaryColor">local_fire_department</mat-icon>
            <span class="primaryColor font-bold">GAS</span>
          </span>
          <div *ngIf="selectedContract?.deliveryInformation?.energyType === EnergyType.Electricity"
               class="color-thema flex flex-row items-center !px-1 !py-[2px] primaryColorFaded rounded">
            <mat-icon class="primaryColor">bolt</mat-icon>
            <span class="primaryColor font-semibold">STROM</span>
          </div>
        </div>
        <ng-container
          *ngIf="currentPrice && (currentPrice.tariffType === TariffType.Index || currentPrice.tariffType === TariffType.IndexHybrid)">
          <div class="mt-4 flex flex-col items-center">
            <div class="flex flex-col">
              <ng-container *ngIf="(dailyPriceSnapshot && currentPrice) || (currentPrice && priceService.checkIfInFixedPricePeriod(currentPrice))">
                <app-daily-price-spiral [dailyPriceSnapshot]="dailyPriceSnapshot" [showTotal]="true"
                                        [displayInCard]="true" [currentPrice]="currentPrice">
                </app-daily-price-spiral>
              </ng-container>
            </div>
            <div class="mt-5 flex flex-col w-full">
              <a routerLink="/{{ environment.tariffUrl }}" class="flex justify-end w-full">
                <span class="flex items-center mat-subheading-2 !my-0">
                  <span color="primary">Details</span>
                  <mat-icon class="material-symbols-outlined" color="primary">chevron_right</mat-icon>
                </span>
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="!currentPrice || (currentPrice.tariffType !== TariffType.Index && currentPrice.tariffType !== TariffType.IndexHybrid)">
          <span class="mat-subheading-2">{{ selectedContract?.deliveryAddress?.person?.firstName }} {{ selectedContract?.deliveryAddress?.person?.lastName }}</span>
          <br/>
          <span>{{ selectedContract?.deliveryAddress?.location?.street }} {{ selectedContract?.deliveryAddress?.location?.houseNumber }}
            , {{ selectedContract?.deliveryAddress?.location?.city }}</span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="priceAutoLoadService.isLoading || isLoading">
        <div class="flex flex-col content-center items-center">
          <mat-spinner [diameter]="70"></mat-spinner>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
  <ng-container *ngIf="!priceAutoLoadService.isLoading && !isLoading">
    <ng-container *ngIf="currentPrice">
      <ng-container *ngIf="priceService.checkIfInFixedPricePeriod(currentPrice!)">
        <div class="primaryColorFaded mt-[-30px] pt-[40px] px-4 pb-[15px] rounded-b-[20px] flex flex-col">
          <span class="mat-body-2 my-2">Ihr Preis im ersten Monat</span>
          <span>
            Bis zum {{currentPrice.tariffFixedPriceEndDate | date : 'mediumDate'}} ist die Preisfixierung noch aktiv. Ihr aktueller Arbeitspreis ist {{currentPrice.workingPriceInEuroPerKwhGross * 100 | number : '1.2-2'}} ct/kWh. Der Grundpreis beträgt {{currentPrice.basicPriceInEuroGross | number : '1.2-2'}} €/Monat.
          </span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

