<ng-container *ngIf="notification && timeline">
  <div class="errorWrapper flex flex-wrap w-full rounded-xl p-4 my-4"
       [ngClass]="{
       'errorMsg': notification.notificationType === 'Error',
       'warningMsg': notification.notificationType === 'Warning'
     }"
  >
    <ng-container [ngSwitch]="notification.notificationReasonId">
      <span class="headline w-full mat-subheading-2 order-1 md:w-11/12">
        <ng-container *ngSwitchCase="0">
          Keine Identifizierung der Lieferstelle möglich
        </ng-container>
        <ng-container *ngSwitchCase="1">
          Keine Identifizierung der Lieferstelle möglich
        </ng-container>
        <ng-container *ngSwitchCase="2">
          Identifikation nicht möglich
        </ng-container>
        <ng-container *ngSwitchCase="3">
          Ihre Belieferung beginnt ab dem {{ timeline.confirmedDeliveryDate | date:'dd.MM.yyyy' }}
        </ng-container>
        <ng-container *ngSwitchCase="4">
          Ihre Belieferung beginnt ab dem {{ timeline.confirmedDeliveryDate | date:'dd.MM.yyyy' }}
        </ng-container>
        <ng-container *ngSwitchCase="5">
          Ihre Energiebelieferung startet zum nächstmöglichen Termin, dem {{ timeline.confirmedDeliveryDate | date:'dd.MM.yyyy' }}
        </ng-container>
        <ng-container *ngSwitchCase="6">
          Ihre Energiebelieferung startet zum nächstmöglichen Termin, dem {{ timeline.confirmedDeliveryDate | date:'dd.MM.yyyy' }}
        </ng-container>
        <ng-container *ngSwitchCase="7">
          Ihr Wechsel verzögert sich
        </ng-container>
        <ng-container *ngSwitchCase="8">
          Ihr Wechsel verzögert sich
        </ng-container>
        <ng-container *ngSwitchCase="9">
          Ihr Wechsel verzögert sich
        </ng-container>
        <ng-container *ngSwitchCase="10">
          Gewünschter Liefertermin kann nicht realisiert werden
        </ng-container>
        <ng-container *ngSwitchCase="11">
          Gewünschter Liefertermin kann nicht realisiert werden
        </ng-container>
        <ng-container *ngSwitchCase="12">
          Gewünschter Liefertermin kann nicht realisiert werden
        </ng-container>
        <ng-container *ngSwitchCase="13">
          Ihr Netzbetreiber {{ timeline.gridOperator }} lehnt eine Änderung des Lieferbeginns ab
        </ng-container>
        <ng-container *ngSwitchCase="14">
          Ihr Netzbetreiber {{ timeline.gridOperator }} lehnt eine Änderung des Lieferbeginns ab
        </ng-container>
        <ng-container *ngSwitchCase="15">
          Ihr Netzbetreiber {{ timeline.gridOperator }} lehnt eine Änderung des Lieferbeginns ab
        </ng-container>
        <ng-container *ngSwitchCase="16">
          Ihr Netzbetreiber {{ timeline.gridOperator }} lehnt eine Änderung des Lieferbeginns ab
        </ng-container>
        <ng-container *ngSwitchCase="17">
          Der Wechselprozess wurde gestoppt
        </ng-container>
        <ng-container *ngSwitchCase="18">
          Der Wechselprozess wurde gestoppt
        </ng-container>
        <ng-container *ngSwitchCase="19">
          Der Wechselprozess wurde gestoppt
        </ng-container>
        <ng-container *ngSwitchCase="20">
          Der Wechselprozess wurde gestoppt
        </ng-container>
        <ng-container *ngSwitchCase="21">
          Der Wechselprozess wurde gestoppt
        </ng-container>
        <ng-container *ngSwitchCase="22">
          Der Wechselprozess wurde gestoppt
        </ng-container>
        <ng-container *ngSwitchCase="23">
          Der Wechselprozess wurde gestoppt
        </ng-container>
        <ng-container *ngSwitchCase="24">
          Der Wechselprozess wurde gestoppt
        </ng-container>
        <ng-container *ngSwitchCase="25">
          Ihr Wechsel verzögert sich
        </ng-container>
        <ng-container *ngSwitchCase="26">
          Ihr Wechsel verzögert sich
        </ng-container>
        <ng-container *ngSwitchCase="27">
          Ihre Anmeldung wurde vom Netzbetreiber abgelehnt
        </ng-container>
        <ng-container *ngSwitchCase="28">
          Ihre Anmeldung wurde vom Netzbetreiber abgelehnt
        </ng-container>
        <ng-container *ngSwitchCase="29">
          Ihr Wechsel verzögert sich
        </ng-container>
        <ng-container *ngSwitchCase="30">
          Ihr Wechsel verzögert sich
        </ng-container>
        <ng-container *ngSwitchCase="31">
          Der Wechselprozess kann nicht abgeschlossen werden
        </ng-container>
        <ng-container *ngSwitchCase="32">
          Der Wechselprozess kann nicht abgeschlossen werden
        </ng-container>
      </span>
      <p class="content w-full order-2 md:order-3">
        <ng-container *ngSwitchCase="0">
          Ihr bisheriger Lieferant {{ timeline.previousSupplier }} gibt an, Ihren Zähler bzw. Ihre Adresse nicht identifizieren zu können. Bitte prüfen Sie Ihre Angaben zum Wechseltermin, zur Zählernummer und Adresse oder zu einem abweichenden Vertragspartner.
        </ng-container>
        <ng-container *ngSwitchCase="1">
          Ihr bisheriger Lieferant {{ timeline.previousSupplier }} gibt an, Ihren Zähler bzw. Ihre Adresse nicht identifizieren zu können. Bitte prüfen Sie, ob es einen abweichenden oder weiteren Vertragspartner bei Ihrem bisherigen Lieferanten gibt und Ihre Angaben zum Zähler und zur Adresse.
        </ng-container>
        <ng-container *ngSwitchCase="2">
          Ihr bisheriger Lieferant {{ timeline.previousSupplier }} gibt an,  Sie nicht im System identifizieren zu können. Bitte überprüfen Sie, bei welchem Lieferanten die Kündigung durchgeführt werden soll und Ihre Angaben zum Zähler und zur Adresse.
        </ng-container>
        <ng-container *ngSwitchCase="3">
          Wir haben versucht, Ihr Wunschdatum zu berücksichtigen, jedoch war dies aufgrund von Marktprozessfristen nicht möglich. Wenn Sie eine frühere Belieferung wünschen, prüfen Sie bitte die Vertragslaufzeiten bei Ihrem bisherigen Lieferanten und bestätigen Sie uns hier die Kündigung.
        </ng-container>
        <ng-container *ngSwitchCase="4">
          Wir haben versucht, Ihr Wunschdatum zu berücksichtigen, jedoch war dies aufgrund von Marktprozessfristen nicht möglich. Wenn Sie eine frühere Belieferung wünschen, prüfen Sie bitte die Vertragslaufzeiten bei Ihrem bisherigen Lieferanten und bestätigen Sie uns hier die Kündigung.
        </ng-container>
        <ng-container *ngSwitchCase="5">
          Wenn Sie eine frühere Belieferung wünschen, prüfen Sie bitte die Vertragslaufzeiten bei Ihrem bisherigen Lieferanten und bestätigen Sie uns hier die Kündigung.
        </ng-container>
        <ng-container *ngSwitchCase="6">
          Wenn Sie eine frühere Belieferung wünschen, prüfen Sie bitte die Vertragslaufzeiten bei Ihrem bisherigen Lieferanten und bestätigen Sie uns hier die Kündigung.
        </ng-container>
        <ng-container *ngSwitchCase="7">
          Wir arbeiten daran, Sie schnellstmöglich zu beliefern. Bitte prüfen Sie Ihre Angaben zum bisherigen Vorversorger und Lieferbeginn, um den Prozess zu beschleunigen.
        </ng-container>
        <ng-container *ngSwitchCase="8">
          Wir arbeiten daran, Sie schnellstmöglich zu beliefern. Bitte prüfen Sie Ihre Angaben zum Wechsel, um den Prozess zu beschleunigen.
        </ng-container>
        <ng-container *ngSwitchCase="9">
          Wir arbeiten daran, Sie schnellstmöglich zu beliefern. Bitte prüfen Sie Ihre Angaben zum bisherigen Vorversorger, zum Lieferbeginn und abweichenden Vertragspartnern, um den Prozess zu beschleunigen.
        </ng-container>
        <ng-container *ngSwitchCase="10">
          Sie haben zum angegebenen Zeitpunkt eine Vertragsbindung mit einem anderen Anbieter. Bitte überprüfen Sie Ihre Angaben zum Liefertermin oder widerrufen Sie den Vertrag mit dem betroffenen Versorger, damit wir den Prozess fortführen können.
        </ng-container>
        <ng-container *ngSwitchCase="11">
          Sie haben zum angegebenen Zeitpunkt eine Vertragsbindung mit einem anderen Anbieter. Bitte überprüfen Sie Ihre Angaben zum Liefertermin,  oder widerrufen Sie den Vertrag mit dem betroffenen Versorger, damit wir den Prozess fortführen können.
        </ng-container>
        <ng-container *ngSwitchCase="12">
          Sie haben zum angegebenen Zeitpunkt eine Vertragsbindung mit einem anderen Anbieter. Bitte überprüfen Sie Ihre Angaben zum vorherigen Lieferanten,  oder widerrufen Sie den Vertrag mit dem betroffenen Versorger, damit wir den Prozess fortführen können.
        </ng-container>
        <ng-container *ngSwitchCase="13">
          Sie sind zum Wunschtermin {{ timeline.gridOperatorRegistrationDate | date:'dd.MM.yyyy' }} noch bei einem anderen Lieferanten vertraglich gebunden. Bitte überprüfen Sie Ihre Angaben, bevor wir den Prozess erneut starten.
        </ng-container>
        <ng-container *ngSwitchCase="14">
          Sie sind zum Wunschtermin {{ timeline.gridOperatorRegistrationDate | date:'dd.MM.yyyy' }} noch bei einem anderen Lieferanten vertraglich gebunden. Bitte überprüfen Sie Ihre Angaben, bevor wir den Prozess erneut starten.
        </ng-container>
        <ng-container *ngSwitchCase="15">
          Der Netzbetreiber hält Ihre Angaben des Datums {{ timeline.gridOperatorRegistrationDate | date:'dd.MM.yyyy' }} für unplausibel. Bitte überprüfen Sie Ihre Angaben, bevor wir den Prozess erneut starten.
        </ng-container>
        <ng-container *ngSwitchCase="16">
          Sie befinden sich zu dem gewünschten Datum {{ timeline.gridOperatorRegistrationDate | date:'dd.MM.yyyy' }} nicht in der Grundversorgung. Bitte prüfen Sie den Beendigungszeitpunkt Ihres vorherigen Vertrags, bevor wir den Prozess erneut starten.
        </ng-container>
        <ng-container *ngSwitchCase="17">
          Ihr bisheriger Lieferant kann Sie in seinem System nicht identifizieren. Bitte überprüfen Sie Ihre Zählernummer, um den Wechselprozess erneut zu starten.
        </ng-container>
        <ng-container *ngSwitchCase="18">
          Die Kündigung bei Ihrem vorherigen Lieferanten {{ timeline.previousSupplier }} konnte nicht erfolgreich durchgeführt werden. Bitte überprüfen Sie Ihre Daten, um den Wechselprozess erneut zu starten.
        </ng-container>
        <ng-container *ngSwitchCase="19">
          Bitte kontaktieren Sie den Kundensupport, um den Wechselprozess wieder aufzunehmen.
        </ng-container>
        <ng-container *ngSwitchCase="20">
          Ihr Netzbetreiber {{ timeline.gridOperator }} kann Ihre Zählernummer nicht zuordnen. Bitte überprüfen Sie Ihre Zählernummer, um den Wechselprozess erneut zu starten.
        </ng-container>
        <ng-container *ngSwitchCase="21">
          Die Kündigung bei Ihrem vorherigen Lieferanten {{ timeline.previousSupplier }} konnte nicht erfolgreich durchgeführt werden. Bitte überprüfen Sie den Kündigungstermin und den Namen Ihres vorherigen Lieferanten, um den Wechselprozess erneut zu starten.
        </ng-container>
        <ng-container *ngSwitchCase="22">
          Ihr Netzbetreiber {{ timeline.gridOperator }} konnte bei Ihrem vorherigen Lieferanten {{ timeline.previousSupplier }} nicht erfolgreich kündigen. Bitte überprüfen Sie Ihre Angaben, um den Wechselprozess erneut zu starten.
        </ng-container>
        <ng-container *ngSwitchCase="23">
          Ihr Netzbetreiber {{ timeline.gridOperator }} konnte bei Ihrem vorherigen Lieferanten {{ timeline.previousSupplier }} nicht erfolgreich kündigen. Bitte überprüfen Sie Ihre Angaben zu Ihrem bisherigen Versorger, um den Wechselprozess erneut zu starten.
        </ng-container>
        <ng-container *ngSwitchCase="24">
          Ihr Netzbetreiber {{ timeline.gridOperator }} hat uns mitgeteilt, dass Ihre momentane Belieferungssituation unklar ist. Bitte überprüfen Sie Ihre Angaben, um den Wechselprozess erneut zu starten.
        </ng-container>
        <ng-container *ngSwitchCase="25">
          Ihr Netzbetreiber {{ timeline.gridOperator }} hat dem Einzug nicht zugestimmt. Bitte überprüfen Sie, ob Sie in den letzten 6 Wochen in die Lieferstelle eingezogen sind oder übermitteln und Informationen zu Ihrem bisherigen Versorger.
        </ng-container>
        <ng-container *ngSwitchCase="26">
          Ihr Netzbetreiber {{ timeline.gridOperator }} hat dem Einzug nicht zugestimmt. Bitte überprüfen Sie, ob Sie in den letzten 6 Wochen in die Lieferstelle eingezogen sind und geben uns eine Rückmeldung zu Ihren Angaben im Auftrag.
        </ng-container>
        <ng-container *ngSwitchCase="27">
          Bitte prüfen Sie Ihre Angaben zur Kündigung Ihres vorherigen Versorgers {{ timeline.previousSupplier }} sowie zu Ihrem Zähler, um den Wechselprozess fortzusetzen.
        </ng-container>
        <ng-container *ngSwitchCase="28">
          Bitte prüfen Sie Ihre Angaben zum Auftrag, um den Wechselprozess fortzusetzen.
        </ng-container>
        <ng-container *ngSwitchCase="29">
          Ihr vorheriger Lieferant {{ timeline.previousSupplier }} hat der Kündigung nicht zugestimmt. Bitte prüfen Sie Ihre Angaben zum Auftrag und teilen Sie uns Abweichungen mit.
        </ng-container>
        <ng-container *ngSwitchCase="30">
          Ihre Angaben zu Ihrem bisherigen Versorger sind nicht plausibel. Bitte überprüfen Sie besonders den Namen und das Kündigungsdatum Ihres bisherigen Versorgers.
        </ng-container>
        <ng-container *ngSwitchCase="31">
          Der Vormieter Ihrer neuen Wohnung hat sich nicht abgemeldet. Bitte lassen Sie uns ein Übergabeprotokoll für Ihre Wohnung zukommen, damit wir dieses für Ihre Anmeldung vorlegen können. Nutzen Sie dafür unsere Mailadresse: {{ environment.brandEmail }}
        </ng-container>
        <ng-container *ngSwitchCase="32">
          Der Vormieter Ihrer neuen Wohnung hat sich nicht abgemeldet. Bitte lassen Sie uns ein Übergabeprotokoll für Ihre Wohnung zukommen, damit wir dieses für Ihre Anmeldung vorlegen können. Nutzen Sie dafür unsere Mailadresse: {{ environment.brandEmail }}
        </ng-container>
      </p>
      <a (click)="openOrderCorrectionDialog()" class="w-full navigate mat-subheading-1 flex flex-row items-center justify-between order-3 md:order-2 md:w-1/12 md:justify-end md:items-start">
        <span class="md:hidden">
          Überprüfen
        </span>
        <span class="material-symbols-outlined">
          chevron_right
        </span>
      </a>
    </ng-container>
  </div>
</ng-container>
