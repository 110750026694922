<div class="w-full flex flex-row justify-around py-5 border-t border-gray-200" *ngIf="authService.contract">
  <a routerLink="/{{ environment.dashboardUrl }}" class="flex flex-col min-w-[80px]">
    <span class="text-center">
      <mat-icon color="primary">grid_view</mat-icon>
    </span>
    <span class="text-center">Übersicht</span>
  </a>
  <a *ngIf="showTariff" routerLink="/{{ environment.tariffUrl }}" class="flex flex-col min-w-[80px]">
    <span class="text-center">
      <mat-icon color="primary" class="material-symbols-outlined">broken_image</mat-icon>
    </span>
    <span class="text-center">Tarif</span>
  </a>
  <a (click)="openDialog('Wirklich ausloggen?')" class="flex flex-col min-w-[80px]">
    <span class="text-center">
      <mat-icon color="primary">logout</mat-icon>
    </span>
    <span class="text-center">Logout</span>
  </a>
</div>
