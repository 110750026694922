import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MagicKeyService } from '../services/magic-key.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MagicKeySendingError } from "../enumerations/MagicKeySendingError";
import { ObservableStatus } from "../../observable-status/classes/observable-status";
import { MagicKeyCreationRequestResponse } from "../interfaces/magic-key-creation-request-response";
import { withSlowLoadingStatus } from "../../observable-status/extensions/observable.extension";
import { NotificationType } from '../../notification/enumerations/notification-type';

@Component({
  selector: 'app-magic-key-create',
  templateUrl: './magic-key-create.component.html'
})
export class MagicKeyCreateComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  MagicKeySendingError = MagicKeySendingError;
  magicKeyCreateFormGroup: FormGroup | null = null;
  @ViewChild('magicKeyCreateForm') magicKeyCreateForm: NgForm | null = null;
  isErrorRedirection: boolean = false;
  magicKeySendResponse: ObservableStatus<MagicKeyCreationRequestResponse> = ObservableStatus.none<MagicKeyCreationRequestResponse>();
  NotificationType = NotificationType;
  customerOrContractIdMaximumLength: number = 20;
  @HostBinding('class') flexClass = 'flex-1';
  environment = environment;

  private resetNotifications(): void {
    this.isErrorRedirection = false;
    this.magicKeySendResponse = ObservableStatus.none<MagicKeyCreationRequestResponse>();
  }

  constructor(private route: ActivatedRoute, private magicKeyService: MagicKeyService, private formBuilder: FormBuilder) {
  }

  onSubmit(): void {
    if (this.magicKeyCreateForm!.invalid || this.magicKeySendResponse.isInLoadingState) {
      return;
    }
    this.resetNotifications();
    withSlowLoadingStatus(
      this.magicKeyService.create(this.magicKeyCreateFormGroup!.controls.contractId.value)
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(magicKeyCreateOperation => {
        this.magicKeySendResponse = magicKeyCreateOperation;
      });
  }

  ngOnInit(): void {
    let isKeyExpired = this.route.snapshot.queryParamMap.get('isKeyExpired');
    if (isKeyExpired) {
      this.isErrorRedirection = JSON.parse(isKeyExpired);
    }
    this.magicKeyCreateFormGroup = this.formBuilder.group({
      contractId: [null, [Validators.required, Validators.maxLength(this.customerOrContractIdMaximumLength)]]
    });
    this.magicKeyCreateFormGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.resetNotifications();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}

