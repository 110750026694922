import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FaqComponent} from "./components/faq.component";
import {environment} from "../../../environments/environment";
import {ContactComponent} from "./components/contact.component";
import {TopicComponent} from "./components/topic.component";
import {ContactTopicComponent} from "./components/contact-topic.component";
import {ContactTopicRequestComponent} from "./components/contact-topic-request.component";
import {TagComponent} from "./components/tag.component";

const routes: Routes = [
  // {
  //  title: 'Hilfe & Kontakt | ' + environment.brandName,
  //  path: 'faq',
  //  children: [
  //    {
  //      path: '',
  //      component: FaqComponent,
  //    },
  //    {
  //      path: 'contact',
  //      component: ContactComponent,
  //      data: {title: 'Kontakt | ' + environment.brandName},
  //    },
  //    {
  //      path: 'contact/topic',
  //      data: {title: 'Kontakt | ' + environment.brandName},
  //      children: [
  //        {
  //          path: '',
  //          component: ContactTopicComponent,
  //          data: { title: 'Kontakt | ' + environment.brandName },
  //        },
  //        {
  //          path: ':route',
  //          component: ContactTopicRequestComponent,
  //          data: { title: 'Kontakt | ' + environment.brandName },
  //        },
  //      ],
  //    },
  //    {
  //      path: 'tags/:id',
  //      component: TagComponent
  //    },
  //    {
  //      path: 'topics/:id',
  //      component: TopicComponent
  //    }
  //  ]
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule {
}
