<table class="w-full" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="paymentMethod">
    <th mat-header-cell *matHeaderCellDef>Zahlungsart</th>
    <td mat-cell *matCellDef="let payment">
      <span *ngIf="payment.paymentMethod === PaymentMethod.DirectDebit">Bankeinzug</span>
      <span *ngIf="payment.paymentMethod === PaymentMethod.BankTransfer">Überweisung</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="receiptDate">
    <th mat-header-cell *matHeaderCellDef>Eingang</th>
    <td mat-cell *matCellDef="let payment">{{ payment.receiptDate|date:'mediumDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>Gezahlter Betrag</th>
    <td mat-cell *matCellDef="let payment">{{ payment.amount|number:'1.2' }} €</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons>
</mat-paginator>
