import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NotificationType } from '../enumerations/notification-type';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'cpc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

  @Input() class: string | null = null;
  @Input() notification: string | null = null;
  @Input() notificationType: NotificationType = NotificationType.Information;
  NotificationType = NotificationType;

}

