import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeterReadingComponent } from './components/meter-reading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MeterReadingCreateComponent } from './components/meter-reading-create.component';
import { MeterReadingViewComponent } from './components/meter-reading-view.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MeterReadingRoutingModule } from "./meter-reading-routing.module";
import { SharedModule } from "../shared/shared.module";
import { MeterReadingTableComponent } from './components/meter-reading-table.component';
import { ContactModule } from '../contact/contact.module';

@NgModule({
  declarations: [
    MeterReadingTableComponent,
    MeterReadingViewComponent,
    MeterReadingCreateComponent,
    MeterReadingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MeterReadingRoutingModule,
    ContactModule,
    SharedModule
  ]
})
export class MeterReadingModule { }
