<div class="container mx-auto px-4 py-8">
  <h1 class="mat-headline !my-0 !mb-6">
    Ihr Vertrag
  </h1>
  <ng-container *ngIf="selectedContract && groupedLoadingState !== ObservableDisplayState.Loading && groupedLoadingState !== ObservableDisplayState.None">
    <ng-container *ngIf="orderCorrectionSettings && orderCorrectionSettings.notification && orderStatusTimeline">
      <app-order-correction-notification [timeline]="orderStatusTimeline" [notification]="orderCorrectionSettings.notification"></app-order-correction-notification>
    </ng-container>
    <div class="flex content-center mb-4">
      <div class="w-full">
        <mat-card-title class="mb-4 mat-title w-full">
          <div class="flex w-full flex-wrap gap-x-5 p-4 mb-5 mt-2 rounded-xl color-thema contractHeader">
            <ng-container *ngIf="selectedContract.deliveryInformation.energyType === EnergyType.Electricity">
              <div class="energySymbol electricity">&nbsp;</div>
            </ng-container>
            <ng-container *ngIf="selectedContract.deliveryInformation.energyType === EnergyType.Gas">
              <div class="energySymbol gas">&nbsp;</div>
            </ng-container>
            <span class="w-full sm:flex-1">
              <ng-container *ngIf="selectedContract.deliveryInformation.energyType === EnergyType.Electricity">Strom-Tarif</ng-container>
              <ng-container *ngIf="selectedContract.deliveryInformation.energyType === EnergyType.Gas">Gas-Tarif</ng-container>
              <br>
              <span class="mat-subheading-2">{{currentPrice?.tariffName || '-'}}</span>
            </span>
            <span class="w-full mt-3 sm:mt-0 sm:flex-1">
              Vertragsnummer<br>
              <b>{{selectedContract.contractId}}</b>
            </span>
          </div>
        </mat-card-title>
        <ng-container *ngIf="orderStatusTimeline && orderStatusTimelineLoadingState === ObservableDisplayState.Value">
          <div class="my-4">
            <app-history>
              <app-history-entry *ngIf="orderStatusTimeline.orderReceivedStep.isVisible" [state]="orderStatusTimeline.orderReceivedStep.state!">
                <app-history-entry-title>
                  Auftrag eingegangen
                </app-history-entry-title>
                <app-history-entry-text>
                  <ng-container [ngSwitch]="orderStatusTimeline.orderReceivedStep.state">
                    <ng-container *ngSwitchCase="HistoryEntryState.Completed">
                      <p>Ihr Auftrag ist bei uns eingegangen und wird nun geprüft und verarbeitet.</p>
                    </ng-container>
                  </ng-container>
                </app-history-entry-text>
              </app-history-entry>
              <app-history-entry *ngIf="orderStatusTimeline.previousSupplierTerminatedStep.isVisible" [state]="orderStatusTimeline.previousSupplierTerminatedStep.state!">
                <app-history-entry-title>
                  Kündigung beim Vorversorger
                </app-history-entry-title>
                <app-history-entry-text>
                  <ng-container [ngSwitch]="orderStatusTimeline.previousSupplierTerminatedStep.state">
                    <ng-container *ngSwitchCase="HistoryEntryState.Started">
                      <p>Wir haben die Kündigung bei Ihrem vorherigen {{getPreviousSupplierLabel()}} {{orderStatusTimeline.previousSupplier}} zum {{orderStatusTimeline.previousSupplierCancellationDate|date:'mediumDate'}} eingeleitet und warten nun auf eine Rückmeldung.</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="HistoryEntryState.Completed">
                      <p>Wir haben erfolgreich eine Kündigung zum {{orderStatusTimeline.previousSupplierCancellationDate|date:'mediumDate'}} bei Ihrem vorherigen {{getPreviousSupplierLabel()}} {{orderStatusTimeline.previousSupplier}} ausgesprochen.</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="HistoryEntryState.Error">
                      <ng-container *ngIf="orderStatusTimeline.previousSupplierTerminatedStep.errorReason === OrderStatusErrorReason.HasContractCommitment">
                        <p>Leider konnten wir Ihren vorherigen {{getPreviousSupplierLabel()}} {{orderStatusTimeline.previousSupplier}} nicht zum {{orderStatusTimeline.previousSupplierCancellationDate|date:'mediumDate'}} kündigen, da Sie eine Vertragsbindung bis zum {{orderStatusTimeline.contractCommitmentUntil|date:'mediumDate'}} haben.</p>
                      </ng-container>
                      <ng-container *ngIf="orderStatusTimeline.previousSupplierTerminatedStep.errorReason !== OrderStatusErrorReason.HasContractCommitment">
                        <p>Leider konnten wir Ihren vorherigen {{getPreviousSupplierLabel()}} {{orderStatusTimeline.previousSupplier}} nicht kündigen.</p>
                      </ng-container>
                      <p>Eine Überprüfung Ihrer Daten ist erforderlich, um den Vorgang fortzusetzen.</p>
                    </ng-container>
                  </ng-container>
                </app-history-entry-text>
              </app-history-entry>
              <app-history-entry *ngIf="orderStatusTimeline.registrationWithGridOperatorStep.isVisible" [state]="orderStatusTimeline.registrationWithGridOperatorStep.state!">
                <app-history-entry-title>
                  Anmeldung beim Netzbetreiber
                </app-history-entry-title>
                <app-history-entry-text>
                  <ng-container [ngSwitch]="orderStatusTimeline.registrationWithGridOperatorStep.state">
                    <ng-container *ngSwitchCase="HistoryEntryState.Started">
                      <p>Die Anmeldung Ihrer Lieferstelle läuft, wir warten auf Rückmeldung von Ihrem Netzbetreiber {{orderStatusTimeline.gridOperator}}.</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="HistoryEntryState.Completed">
                      <p>Ihre Lieferstelle wurde bei Ihrem Netzbetreiber {{orderStatusTimeline.gridOperator}} zum {{orderStatusTimeline.gridOperatorRegistrationDate|date:'mediumDate'}} angemeldet.</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="HistoryEntryState.Error">
                      <p>Leider konnte Ihre Lieferstelle nicht zum {{orderStatusTimeline.gridOperatorRegistrationDate|date:'mediumDate'}} bei Ihrem Netzbetreiber {{orderStatusTimeline.gridOperator}} angemeldet werden.</p>
                      <p>Eine Überprüfung Ihrer Daten ist erforderlich, um den Vorgang fortzusetzen.</p>
                    </ng-container>
                  </ng-container>
                </app-history-entry-text>
              </app-history-entry>
              <app-history-entry *ngIf="orderStatusTimeline.supplyConfirmedStep.isVisible" [state]="orderStatusTimeline.supplyConfirmedStep.state!">
                <app-history-entry-title>
                  Belieferung bestätigt
                </app-history-entry-title>
                <app-history-entry-text>
                  <ng-container [ngSwitch]="orderStatusTimeline.supplyConfirmedStep.state">
                    <ng-container *ngSwitchCase="HistoryEntryState.Completed">
                      <p>Ihr Vertrag ist nun bestätigt.</p>
                      <p>Ihre Energieversorgung beginnt mit dem Lieferbeginn zum {{ orderStatusTimeline.confirmedDeliveryDate|date:'mediumDate' }}.</p>
                    </ng-container>
                  </ng-container>
                </app-history-entry-text>
              </app-history-entry>
            </app-history>
          </div>
        </ng-container>
        <ng-container *ngIf="false && orderStatusTimelineLoadingState === ObservableDisplayState.Error">
          <app-notification [notificationType]="NotificationType.Error" notification="Technischer Fehler, bitte später erneut versuchen."></app-notification>
        </ng-container>
        <ng-container *ngIf="false && orderStatusTimelineLoadingState === ObservableDisplayState.NoValue">
          <app-notification [notificationType]="NotificationType.Warning" notification="Bisher wurden noch keine Daten hinterlegt."></app-notification>
        </ng-container>
        <div class="flex flex-col md:flex-row mb-3">
          <div class="w-full md:flex-1 md:mr-4 mb-3 mb-md-0">
            <span class="w-full mb-2 mat-subheading-2">Lieferstelle</span>
            <div class="border rounded p-2">
              <div class="flex flex-col">
                <span>{{selectedContract.deliveryAddress.person.firstName}} {{selectedContract.deliveryAddress.person.lastName}}</span>
                <span>{{selectedContract.deliveryAddress.location.street}} {{selectedContract.deliveryAddress.location.houseNumber}}</span>
                <span>{{selectedContract.deliveryAddress.location.postcode}} {{selectedContract.deliveryAddress.location.city}}</span>
              </div>
            </div>
          </div>
          <div class="w-full md:flex-1 md:ml-4">
            <span class="w-full mb-2 mat-subheading-2">Rechnung an</span>
            <div class="border rounded p-2">
              <ng-container *ngIf="lodash.isEqual(selectedContract.deliveryAddress.location, selectedContract.customerAddress.location)">
                Entspricht aktuell der Lieferstelle
                <br><br><br>
              </ng-container>
              <ng-container *ngIf="! lodash.isEqual(selectedContract.deliveryAddress.location, selectedContract.customerAddress.location)">
                <div class="flex flex-col">
                  <span>{{selectedContract.customerAddress.person.firstName}} {{selectedContract.customerAddress.person.lastName}}</span>
                  <span>{{selectedContract.customerAddress.location.street}} {{selectedContract.customerAddress.location.houseNumber}}</span>
                  <span>{{selectedContract.customerAddress.location.postcode}} {{selectedContract.customerAddress.location.city}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="flex py-2">
          <div class="w-1/2 mat-subheading-2 !my-0">
            Lieferbeginn
          </div>
          <div class="w-1/2 text-end">
            {{(selectedContract.deliveryInformation.deliveryStart | date: 'mediumDate') || '-'}}
          </div>
        </div>
        <div class="flex py-2">
          <div class="w-1/2 mat-subheading-2 !my-0">
            Lieferende
          </div>
          <div class="w-1/2 text-end">
            {{(selectedContract.deliveryInformation.deliveryEnd | date: 'mediumDate') || '-'}}
          </div>
        </div>
        <div class="flex py-2">
          <div class="w-1/2 mat-subheading-2 !my-0">
            Zählernummer
          </div>
          <div class="w-1/2 text-end">
            {{meter?.meterNumber || '-'}}
          </div>
        </div>
        <div class="flex py-2">
          <div class="w-1/2 mat-subheading-2 !my-0">
            <ng-container *ngIf="selectedContract.deliveryInformation.isYearlyConsumptionBasedOnMeterReadings">
              <span class="dialogTrigger flex items-center">
                Errechneter Verbrauch
                <mat-icon class="!text-[#C8C8C8] ml-1 cursor-pointer" (click)="openCalculatedYearlyConsumptionDialog()">help</mat-icon>
              </span>
            </ng-container>
            <ng-container *ngIf="!selectedContract.deliveryInformation.isYearlyConsumptionBasedOnMeterReadings">
              <span class="dialogTrigger flex items-center">
                Kundenangabe
                <mat-icon class="!text-[#C8C8C8] ml-1 cursor-pointer" (click)="openCustomerDeclaredYearlyConsumptionDialog()">help</mat-icon>
              </span>
            </ng-container>
          </div>
          <div class="w-1/2 text-end">
            <ng-container *ngIf="selectedContract.deliveryInformation.isYearlyConsumptionBasedOnMeterReadings">{{selectedContract.deliveryInformation.yearlyConsumptionInKwh || '-'}} kWh/Jahr</ng-container>
            <ng-container *ngIf="!selectedContract.deliveryInformation.isYearlyConsumptionBasedOnMeterReadings">{{selectedContract.deliveryInformation.customerDeclaredYearlyConsumptionInKwh || '-'}} kWh/Jahr</ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row lg:flex-wrap lg:gap-x-5 mt-4 mb-4 fadedBG rounded-xl primaryColor py-1 px-3" *ngIf="orderCorrectionSettings!.isAvailable && environment.canCorrectOrder">
      <div class="w-full lg:flex-1 mt-2 mb-2">
        Sie haben einen Fehler entdeckt oder möchten etwas ändern?
      </div>
      <div class="w-full lg:flex-1 mt-2 mb-2 lg:text-end fw-bold">
        <a (click)="openOrderCorrectionDialog(orderCorrectionSettings!.stepperSettings!)">
          <mat-icon class="primaryColor sectionHeading mr-1">border_color</mat-icon>
          Änderung der Auftragsdaten anfragen
        </a>
      </div>
    </div>
    <mat-accordion multi="false" class="mt-5">
      <mat-expansion-panel *ngIf="environment.isMonthlyPaymentCardVisible">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Zahlung
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="linkWrapper w-full">
          <a routerLink="/{{ environment.monthlyPaymentUrl }}" style="color: #707070; display: block;" class="mat-body-1">
            <mat-icon style="font-size: 14px; width: 16px;">border_color</mat-icon>
            Zahlbetrag ändern
          </a>
          <a routerLink="/{{ environment.paymentInformationUrl }}" style="color: #707070; display: inline-block;" class="mat-body-1">
            <mat-icon style="font-size: 14px; width: 16px;">border_color</mat-icon>
            Zahlungsweise ändern
          </a>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="environment.isMeterReadingCardVisible">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Zählerstand
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a routerLink="/{{ environment.meterReadingUrl }}" style="color: #707070;" class="mat-body-1">
          <mat-icon style="font-size: 14px; width: 16px;">border_color</mat-icon>
          Zählerstand melden
        </a>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="currentPrice?.tariffType !== TariffType.Index && currentPrice?.tariffType !== TariffType.IndexHybrid">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Tarifdetails
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="currentPrice">
          <span>
            Ab {{currentPrice.dateFrom | date: 'mediumDate'}} beliefert Sie {{environment.brandName}} in {{selectedContract.deliveryAddress.location.city}} zu einem Arbeitspreis von {{currentPrice.workingPriceInEuroPerKwhGross * 100 | number : '1.2-2'}} ct pro kWh und zu einem Grundpreis von {{currentPrice.basicPriceInEuroGross | number : '1.2-2'}} € pro Monat. Ihr Vertrag hat eine Laufzeit von {{currentPrice.contractTerm | time : true}}. Die Kündigungsfrist beträgt {{currentPrice.noticePeriod | time : false}} zum Ende der Vertragslaufzeit.
          </span>
          <div class="mt-3 flex items-center" *ngIf="selectedContract.deliveryInformation.energyType === EnergyType.Gas">
            <mat-icon class="text-2xl w-8 mr-2">error</mat-icon>
            <span>Seit dem 01.04.2024 gilt wieder eine MwSt. von 19% auf Ihren Arbeits- und Grundpreis.</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!currentPrice">
          <span>
            Die Tarifübersicht folgt, sobald Ihr Lieferbeginn bestätigt wurde.
          </span>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
  <ng-container *ngIf="groupedLoadingState === ObservableDisplayState.Loading">
    <div class="flex flex-col items-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</div>
