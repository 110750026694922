import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { Document } from "../interfaces/document";
import { DocumentMeta } from '../interfaces/document-meta';

@Injectable({
  providedIn: 'root'
})

export class DocumentsService {

  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getDocumentMetas(contractId: string): Observable<DocumentMeta[]> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<DocumentMeta[]>(`${this.baseUrl}api/documents/customer/${contractId}`, { headers: headers });
  }

  getDocument(documentId: string, contractId: string): Observable<Document> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<Document>(`${this.baseUrl}api/documents/${documentId}/download?contractId=${contractId}`, { headers: headers });
  }

  async downloadDocument(document: Document): Promise<void> {
    const file = await this.convertToFile(document);
    const url = URL.createObjectURL(file);
    const link = window.document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();
    URL.revokeObjectURL(url);
  }

  async convertToFile(document: Document): Promise<File> {
    const file = this.byteArrayToFile(await this.base64ToUint8Array(document.bytes), document.name);
    return file;
  }

  async base64ToUint8Array(base64String: string): Promise<Uint8Array> {
    const response = await fetch(`data:application/octet-stream;base64,${base64String}`);
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
  }

  byteArrayToFile(byteArray: Uint8Array, filename: string): File {
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    const file = new File([blob], filename, { type: 'application/octet-stream' });
    return file;
  }

}
