<div class="container mx-auto px-4 py-8">
  <app-breadcrumb></app-breadcrumb>

  <mat-card-title>
    <h1 class="mat-headline">
      Zu welchem Thema?
    </h1>
  </mat-card-title>

  <div class="flex flex-wrap">
    <ng-container *ngIf="favourites.length > 0">
      <div class="w-full">
        <app-favourites [favourites]="favourites"></app-favourites>
      </div>
    </ng-container>
    <ng-container *ngIf="topics.length > 0">
      <div class="w-full my-8">
        <p class="font-bold">Zu welchem Thema haben Sie eine Frage?</p>
        <app-topics [topics]="topics"></app-topics>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="topics.length === 0">
    <div class="w-full my-8">
      <app-notification [notificationType]="NotificationType.Information"
                        notification="Keine Themen verfügbar.">
      </app-notification>
    </div>
  </ng-container>

  <div class="flex flex-wrap justify-center">
    <ng-container *ngIf="this.faqState.isInLoadingState">
      <div class="m-auto flex items-center my-16 w-full z-10">
        <mat-spinner class="m-auto"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="faqState.isInErrorState">
      <div class="w-full my-8">
        <app-notification [notificationType]="NotificationType.Error"
                          notification="Die Themen sind derzeit nicht verfügbar. Bitte versuchen Sie es später erneut.">
        </app-notification>
      </div>
    </ng-container>
  </div>
</div>
