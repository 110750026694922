import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { MoveSuccessComponent } from './components/move-success.component';
import { MoveComponent } from './components/move.component';
import { environment } from '../../../environments/environment';

const routes: Routes = [
  {
    title: 'Umzug melden | Kundenportal | ' + environment.brandName,
    path: environment.moveUrl,
    children: [
      {
        title: 'Umzug melden | Kundenportal | ' + environment.brandName,
        path: '',
        component: MoveComponent,
        canActivate: [AuthGuardService],
      },
      {
        title: 'Umzug gemeldet | Kundenportal | ' + environment.brandName,
        path: environment.moveSuccessUrl,
        children: [
          {
            title: 'Umzug gemeldet | Kundenportal | ' + environment.brandName,
            path: '',
            component: MoveSuccessComponent,
            canActivate: [AuthGuardService],
          }]
      },
      {
        path: '**',
        redirectTo: '',
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class MoveRoutingModule { }
