import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { ReceiptOfPaymentViewComponent } from './components/receipt-of-payment-view.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReceiptOfPaymentTableComponent } from './components/receipt-of-payment-table.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    ReceiptOfPaymentTableComponent,
    ReceiptOfPaymentViewComponent
  ],
  exports: [
    ReceiptOfPaymentTableComponent,
    ReceiptOfPaymentViewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule
  ]
})
export class PaymentModule { }
