import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { DocumentsService } from "../services/documents.service";
import { MatTableDataSource } from "@angular/material/table";
import { Contract } from "../../shared/interfaces/contract";
import { DocumentMeta } from "../interfaces/document-meta";
import { MatPaginator } from "@angular/material/paginator";
import { DocumentCategory } from "../enumerations/document-category";

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html'
})
export class DocumentTableComponent implements OnDestroy, AfterViewInit, OnChanges {

  private destroyed$ = new Subject<void>();
  @Input() contract: Contract | null = null;
  @Input() documents: DocumentMeta[] | null = null;
  dataSource: MatTableDataSource<DocumentMeta> = new MatTableDataSource<DocumentMeta>();
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  DocumentCategory = DocumentCategory;
  displayedColumns: string[] = ['documentCategory', 'documentCreatedAt', "download"];

  constructor(private documentsService: DocumentsService) {
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.documents) {
      return;
    }
    if (!changes.documents.currentValue) {
      this.dataSource.data = [];
      return;
    }
    this.dataSource.data = changes.documents.currentValue;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onDownloadClick(documentId: string): void {
    this.documentsService.getDocument(documentId, this.contract!.contractId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(document => {
        this.documentsService.downloadDocument(document);
      });
  }

}
