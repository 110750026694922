import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveComponent } from './components/move.component';
import { MoveRoutingModule } from './move-routing.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MoveSuccessComponent } from './components/move-success.component';
import { SharedModule } from '../shared/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatDatepickerModule} from "@angular/material/datepicker";

@NgModule({
  declarations: [MoveComponent, MoveSuccessComponent],
	imports: [
		CommonModule,
		MoveRoutingModule,
		SharedModule,
		MatStepperModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatCardModule,
		MatProgressBarModule,
		RouterModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
    MatDatepickerModule
	]
})
export class MoveModule { }
