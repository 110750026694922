<div class="container mx-auto px-4 py-8">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Dokumente
    </h1>
  </mat-card-title>
  <div class="flex flex-col md:flex-row">
    <div class="w-full md:flex-1 md:mr-5 mb-10 md:mb-0">
      <ng-container *ngIf="documentMetas.isInNoValueState || documentMetas.isInValueState">
        <app-notification *ngIf="!documentMetas.value?.length" [notificationType]="NotificationType.Information" notification="Bisher sind keine Dokumente hinterlegt."></app-notification>
        <ng-container *ngIf="documentMetas.value?.length && documentGroups">
          <mat-accordion class="w-full">
            <mat-expansion-panel hideToggle *ngFor="let documentGroup of documentGroups; let i = index; first as isFirst" [expanded]="isFirst">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{documentGroup.name}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-document-table [contract]="selectedContract" [documents]="documentGroup.documents">
              </app-document-table>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="documentMetas.isInErrorState">
        <app-notification [notificationType]="NotificationType.Error" notification="Ihre Dokumente sind zurzeit nicht verfügbar – Bitte versuchen Sie es später erneut."></app-notification>
      </ng-container>
      <ng-container *ngIf="documentMetas.isInLoadingState">
        <div class="flex justify-center w-full">
          <mat-spinner></mat-spinner>
        </div>
      </ng-container>
    </div>
    <div class="w-full md:w-4/12">
      <app-contact-card></app-contact-card>
    </div>
  </div>
</div>


