import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { MonthlyPaymentDetail } from "../interfaces/monthly-payment-detail";
import { AuthService } from "../../shared/services/auth.service";
import { MonthlyPaymentUpdate } from '../interfaces/monthly-payment-update';
import { PriceDetail } from '../../price/interfaces/price-detail';

@Injectable({
  providedIn: 'root'
})
export class MonthlyPaymentService {

  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getMonthlyPaymentDetail(contractId: string): Observable<MonthlyPaymentDetail> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<MonthlyPaymentDetail>(`${this.baseUrl}api/monthly-payments/${contractId}/detail`, { headers: headers });
  }

  updateMonthlyPayment(value: MonthlyPaymentUpdate, contractId: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.put(`${this.baseUrl}api/monthly-payments/${contractId}`, value, { headers: headers });
  }

  calculateMonthlyCostsWithPriceDetail(yearlyConsumption: number, priceDetail: PriceDetail): number {
    return this.calculateMonthlyCosts
      (
        yearlyConsumption,
        priceDetail.workingPriceInEuroPerKwhNet,
        priceDetail.basicPriceInEuroNet,
        priceDetail.packageSize,
        priceDetail.workingPriceAdditionalConsumptionInEuroPerKwhNet
      );
  }

  calculateMonthlyCosts(yearlyConsumption: number, workingPrice: number, basicPrice: number, packageSize: number, workingPriceAdditionalConsumption: number): number {
    let additionalConsumption = 0;
    let normalConsumption = yearlyConsumption;
    let isPackage = packageSize > 0;
    if (isPackage) {
      additionalConsumption = Math.max(yearlyConsumption - packageSize, 0);
      normalConsumption = packageSize;
    }
    let yearlyCosts = workingPrice * normalConsumption + basicPrice * 12 + additionalConsumption * workingPriceAdditionalConsumption;
    return yearlyCosts / 12;
  }

  calculateYearlyConsumptionWithPriceDetail(monthlyCosts: number, priceDetail: PriceDetail) {
    return this.calculateYearlyConsumption
      (
        monthlyCosts,
        priceDetail.workingPriceInEuroPerKwhNet,
        priceDetail.basicPriceInEuroNet,
        priceDetail.packageSize,
        priceDetail.workingPriceAdditionalConsumptionInEuroPerKwhNet
      );
  }

  calculateYearlyConsumption(monthlyCosts: number, workingPrice: number, basicPrice: number, packageSize: number, workingPriceAdditionalConsumption: number): number {
    let yearlyCosts = monthlyCosts * 12;
    let basicPriceCosts = basicPrice * 12;
    let workingPriceCosts = yearlyCosts - basicPriceCosts;
    let additionalWorkingPriceCosts = 0;
    let isPackage = packageSize > 0;
    let normalConsumption = 0;
    let additionalConsumption = 0;
    if (isPackage) {
      additionalWorkingPriceCosts = Math.max(workingPriceCosts - workingPrice * packageSize, 0)
      if (additionalWorkingPriceCosts != 0) {
        additionalConsumption = additionalWorkingPriceCosts / workingPriceAdditionalConsumption;
      }
    }
    let normalWorkingPriceCosts = workingPriceCosts - additionalWorkingPriceCosts;
    normalConsumption = normalWorkingPriceCosts / workingPrice;
    return normalConsumption + additionalConsumption;
  }

}
