<ng-container *ngIf="displayInCard">
  <ng-container *ngIf="dailyPriceSnapshot">
    <div class="flex flex-col">
      <div class="w-full justify-center flex relative">
        <mat-progress-spinner
          mode="determinate"
          value="100"
          diameter="130"
          strokeWidth="5"
          class="daily-price-spiral"
        >
        </mat-progress-spinner>
        <ng-container *ngIf="!priceService.checkIfInFixedPricePeriod(currentPrice!)">
          <div class="absolute h-full flex flex-col justify-center items-center">
            <ng-container *ngIf="showTotal">
              <span class="mat-subheading-2 !my-0" style="font-size: 30px;">{{ dailyPriceSnapshot.totalPriceInEuroGross | roundUpDecimal : 1 | number:'1.1-1' }}</span>
            </ng-container>
            <ng-container *ngIf="!showTotal">
              <span class="mat-subheading-2 !my-0" style="font-size: 30px;">{{ dailyPriceSnapshot.purchasePriceInEuroGross | roundUpDecimal : 1 | number:'1.1-1' }}</span>
            </ng-container>
            <span class="mat-body mt-1">
              ct/kWh • {{ dailyPriceSnapshot.day | date : 'd MMM' }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="priceService.checkIfInFixedPricePeriod(currentPrice!)">
          <div class="absolute h-full flex flex-col justify-center items-center">
            <ng-container *ngIf="showTotal">
              <span class="mat-subheading-2 !my-0">{{currentPrice!.workingPriceInEuroPerKwhGross * 100 | number : '1.2-2'}}</span>
            </ng-container>
            <span>
              ct/kWh
            </span>
          </div>
        </ng-container>
      </div>
      <span class="w-full !mt-5 text-center mat-subheading-2" *ngIf="!priceService.checkIfInFixedPricePeriod(currentPrice!)">
        Durchschnittlicher Arbeitspreis
      </span>
      <span class="w-full !mt-5 text-center mat-subheading-2" *ngIf="priceService.checkIfInFixedPricePeriod(currentPrice!)">
        Im 1. Monat
      </span>
      <ng-container *ngIf="showTotal">
        <span class="w-full text-center text-[#808080]">
          <span>Bruttopreise inkl. </span>
          <span *ngIf="!environment.hasOtherPriceComponents">Netznutzungsengelte, Abgaben und Steuern</span>
          <span *ngIf="environment.hasOtherPriceComponents">weiterer Beschaffungskosten</span>
        </span>
      </ng-container>
      <ng-container *ngIf="!showTotal">
        <span class="w-full text-center text-[#808080]">
          <span>Bruttopreise zzgl. </span>
          <span *ngIf="!environment.hasOtherPriceComponents">Netznutzungsengelte, Abgaben und Steuern</span>
          <span *ngIf="environment.hasOtherPriceComponents">weiterer Beschaffungskosten</span>
        </span>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!displayInCard">
  <ng-container *ngIf="dailyPriceSnapshot">
    <div class="flex">
      <div class="justify-center flex relative mr-10">
        <mat-progress-spinner
          mode="determinate"
          value="100"
          diameter="130"
          strokeWidth="5"
          class="daily-price-spiral"
        >
        </mat-progress-spinner>
        <ng-container *ngIf="!priceService.checkIfInFixedPricePeriod(currentPrice!)">
          <div class="absolute h-full flex flex-col justify-center items-center">
            <ng-container *ngIf="showTotal">
              <span class="mat-subheading-2 !my-0" style="font-size: 30px;">{{ dailyPriceSnapshot.totalPriceInEuroGross | roundUpDecimal : 1 | number:'1.1-1' }}</span>
            </ng-container>
            <ng-container *ngIf="!showTotal">
              <span class="mat-subheading-2 !my-0" style="font-size: 30px;">{{ dailyPriceSnapshot.purchasePriceInEuroGross | roundUpDecimal : 1 | number:'1.1-1' }} ct/kWh</span>
            </ng-container>
            <span class="mat-body mt-1">
              ct/kWh • {{ dailyPriceSnapshot.day | date : 'd MMM' }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="priceService.checkIfInFixedPricePeriod(currentPrice!)">
          <div class="absolute h-full flex flex-col justify-center items-center">
            <ng-container *ngIf="showTotal">
              <span class="mat-subheading-2 !my-0">{{currentPrice!.workingPriceInEuroPerKwhGross * 100 | number : '1.2-2'}}</span>
            </ng-container>
            <span>
              ct/kWh
            </span>
          </div>
        </ng-container>
      </div>
      <div class="flex-1 flex flex-col justify-center">
        <ng-container *ngIf="!priceService.checkIfInFixedPricePeriod(currentPrice!)">
          <span class="w-full mat-subheading-2 !my-0">
            Durchschnittlicher Arbeitspreis
          </span>
        </ng-container>
        <ng-container *ngIf="priceService.checkIfInFixedPricePeriod(currentPrice!)">
          <span class="w-full mat-subheading-2 !my-0">
            Im 1. Monat
          </span>
        </ng-container>
        <ng-container *ngIf="showTotal">
          <span class="w-full text-[#808080]">
            <span>Bruttopreise inkl. </span>
            <span *ngIf="!environment.hasOtherPriceComponents">Netznutzungsengelte, Abgaben und Steuern</span>
            <span *ngIf="environment.hasOtherPriceComponents">weiterer Beschaffungskosten</span>
          </span>
        </ng-container>
        <ng-container *ngIf="!showTotal">
          <span class="w-full text-[#808080]">
            <span>Bruttopreise zzgl. </span>
            <span *ngIf="!environment.hasOtherPriceComponents">Netznutzungsengelte, Abgaben und Steuern</span>
            <span *ngIf="environment.hasOtherPriceComponents">weiterer Beschaffungskosten</span>
          </span>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
