import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { AccountStatementComponent } from './components/account-statement.component';
import { AccountStatementRoutingModule } from "./account-statement-routing.module";
import { MatCardModule } from "@angular/material/card";
import { ContactModule } from "../contact/contact.module";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialogModule } from "@angular/material/dialog";
import { ConsumptionPriceDialogComponent } from './components/consumption-price-dialog.component';
import { TotalBalanceDialogComponent } from "./components/total-balance-dialog.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import {SharedModule} from "../shared/shared.module";


@NgModule({
  providers: [DatePipe],
  declarations: [
    AccountStatementComponent,
    ConsumptionPriceDialogComponent,
    TotalBalanceDialogComponent
  ],
  imports: [
    CommonModule,
    AccountStatementRoutingModule,
    MatCardModule,
    ContactModule,
    MatIconModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    SharedModule
  ]
})
export class AccountStatementModule { }
