import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegalLinksComponent } from './components/legal-links.component';



@NgModule({
  declarations: [
    LegalLinksComponent
  ],
  exports: [
    LegalLinksComponent
  ],
  imports: [
    CommonModule
  ]
})
export class LegalLinksModule { }
