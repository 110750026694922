import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from "rxjs";
import {ContactCategory} from '../interfaces/contact-category';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../../shared/services/auth.service";
import { Settings } from "../../shared/interfaces/settings";

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  environment = environment;
  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getContactCategories(): Observable<ContactCategory[] | null> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<ContactCategory[] | null>(`${this.baseUrl}api/contact-categories`, {headers: headers});
  }

  public replacePlaceholders(possibleCategories: ContactCategory[], environment: Settings) {
    possibleCategories.forEach( category => {
      if (category && category.description){
        category.description = this.replaceWithEnvironmentValues(category.description, environment);
      }
    });
  }
  private replaceWithEnvironmentValues(input: string, environment : Settings): string {
    for (const key in environment) {
      if (Object.prototype.hasOwnProperty.call(environment, key)) {
        const value = (environment as any)[key];
        input = input.replace(`{${key}}`, value);
      }
    }
    return input;
  }
}
