import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { concatMap, Subject, takeUntil } from 'rxjs';
import { MagicKeyAuthentificationType } from '../enumerations/magic-key-authentification-type';
import { MagicKeyService } from '../services/magic-key.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from '../../../../environments/environment';
import { NotificationType } from '../../notification/enumerations/notification-type';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus, withSlowLoadingStatus } from '../../observable-status/extensions/observable.extension';

@Component({
  selector: 'app-magic-key-login',
  templateUrl: './magic-key-login.component.html'
})
export class MagicKeyLoginComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  private returnUrl: string | null = null;
  private magicKey: string | null = null;
  MagicKeyAuthentificationType = MagicKeyAuthentificationType;
  NotificationType = NotificationType;
  @ViewChild('magicKeyLoginForm') magicKeyLoginForm: NgForm | null = null;
  magicKeyLoginFormGroup: FormGroup | null = null;
  contractIdMaximumLength: number = 16;
  customerIdMaximumLength: number = 20;
  @HostBinding('class') flexClass = 'flex-1';
  loginProcess: ObservableStatus<void> = ObservableStatus.none<void>();
  magicKeyValidity: ObservableStatus<boolean> = ObservableStatus.none<boolean>();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private magicKeyService: MagicKeyService,
    private formBuilder: FormBuilder) {
  }

  private resetNotifications(): void {
    this.loginProcess = ObservableStatus.none<void>();
  }

  onSubmit(): void {
    if (this.magicKeyLoginForm!.invalid || this.loginProcess.isInLoadingState) {
      return;
    }
    this.resetNotifications();
    let loginSubscription = this.magicKeyService.login({
      birthdate: this.magicKeyLoginFormGroup!.controls.birthdate.value,
      contractId: this.magicKeyLoginFormGroup!.controls.contractId.value,
      customerId: this.magicKeyLoginFormGroup!.controls.customerId.value,
      magicKey: this.magicKey!
    }).pipe(concatMap(jwt => {
      return this.authService.login(jwt);
    }));
    withSlowLoadingStatus(
      loginSubscription
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(loginProcess => {
        this.loginProcess = loginProcess;
        if (this.loginProcess.isInValueState || this.loginProcess.isInNoValueState) {
          if (this.returnUrl) {
            this.router.navigate([`/${this.returnUrl}`]);
          }
          else {
            this.router.navigate([`/${environment.dashboardUrl}`]);
          }
        }
      });
  }

  onAuthentificationTypeChanged(): void {
    this.magicKeyLoginFormGroup!.controls.birthdate.clearValidators();
    this.magicKeyLoginFormGroup!.controls.customerId.clearValidators();
    this.magicKeyLoginFormGroup!.controls.contractId.clearValidators();
    if (this.magicKeyLoginFormGroup!.controls.authentificationType.value === MagicKeyAuthentificationType.Birthdate) {
      this.magicKeyLoginFormGroup!.controls.birthdate.addValidators([Validators.required]);
    }
    if (this.magicKeyLoginFormGroup!.controls.authentificationType.value === MagicKeyAuthentificationType.CustomerId) {
      this.magicKeyLoginFormGroup!.controls.customerId.addValidators([Validators.required, Validators.maxLength(this.customerIdMaximumLength)]);
    }
    if (this.magicKeyLoginFormGroup!.controls.authentificationType.value === MagicKeyAuthentificationType.ContractId) {
      this.magicKeyLoginFormGroup!.controls.contractId.addValidators([Validators.required, Validators.maxLength(this.contractIdMaximumLength)]);
    }
    this.magicKeyLoginForm?.resetForm({ authentificationType: this.magicKeyLoginFormGroup!.controls.authentificationType.value });
  }

  ngOnInit(): void {
    this.magicKey = this.route.snapshot.queryParamMap.get('magicKey');
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    if (!this.magicKey) {
      this.router.navigate([environment.magicKeyCreateUrl], { relativeTo: this.route });
    }
    this.magicKeyLoginFormGroup = this.formBuilder.group({
      birthdate: [null],
      contractId: [null, [Validators.maxLength(this.contractIdMaximumLength)]],
      customerId: [null, [Validators.maxLength(this.customerIdMaximumLength)]],
      authentificationType: [MagicKeyAuthentificationType.Birthdate, [Validators.required]]
    });
    this.magicKeyLoginFormGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.resetNotifications();
      });
    this.onAuthentificationTypeChanged();
    withNormalLoadingStatus(
      this.magicKeyService.getValidityStatus(this.magicKey!)
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(magicKeyValidity => {
        this.magicKeyValidity = magicKeyValidity;
        if ((this.magicKeyValidity.isInValueState || this.magicKeyValidity.isInNoValueState) && !this.magicKeyValidity.value) {
          this.router.navigate([environment.magicKeyCreateUrl], { queryParams: { isKeyExpired: true }, relativeTo: this.route });
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}

