import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { OrderCorrection } from '../interfaces/order-correction';
import { OrderCorrectionAvailability } from "../interfaces/order-correction-availability";
import { OrderCorrectionSettings } from '../interfaces/order-correction-settings';
import { OrderStatusTimeline } from '../interfaces/orders-status-timeline';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private authService: AuthService, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  insertOrderCorrection(contractId: string, orderCorrection: OrderCorrection): Observable<void> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.post<any>(`${this.baseUrl}api/order/correct/${contractId}`, orderCorrection, { headers: headers });
  }

  getLastOrderCorrection(contractId: string): Observable<OrderCorrection> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<OrderCorrection>(`${this.baseUrl}api/order/correct/${contractId}/last`, { headers: headers });
  }

  getOrderCorrectionAvailability(contractId: string): Observable<OrderCorrectionAvailability> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<OrderCorrectionAvailability>(`${this.baseUrl}api/order/correct/${contractId}/availability`, { headers: headers });
  }

  getOrderStatusTimeline(contractId: string): Observable<OrderStatusTimeline | null> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<OrderStatusTimeline | null>(`${this.baseUrl}api/order/status/${contractId}/timeline`, { headers: headers });
  }

  getOrderCorrectionSettings(contractId: string): Observable<OrderCorrectionSettings | null> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<OrderCorrectionSettings | null>(`${this.baseUrl}api/order/correct/${contractId}/settings`, { headers: headers });
  }

  /**
   * Custom equality check function to compare two OrderCorrection objects.
   * This function is necessary because additional parameters (e.g., createdAt)
   * might be present in the HTTP response, which are not part of the interface.
   * Using lodash or other libraries might not work as expected due to these extra fields.
   */
  isEqual(a: OrderCorrection | null, b: OrderCorrection | null): boolean {
    if (a === null && b === null) {
      return true;
    }
    if (a === null || b === null) {
      return false;
    }
    return a.meterNumber === b.meterNumber &&
      a.marketLocation === b.marketLocation &&
      a.previousSupplierTerminationState === b.previousSupplierTerminationState &&
      a.previousSupplierName === b.previousSupplierName &&
      a.previousSupplierTerminationDate === b.previousSupplierTerminationDate &&
      a.terminationConfirmationReceived === b.terminationConfirmationReceived &&
      a.firstDeviatingContractorFirstName === b.firstDeviatingContractorFirstName &&
      a.firstDeviatingContractorLastName === b.firstDeviatingContractorLastName &&
      a.secondDeviatingContractorFirstName === b.secondDeviatingContractorFirstName &&
      a.secondDeviatingContractorLastName === b.secondDeviatingContractorLastName &&
      a.deliveryStreet === b.deliveryStreet &&
      a.deliveryHouseNumber === b.deliveryHouseNumber &&
      a.deliveryPostcode === b.deliveryPostcode &&
      a.deliveryCity === b.deliveryCity &&
      a.requestedDeliveryStartDate === b.requestedDeliveryStartDate &&
      a.isMove === b.isMove;
  }

}
