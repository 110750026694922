<button [disabled]="disabled" type="{{type}}" customer-portal-button
        mat-raised-button color="{{color}}"
        class="w-full !px-10">
  <ng-container *ngIf="icon">
    <mat-icon >{{icon}}</mat-icon>
  </ng-container>
  {{caption}}
  <div class="w-full">
    <mat-progress-bar *ngIf="progressBarVisible" mode="indeterminate"></mat-progress-bar>
  </div>
</button>
