import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { of, Subject, takeUntil } from "rxjs";
import { DocumentsService } from "../services/documents.service";
import { switchMap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { Contract } from "../../shared/interfaces/contract";
import { DocumentMeta } from "../interfaces/document-meta";
import { DocumentCategory } from "../enumerations/document-category";
import { DocumentGroup } from "../interfaces/document-group";
import { ObservableStatus } from "../../observable-status/classes/observable-status";
import { withNormalLoadingStatus } from "../../observable-status/extensions/observable.extension";
import { NotificationType } from "../../shared/enumerations/notification-type";

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html'
})
export class DocumentsComponent implements OnInit, OnDestroy {

  @HostBinding('class') flexClass = 'flex-1';

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  NotificationType = NotificationType;
  environment = environment;

  documentMetas: ObservableStatus<DocumentMeta[]> = ObservableStatus.none<DocumentMeta[]>();
  documentGroups: DocumentGroup[] | null = null;
  selectedContract: Contract | null = null;

  constructor(
    private documentsService: DocumentsService,
    private customerSelectionService: CustomerSelectionService) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of(ObservableStatus.none<DocumentMeta[]>())
          }
          return withNormalLoadingStatus(
            this.documentsService.getDocumentMetas(this.selectedContract.contractId)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(documentMetas => {
        this.documentMetas = documentMetas;
        this.documentGroups = this.calculateDocumentGroups(this.documentMetas);
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  calculateDocumentGroups(documentMetas: ObservableStatus<DocumentMeta[]>): DocumentGroup[] | null {
    if (!documentMetas.isInValueState && !documentMetas.isInNoValueState) {
      return null;
    }
    let allDocuments : DocumentMeta[] = [];
    if (documentMetas.value) {
      allDocuments = documentMetas.value;
    }
    allDocuments.sort((first, second) => Date.parse(second.createdAt) - Date.parse(first.createdAt));
    let contractDocuments = allDocuments.filter(document => { return [DocumentCategory.TariffChange, DocumentCategory.SepaMandate, DocumentCategory.ContractConfirmation, DocumentCategory.SupplyConfirmation].includes(document.category); });
    let invoiceDocuments = allDocuments.filter(document => { return [DocumentCategory.FinalInvoice, DocumentCategory.Invoice, DocumentCategory.CorrectedInvoice, DocumentCategory.CorrectedFinalInvoice].includes(document.category); });
    let priceAdjustmentDocuments = allDocuments.filter(document => { return [DocumentCategory.PriceAdjustment].includes(document.category); });
    let miscellaneousDocuments = allDocuments.filter(document => { return [DocumentCategory.PriceCapInformation].includes(document.category); });
    return [
      { name: 'Vertragsunterlagen', documents: contractDocuments },
      { name: 'Rechnungen', documents: invoiceDocuments },
      { name: 'Leistungs\u00E4nderungen'.normalize(), documents: priceAdjustmentDocuments },
      { name: 'Allgemeine Gesch\u00E4ftsbedingungen'.normalize(), documents: [] },
      { name: 'Sonstige Dokumente', documents: miscellaneousDocuments }
    ];   
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

}
