import {Component, HostBinding} from '@angular/core';
import {CustomerType} from "../enumerations/customer-type";
import {Router} from "@angular/router";
import {ContactSelectionService} from "../services/contact-selection.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent {

  @HostBinding('class') flexClass = 'flex-1';
  CustomerType = CustomerType;
  environment = environment;
  constructor(private router: Router, private contactSelectionService: ContactSelectionService) {
  }

  onCustomerType(customerType: CustomerType) {
    this.contactSelectionService.selectCustomerType(customerType);
    this.router.navigate(['/faq/contact/topic']);
  }
}
