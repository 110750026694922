<div class="flex w-full">
  <div class="flex flex-col items-center justify-between flex-none pr-4 h-auto">
    <div class="rounded-full {{iconClass}} w-[24px] h-[24px] flex justify-center items-center">
      <mat-icon class="rounded-full {{iconClass}} !w-[24px] !h-[24px] !flex !justify-center !items-center !text-[18px]">
        <ng-container *ngIf="state === HistoryEntryState.Completed">check</ng-container>
        <ng-container *ngIf="state === HistoryEntryState.Error">close</ng-container>
      </mat-icon>
    </div>
    <div *ngIf="showLine" class="w-[4px] min-h-4 bg-gray-400 h-full {{lineClass}}"></div>
  </div>
  <div class="flex flex-col flex-grow">
    <div class="flex justify-between" [ngClass]="{'cursor-pointer': isExpandable}"
       (click)="isExpandable ? toggle() : null">
      <h2 class="flex-1 {{titleClass}}"><ng-content select="app-history-entry-title"></ng-content></h2>
      <div *ngIf="isExpandable">
        <mat-icon class="{{expandButtonClass}}" *ngIf="isExpanded">expand_less</mat-icon>
        <mat-icon class="{{expandButtonClass}}" *ngIf="!isExpanded">expand_more</mat-icon>
      </div>
    </div>
    <div *ngIf="isExpanded">
      <p class="mat-body"><ng-content select="app-history-entry-text"></ng-content></p>
    </div>
  </div>
</div>
