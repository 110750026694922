import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {catchError, map, Observable, of} from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private authService: AuthService, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getCities(postalcode: string): Observable<string[]> {
    if (!postalcode?.length){
      return of([]);
    }
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<string[]>(`${this.baseUrl}api/location/postalcode/${postalcode}`,  { headers: headers });
  }

  getPlace(postalcode: string, place: string): Observable<string[]> {
    if (!postalcode?.length){
      return of([]);
    }
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<string[]>(`${this.baseUrl}api/location/postalcode/${postalcode}/place/${place}`,  { headers: headers });
  }

}
