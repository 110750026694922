import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';
import { CreditSettlement } from '../interfaces/credit-settlement';
import { CreditSettlementType } from "../enumerations/credit-settlement-type";
import * as ibantools from "ibantools";
import { environment } from "../../../../environments/environment";
import { CreditSettlementTypeViewModel } from '../interfaces/credit-settlement-type-view-model';

@Injectable({
  providedIn: 'root'
})
export class CreditSettlementService {

  environment = environment;
  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getLastCreditSettlement(contractId: string): Observable<CreditSettlement> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<CreditSettlement>(`${this.baseUrl}api/credit-settlement/${contractId}/last`, { headers: headers });
  }

  insertCreditSettlement(contractId: string, value: CreditSettlement): Observable<void> {
    if (value.iban) {
      value.iban = ibantools.electronicFormatIBAN(value.iban);
    }
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.post<any>(`${this.baseUrl}api/credit-settlement/${contractId}`, value, { headers: headers });
  }

  getCreditSettlementTypeViewModels(): CreditSettlementTypeViewModel[] {
    let creditSettlementTypeViewModels = [
      { type: CreditSettlementType.PayoutByCheck, label: "Auszahlung per Verrechnungsscheck" },
      { type: CreditSettlementType.OffsetWithSubsequentPaymentAmount, label: "Verrechnung mit Folgezahlbetrag" },
      { type: CreditSettlementType.PayoutByBankTransfer, label: "Auszahlung per Überweisung" }];
    if (environment.showWaiveBalance) {
      creditSettlementTypeViewModels.push({ type: CreditSettlementType.WaiveBalance, label: "Auf das Guthaben verzichten" });
    }
    return creditSettlementTypeViewModels;
  }

  public blurIban(iban: string | undefined | null): string {
    if (!iban) {
      return '';
    }
    const clearedIban = ibantools.electronicFormatIBAN(iban);
    if (!clearedIban || !ibantools.isValidIBAN(clearedIban)) {
      return 'IBAN ungültig'
    } else {
      let removeCount = 15;
      let rightCount = 4;
      let leftCount = 3;
      return clearedIban.substr(0, leftCount) + "*".repeat(removeCount) + clearedIban.substr(leftCount + removeCount, rightCount);
    }
  }

}
