import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from "../../shared/services/auth.service";
import { FileUploadResult } from "../interfaces/file-upload-result";


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  uploadSepaFile(contractId: string, file: File): Observable<FileUploadResult> {
    return this.uploadFile(contractId, file, 'SEPA');
  }

  private uploadFile(contractId: string, file: File, endpoint: string): Observable<FileUploadResult> {
    const formData = new FormData();
    formData.append(file.name, file);
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.post<FileUploadResult>(`${this.baseUrl}api/file-upload/${contractId}/${endpoint}`, formData, { headers: headers });
  }

}
