<div class="flex flex-wrap my-8 gap-4">
  <mat-card class="lg:flex-1 w-full lg:w-auto !flex flex-wrap !p-6 flex-col cursor-pointer"
            *ngFor="let favourite of favourites"
            (click)="openFavourite(favourite)" [class.category-favourite]="favourite.type === FavouriteType.NewTab">
    <mat-card-title>
      <mat-icon color="primary" class="mr-2 material-symbols-outlined" matPrefix>
        {{ favourite.icon }}
      </mat-icon>
    </mat-card-title>
    <mat-card-content class="!flex-grow">
      <div class="mat-title pb-8" [innerHTML]="favourite.favourite"></div>
    </mat-card-content>
    <mat-card-actions class="mt-auto text-center !mb-0">
      <span class="uppercase opacity-40">
        {{ getRouteLabelDisplay(favourite.type) }}
      </span>
    </mat-card-actions>
  </mat-card>
</div>
