import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {of, Subject, switchMap, takeUntil} from "rxjs";
import {ObservableStatus} from "../../observable-status/classes/observable-status";
import {Favourite} from "../interfaces/favourite";
import {FaqService} from "../services/faq.service";
import {withNormalLoadingStatus} from "../../observable-status/extensions/observable.extension";
import {ContactSelectionService} from "../services/contact-selection.service";
import {ContactTopic} from "../interfaces/contact-topic";
import {AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators} from "@angular/forms";
import {Salutation} from "../enumerations/salutation";
import {KeyLabelViewModel} from "../interfaces/key-label-view-model";
import {Customer} from "../interfaces/customer";
import {CustomerType} from "../enumerations/customer-type";
import {environment} from "../../../../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-contact-topic-request',
  templateUrl: './contact-topic-request.component.html'
})
export class ContactTopicRequestComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') flexClass = 'flex-1';
  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  route: string | null = null;

  contactState: ObservableStatus<Customer[]> = ObservableStatus.none<Customer[]>();
  favourites: Favourite[] = [];
  contactTopics: ContactTopic[] = [];
  customerType: CustomerType | null = null;

  isContactTopicsVisible: boolean = false;
  isContactFormVisible: boolean = false;
  isTopicSyncVisible: boolean = false;

  contactFormGroup!: FormGroup;
  @ViewChild('contactForm') contactForm?: NgForm;
  fileName: string = '';
  filePreview: boolean = false;
  captcha: string = '';
  contactTopic: string | null = null;

  maximumMessageLength: number = 500;
  maximumLengthCustomerId: number = 20;
  maxLengthFirstName: number = 100;
  maxLengthLastName: number = 100;
  maxLengthEmailAddress: number = 100;
  maxLengthPhoneNumber: number = 100;
  readonly acceptedFileExtensions = environment.acceptedFileUploadExtensions;
  readonly maximumAllowedMb: number = 5;
  readonly maximumFileSizeInBytes = this.maximumAllowedMb * 1024 * 1024;

  salutations: KeyLabelViewModel<Salutation>[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private faqService: FaqService,
              private contactSelectionService: ContactSelectionService,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              private router: Router) {
  }

  ngOnInit(): void {
    this.initializeRouteParameters();
    this.salutations = this.contactSelectionService.getSalutationViewModels();

    this.setupForm();
    this.setupSubscriptions();

    this.refresh();
  }

  ngAfterViewInit(): void {
    this.refreshCaptcha();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setupForm(): void {
    this.contactFormGroup = this.formBuilder.group({
      customerType: [null, [Validators.required]],
      contactTopic: [null, [Validators.required]],
      salutation: [null, [Validators.required]],
      firstName: [null, [Validators.required, Validators.maxLength(this.maxLengthFirstName)]],
      lastName: [null, [Validators.required, Validators.maxLength(this.maxLengthLastName)]],
      emailAddress: [null, [Validators.required, Validators.email, Validators.maxLength(this.maxLengthEmailAddress)]],
      phoneNumber: [null, [Validators.required, Validators.maxLength(this.maxLengthPhoneNumber)]],
      customerId: [null, [Validators.maxLength(this.maximumLengthCustomerId)]],
      contactMessage: [null, [Validators.required, Validators.maxLength(this.maximumMessageLength)]],
      attachment: [null],
      privacyConsent: [false, [Validators.requiredTrue]],
      captchaInput: [null, [Validators.required, this.captchaValidator.bind(this)]]
    });
  }

  private setupSubscriptions(): void {
    this.refresh$
      .pipe(
        switchMap(() =>
          this.customerType
            ? withNormalLoadingStatus(this.faqService.getFaqContact())
            : of(ObservableStatus.none<Customer[]>())
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(contactState => {
        this.contactState = contactState;

        if (contactState.value) {
          const customer = contactState.value.find(item => item.customerType === this.customerType);
          this.contactTopics = customer?.contactTopics || [];
          this.favourites = customer?.favourites || [];
        }
      });


    this.contactSelectionService.customerTypeSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(customerType => {
        this.customerType = customerType || null;
        this.updateCustomerType();
      });
  }

  private initializeRouteParameters(): void {
    this.route = this.activatedRoute.snapshot.params.route || null;
  }

  private updateCustomerType(): void {
    if (this.customerType) {
      this.contactFormGroup.controls.customerType.setValue(this.customerType);
      const customerIdControl = this.contactFormGroup.controls.customerId;

      if (this.customerType === CustomerType.Customer) {
        customerIdControl.addValidators(Validators.required);
      }
      customerIdControl.updateValueAndValidity();
      this.refresh();
    }
  }

  refresh(): void {
    this.refresh$.next();
  }

  onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files?.length) {
      const file = inputElement.files[0];
      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

      if (file.size > this.maximumFileSizeInBytes) {
        this.setFileError('invalidFile');
      } else if (!this.acceptedFileExtensions.includes(fileExtension)) {
        this.setFileError('invalidFileType');
      } else {
        this.fileName = file.name;
        this.contactFormGroup.controls.attachment.setValue(file);
        this.filePreview = true;
      }
    }
  }

  private setFileError(error: string): void {
    this.fileName = '';
    this.contactFormGroup.controls.attachment.setErrors({[error]: true});
  }

  deleteFile(): void {
    this.fileName = '';
    this.contactFormGroup.controls.attachment.reset();
    this.filePreview = false;
  }

  refreshCaptcha(): void {
    this.captcha = this.generateCaptcha(6);
    this.contactFormGroup.controls.captchaInput.reset();
    this.cdr.detectChanges();
  }

  private generateCaptcha(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({length}, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  }

  private captchaValidator(control: AbstractControl): ValidationErrors | null {
    return control.value === this.captcha ? null : {invalidCaptcha: true};
  }

  onShowContactTopics() {
    this.isContactTopicsVisible = true;
  }

  onShowContactForm(selectedTopic: ContactTopic) {
    this.contactTopics.forEach(topic => topic.isVisible = !topic.isVisible);
    selectedTopic.isVisible = !selectedTopic.isVisible;
    this.isTopicSyncVisible = !this.isTopicSyncVisible;
    this.isContactFormVisible = true;
    this.contactTopic = selectedTopic.label;
    this.contactFormGroup?.controls.contactTopic.setValue(this.contactTopic);
  }

  save(): void {
    if (this.contactFormGroup?.invalid) {
      this.contactFormGroup.markAllAsTouched();
      return;
    }

    const formValues = this.contactFormGroup.value;

    // Formular erfolgreich gesendet
    this.snackBar.open('Vielen Dank für Ihre Nachricht', 'OK', {
      panelClass: 'snack-success'
    });

    // Formular zurücksetzen
    this.contactFormGroup.reset();
    this.fileName = '';
    this.isContactFormVisible = false;
    this.isContactTopicsVisible = false;
    this.contactTopic = null;
    this.contactSelectionService.resetSelection();
    this.refreshCaptcha();
  }
}
