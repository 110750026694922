<div class="container mx-auto px-4 py-8">
  <app-breadcrumb></app-breadcrumb>

  <mat-card-title>
    <h1 class="mat-headline">
      Anfrage
    </h1>
  </mat-card-title>
  <div class="flex flex-wrap">
    <p class="font-bold">Wird Ihre Frage hier schon beantwortet?</p>
    <div class="w-full">
      <app-favourites [favourites]="favourites"></app-favourites>
    </div>
  </div>

  <div class="w-full opacity-30 uppercase my-8 text-center">OR</div>

  <div class="flex flex-wrap">
    <p class="w-full font-bold">Haben Sie ein anderes Anliegen?</p>
    <div class="w-full md:w-1/3 md:py-4 md:pr-4">
      <div (click)="onShowContactTopics()" class="p-4 rounded-2xl flex items-center justify-between border border-primary-color cursor-pointer">
        <span class="flex"><mat-icon color="primary" class="mr-2 material-symbols-outlined" matPrefix>input </mat-icon>
          <h1 class="mat-subheading-2 !my-0"> Anderes Anliegen</h1>
        </span>
      </div>
    </div>

    <ng-container *ngIf="contactTopics.length > 0 && isContactTopicsVisible">
      <div class="w-full my-4">
        <p class="font-bold">Worum geht es genau?</p>
        <div class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
          <ng-container *ngFor="let contactTopic of contactTopics">
            <ng-container *ngIf="contactTopic.isVisible">
              <div (click)="onShowContactForm(contactTopic)" class="p-4 rounded-2xl flex items-center justify-between border border-primary-color cursor-pointer">
                <span class="flex">
                  <mat-icon color="primary" class="mr-2 material-symbols-outlined" matPrefix> {{ contactTopic.icon }} </mat-icon>
                  <h1 class="mat-subheading-2 !my-0"> {{ contactTopic.label }}</h1>
                </span>
                <ng-container *ngIf="isTopicSyncVisible">
                  <mat-icon color="primary" class="material-symbols-outlined" matPrefix>sync</mat-icon>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isContactFormVisible">
      <div class="w-full my-4">
        <p class="font-bold">Ihre Daten</p>

        <div class="w-full md:w-2/3">
          <form *ngIf="contactFormGroup" #contactForm="ngForm" [formGroup]="contactFormGroup" (ngSubmit)="save()">
            <div class="w-full grid grid-cols-8 gap-4">

              <div class="col-span-2">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Anrede</mat-label>
                  <mat-select formControlName="salutation">
                    <mat-option *ngFor="let salutation of salutations"
                                [value]="salutation.key">{{ salutation.label }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="contactFormGroup.controls.salutation.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-3">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Vorname</mat-label>
                  <input formControlName="firstName" type="text" matInput>
                  <mat-error *ngIf="contactFormGroup.controls.firstName.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.firstName.errors?.maxlength">
                    Maximal {{ maxLengthFirstName }} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-3">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Nachname</mat-label>
                  <input formControlName="lastName"
                         type="text"
                         matInput>
                  <mat-error *ngIf="contactFormGroup.controls.lastName.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.lastName.errors?.maxlength">
                    Maximal {{ maxLengthLastName }} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-4">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>E-Mail-Adresse</mat-label>
                  <input formControlName="emailAddress" type="email" matInput>
                  <mat-error *ngIf="contactFormGroup.controls.emailAddress.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.emailAddress.errors?.maxlength">
                    Maximal {{ maxLengthEmailAddress }} Zeichen
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.emailAddress.errors?.email">
                    Die eingegebene E-Mail-Adresse ist nicht korrekt
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-4">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Telefon</mat-label>
                  <input formControlName="phoneNumber"
                         type="text"
                         matInput>
                  <mat-error *ngIf="contactFormGroup.controls.phoneNumber.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.phoneNumber.errors?.maxlength">
                    Maximal {{ maxLengthPhoneNumber }} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-8">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Vertragsnummer</mat-label>
                  <input formControlName="customerId" type="text" matInput>
                  <mat-error *ngIf="contactFormGroup.controls.customerId.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.customerId.errors?.maxlength">
                    Maximal {{ maximumLengthCustomerId }} Zeichen
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-8">
                <mat-form-field appearance="fill" class="w-full">
                  <mat-label>Ihre Nachricht</mat-label>
                  <textarea
                    matInput
                    formControlName="contactMessage"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="4">
                  </textarea>
                  <mat-hint class="block ml-3">[{{ contactFormGroup.controls.contactMessage.value?.length ?? 0 }}
                    / {{ maximumMessageLength }}]
                  </mat-hint>
                  <mat-error *ngIf="contactFormGroup.controls.contactMessage.errors?.required">
                    Dieses Feld ist erforderlich.
                  </mat-error>
                  <mat-error *ngIf="contactFormGroup.controls.contactMessage.errors?.maxlength">
                    [{{ contactFormGroup.controls.contactMessage.value?.length ?? 0 }} / {{ maximumMessageLength }}]
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-span-8">
                <div class="w-full flex flex-wrap justify-center p-5 border border-gray-500 border-dotted relative">
                 <span class="w-full text-center text-sm opacity-60">
                  Anhang (max. {{ maximumAllowedMb }} MB) <br> JPG, PNG oder PDF
                 </span>
                  <ng-container *ngIf="filePreview">
                    <button mat-button (click)="deleteFile()" class="!absolute right-0 top-3">
                      <mat-icon class="material-symbols-outlined opacity-30 mx-3">delete</mat-icon>
                    </button>
                  </ng-container>
                  <button type="button" customer-portal-button
                          mat-raised-button
                          class="w-auto px-10 load-more-button !rounded-md !my-4"
                          (click)="fileInput.click()">Datei öffnen
                  </button>
                  <input type="file" #fileInput
                         (change)="onFileSelected($event)" hidden
                         [accept]="acceptedFileExtensions.join(',')"/>

                  <ng-container *ngIf="fileName">
                    <span class="w-full text-center">{{ fileName }}</span>
                  </ng-container>

                  <mat-error class="w-full text-center text-[12px]" *ngIf="contactFormGroup.controls.attachment?.errors?.invalidFile">
                    Es werden nur Dateien bis zu einer Größe von {{ maximumAllowedMb }} MB unterstützt
                  </mat-error>
                  <mat-error class="w-full text-center text-[12px]" *ngIf="contactFormGroup.controls.attachment?.errors?.invalidFileType">
                    Es werden nur PDF, JPG und PNG Dateien unterstützt.
                  </mat-error>
                </div>
              </div>

              <div class="col-span-8">
                <mat-checkbox formControlName="privacyConsent" class="items-start" color="primary">
                  <span class="pt-5 block whitespace-normal">
                    Es gelten die AGB für Stromlieferungen an Haushaltskunden und -Kundinnen.
                    Bitte beachte auch unsere Widerrufsbelehrung und Datenschutzbestimmungen. *</span>
                </mat-checkbox>
                <mat-error class="text-[12px]"
                           *ngIf="contactForm.submitted && contactFormGroup.controls.privacyConsent.errors?.required">
                  Dieses Feld ist erforderlich.
                </mat-error>
              </div>
            </div>

            <div class="col-span-8 my-8">
              <div class="flex flex-wrap justify-between items-center">
                <p class="my-10 mat-h2 !mb-0">Sicherheitsüberprüfung</p>
                <div class="flex flex-wrap justify-between items-center">
                  <button type="button" (click)="refreshCaptcha()" class="mx-3">
                    <mat-icon class="material-symbols-outlined opacity-30">sync</mat-icon>
                  </button>
                  <button type="button" matTooltip="Zum Schutz vor Bots verwenden wir Captchas. Sie stellen sicher, dass nur echte Nutzer Aktionen durchführen können – so verhindern wir den Versand von Fake-E-Mails."
                          matTooltipClass="tooltip-primary"
                          matTooltipPosition="above"
                          matTooltipHideDelay="100000">
                    <mat-icon class="material-symbols-outlined opacity-30">info</mat-icon>
                  </button>
                </div>
              </div>
              <div class="flex flex-wrap justify-center items-center my-4">
                <span class="primaryColor captcha text-center w-full">{{ captcha }}</span>
              </div>
              <mat-form-field appearance="fill" class="w-full">
                <mat-label>Zeichenfolge</mat-label>
                <input formControlName="captchaInput" type="text" matInput>
                <mat-error *ngIf="contactFormGroup.controls.captchaInput.errors?.required">
                  Dieses Feld ist erforderlich.
                </mat-error>
                <mat-error *ngIf="contactFormGroup.controls.captchaInput.value !== '' &&
                contactFormGroup.controls.captchaInput.invalid &&
                contactFormGroup.controls.captchaInput.touched">
                  CAPTCHA ist falsch.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="flex justify-center my-8">
              <div class="w-auto">
                <app-button caption="Absenden" color="primary" type="submit" [progressBarVisible]="false"></app-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
  </div>
</div>
