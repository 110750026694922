import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { HistoryComponent } from './components/history.component';
import { HistoryEntryComponent } from './components/history-entry.component';
import { MatButtonModule } from '@angular/material/button';
import { HistoryEntryTitleDirective } from './directives/history-entry-title.directive';
import { HistoryEntryTextDirective } from './directives/history-entry-text.directive';

@NgModule({
  declarations: [
    HistoryComponent,
    HistoryEntryComponent,
    HistoryEntryTitleDirective,
    HistoryEntryTextDirective
  ],
  exports: [
    HistoryComponent,
    HistoryEntryComponent,
    HistoryEntryTitleDirective,
    HistoryEntryTextDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class HistoryModule { }
