import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariffRoutingModule } from "./tariff-routing.module";
import { TariffComponent } from './components/tariff.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TimeModule } from "../time/time.module";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { DailyPriceSpiralComponent } from './components/daily-price-spiral.component';

@NgModule({
  declarations: [
    DailyPriceSpiralComponent,
    TariffComponent,
  ],
  exports: [DailyPriceSpiralComponent], 
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TariffRoutingModule,
    MatCardModule,
    MatIconModule,
    MatSlideToggleModule,
    TimeModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class TariffModule { }
