import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AntiAuthGuardService } from '../shared/services/anti-auth-guard.service';
import { MagicKeyCreateComponent } from './components/magic-key-create.component';
import { MagicKeyLoginComponent } from './components/magic-key-login.component';

const routes: Routes = [
  {
    title: 'Login | Kundenportal | ' + environment.brandName,
    path: environment.loginUrl,
    children: [
      {
        title: 'Login | Kundenportal | ' + environment.brandName,
        path: '',
        redirectTo: environment.magicKeyUrl,
        pathMatch: 'full'
      },
      {
        /* Link anfordern" wird hier extra nicht als Titel genommen , weil das die einzige Seite ist, auf die die Suchmachinenindexierung Zugriff hat. */
        title: 'Kundenportal | ' + environment.brandName,
        path: environment.magicKeyUrl,
        children: [
          {
            title: 'Login | Kundenportal | ' + environment.brandName,
            path: '',
            component: MagicKeyLoginComponent,
            canActivate: [AntiAuthGuardService],
          },
          {
            title: 'Kundenportal | ' + environment.brandName,
            path: environment.magicKeyCreateUrl,
            component: MagicKeyCreateComponent,
            canActivate: [AntiAuthGuardService]
          }]
      },
      {
        path: '**',
        redirectTo: '',
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class LoginRoutingModule { }
