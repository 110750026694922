import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {Subject, takeUntil} from 'rxjs';
import packageInfo from '../../package.json';
import {TariffType} from './modules/price/enumerations/tariff-type';
import {PriceAutoLoadService} from './modules/price/services/price-auto-load.service';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  appVersion: string | null = null;
  showTariff: boolean = false;

  constructor(
    public priceAutoLoadService: PriceAutoLoadService,
      private meta: Meta,
      private router: Router,
      private el: ElementRef,
      private renderer: Renderer2
    ) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd), takeUntil(this.destroyed$)
    )
      .subscribe(() => {
        const contentWrapper = this.el.nativeElement.querySelector('.contentWrapper');
        if (contentWrapper) {
          this.renderer.setProperty(contentWrapper, 'scrollTop', 0);
        }
      });
    this.appVersion = packageInfo.version;
    this.meta.addTag({name: 'app-version', content: `${this.appVersion}`})
    this.priceAutoLoadService
      .currentPrice$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(currentPrice => {
        if (!currentPrice) {
          this.showTariff = false;
          return;
        }
        this.showTariff = currentPrice.tariffType == TariffType.Index || currentPrice?.tariffType == TariffType.IndexHybrid;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}

