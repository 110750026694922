import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { RoundUpDecimalPipe } from '../../shared/pipes/round-up-decimal.pipe';
import { AuthService } from '../../shared/services/auth.service';
import { GroupUnit } from '../enumerations/group-unit';
import { DailyPriceSnapshot } from '../interfaces/daily-price-snapshot';
import { PriceDetail } from '../interfaces/price-detail';
import { PriceCompositionGroup } from '../interfaces/price-composition-group';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  constructor(private roundUpDecimalPipe: RoundUpDecimalPipe, private authService: AuthService, private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getCurrentPrice(contractId: string): Observable<PriceDetail> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<PriceDetail>(`${this.baseUrl}api/prices/current/${contractId}`, { headers: headers });
  }

  getDailyPriceSnapshots(contractId: string): Observable<DailyPriceSnapshot[]> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<DailyPriceSnapshot[]>(`${this.baseUrl}api/prices/${contractId}/daily`, { headers: headers });
  }

  getDailyPriceSnapshotForToday(contractId: string): Observable<DailyPriceSnapshot> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<DailyPriceSnapshot>(`${this.baseUrl}api/prices/${contractId}/daily/today`, { headers: headers });
  }

  getPriceCompositionGroup(contractId: string): Observable<PriceCompositionGroup> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.httpClient.get<PriceCompositionGroup>(`${this.baseUrl}api/prices/${contractId}/compositions`, { headers: headers });
  }

  checkIfInFixedPricePeriod(priceDetail: PriceDetail): boolean {
    if (!priceDetail.tariffFixedPriceStartDate || !priceDetail.tariffFixedPriceEndDate) {
      return false;
    }
    const startDate = new Date(priceDetail.tariffFixedPriceStartDate);
    const endDate = new Date(priceDetail.tariffFixedPriceEndDate);
    const actualDate = new Date();
    return actualDate <= endDate && actualDate >= startDate;
  }

  aggregateDailyPriceSnapshots(dailyPriceSnapshots: DailyPriceSnapshot[], includedDays: number, groupUnit: GroupUnit): DailyPriceSnapshot[] {
    const entriesConsidered = dailyPriceSnapshots.slice(Math.max(dailyPriceSnapshots.length - includedDays, 0));
    const aggregated: { [key: string]: { snapshot: DailyPriceSnapshot, count: number } } = {};
    let key: string;
    for (const entry of entriesConsidered) {
      switch (groupUnit) {
        case GroupUnit.Day:
          key = entry.day;
          break;
        case GroupUnit.Week:
          key = moment(entry.day).startOf('week').toISOString();
          break;
        case GroupUnit.Month:
          key = moment(entry.day).startOf('month').toISOString();
          break;
        case GroupUnit.All:
          key = "All";
          break;
      }
      if (aggregated[key]) {
        aggregated[key].snapshot.purchasePriceInEuroGross += entry.purchasePriceInEuroGross;
        aggregated[key].snapshot.totalPriceInEuroGross += entry.totalPriceInEuroGross;
        aggregated[key].count += 1;
      } else {
        aggregated[key] = {
          snapshot: {
            day: key === "All" ? moment().toISOString() : key,
            purchasePriceInEuroGross: entry.purchasePriceInEuroGross,
            totalPriceInEuroGross: entry.totalPriceInEuroGross
          },
          count: 1
        };
      }
    }
    const result: DailyPriceSnapshot[] = [];
    for (const key in aggregated) {
      result.push({
        day: aggregated[key].snapshot.day,
        purchasePriceInEuroGross: aggregated[key].snapshot.purchasePriceInEuroGross / aggregated[key].count,
        totalPriceInEuroGross: aggregated[key].snapshot.totalPriceInEuroGross / aggregated[key].count
      });
    }
    return this.roundDailyPriceSnapshots(result);
  }

  private roundDailyPriceSnapshots(dailyPriceSnapshots: DailyPriceSnapshot[]): DailyPriceSnapshot[] {
    return dailyPriceSnapshots.map(dailyPriceSnapshot => {
      dailyPriceSnapshot.purchasePriceInEuroGross = this.roundUpDecimalPipe.transform(dailyPriceSnapshot.purchasePriceInEuroGross, 1);
      dailyPriceSnapshot.totalPriceInEuroGross = this.roundUpDecimalPipe.transform(dailyPriceSnapshot.totalPriceInEuroGross, 1);
      return dailyPriceSnapshot;
    });
  }

}
