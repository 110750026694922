
export enum MagicKeySendingError{

  /// <summary>
  /// Unknown contract.
  /// </summary>
  UnknownContract = 'UnknownContract',
  /// <summary>
  /// No valid email address.
  /// </summary>
  NoValidEmailAddress = 'NoValidEmailAddress'
}
