import { Pipe, PipeTransform } from '@angular/core';
import { TimeUnit } from '../enumerations/time-unit';
import { Time } from '../interfaces/time';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {

  private units = {
    [TimeUnit.Day]: { singular: 'Tag', plural: 'Tage', dativePlural: 'Tagen' },
    [TimeUnit.Week]: { singular: 'Woche', plural: 'Wochen', dativePlural: 'Wochen' },
    [TimeUnit.Month]: { singular: 'Monat', plural: 'Monate', dativePlural: 'Monaten' },
    [TimeUnit.Year]: { singular: 'Jahr', plural: 'Jahre', dativePlural: 'Jahren' },
  };

  transform(time: Time, useDativePlural: boolean): string {
    let text = `${time.value}`;
    text += ' ';
    const unit = this.units[time.unit];
    if (time.value === 1) {
      text += unit.singular;
    } else {
      if (useDativePlural) {
        text += unit.dativePlural;
      } else {
        text += unit.plural;
      }
    }
    return text;
  }

}
