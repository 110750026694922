import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: 'input[matInputBlurValue]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputBlurValueDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputBlurValueDirective),
      multi: true,
    }
  ]
})
export class MatInputBlurValueDirective {

  isFocused: boolean = false;

  @Input() blurValue: string = '';

  private _value: string | null = null

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    if (this._value !== value) {
      this._value = value;
      this.refreshVisibleValue();
    }
  }

  constructor(private elementRef: ElementRef<HTMLInputElement>) {
  }

  onModelChange($event: string) {
    if (this.isFocused) {
      this.value = $event;
    }
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
    this.isFocused = false;
    this.refreshVisibleValue();
  }

  @HostListener('focus')
  onFocus() {
    this.isFocused = true;
    this.refreshVisibleValue();
  }

  writeValue(value: string): void {
    this._value = value;
    this.refreshVisibleValue();
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this._value = value;
    this.onChange(this._value);
  }


  private refreshVisibleValue() {
    if (this.isFocused) {
      this.elementRef.nativeElement.value = this.value ?? '';
    }
    else {
      this.elementRef.nativeElement.value = this.blurValue;
    }
  }

  private onTouched!: () => void;
  private onChange!: (value: string) => void;

}
