import { NgModule } from '@angular/core';
import { ContractComponent } from './components/contract.component';
import { RouterModule } from "@angular/router";
import { ContractRoutingModule } from "./contract-routing.module";
import { ContractOverviewComponent } from "./components/contract-overview.component";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PriceModule } from '../price/price.module';
import { TimeModule } from '../time/time.module';
import { MatStepperModule } from "@angular/material/stepper";
import { SharedModule } from "../shared/shared.module";
import { InformationDialogModule } from '../information-dialog/information-dialog.module';
import { HistoryModule } from '../history/history.module';
import { OrderCorrectionModule } from '../order-correction/order-correction.module';

@NgModule({
  declarations: [
    ContractComponent,
    ContractOverviewComponent,
  ],
  imports: [
    CommonModule,
    ContractRoutingModule,
    RouterModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressSpinnerModule,
    PriceModule,
    TimeModule,
    MatStepperModule,
    SharedModule,
    InformationDialogModule,
    HistoryModule,
    OrderCorrectionModule
  ]
})
export class ContractModule { }
