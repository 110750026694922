<div class="container mx-auto px-4 py-8">
  <h2 class="mat-subheading-2">SEPA-Mandat hinzufügen</h2>
  <fieldset [disabled]="isUploadProcessOngoing">
    <div class="w-full md:w-8/12 flex flex-col md:flex-row items-center">
      <app-file-select [(file)]="selectedFile" [maximumFileLengthInBytes]="environment.maximumFileLengthInBytes" #sepaUpload [acceptedFileExtensions]="environment.acceptedFileUploadExtensions.join(',')" class="w-full"></app-file-select>
    </div>
    <div class="flex flex-col md:flex-row md:flex-wrap justify-between items-center !mt-10">
      <div class="w-full md:w-auto">
        <app-button [disabled]="!this.selectedFile || !selectedContract" caption="Hochladen" color="primary" type="button" (click)="upload()" [progressBarVisible]="isUploadProcessOngoing"></app-button>
      </div>
    </div>
  </fieldset>
</div>
