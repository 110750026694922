<div class="container mx-auto px-4 pb-8">
  <h2 class="mat-headline !my-0">
    Ihre Zahlungseingänge
  </h2>
  <div class="flex flex-col">
    <ng-container *ngIf="receiptsOfPayment.isInValueState || receiptsOfPayment.isInNoValueState">
      <app-receipt-of-payment-table *ngIf="receiptsOfPayment.value?.length" [receiptsOfPayment]="receiptsOfPayment.value"></app-receipt-of-payment-table>
      <app-notification *ngIf="!receiptsOfPayment.value?.length" [notificationType]="NotificationType.Information" notification="Bisher sind keine Zahlungen hinterlegt."></app-notification>
    </ng-container>
    <ng-container *ngIf="receiptsOfPayment.isInErrorState">
      <app-notification [notificationType]="NotificationType.Error" notification="Ihre Zahlungen können zurzeit nicht angezeigt werden – Bitte versuchen Sie es später erneut."></app-notification>
    </ng-container>
    <ng-container *ngIf="receiptsOfPayment.isInLoadingState">
      <div class="flex flex-col items-center">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
  </div>
</div>
