<ng-container *ngIf="documents && contract">
  <table class="w-full" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="documentCategory">
      <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Dokument</th>
      <td mat-cell *matCellDef="let document">
        <span *ngIf="document.category === DocumentCategory.ContractConfirmation">Herzlich Willkommen!</span>
        <span *ngIf="document.category === DocumentCategory.SupplyConfirmation">Ihr Auftrag ist bei uns eingegangen.</span>
        <span *ngIf="document.category === DocumentCategory.PriceAdjustment">Preisinformation</span>
        <span *ngIf="document.category === DocumentCategory.SepaMandate">Vordruck SEPA-Mandat</span>
        <span *ngIf="document.category === DocumentCategory.TariffChange">Ihre Tarifwechselbestätigung</span>
        <span *ngIf="document.category === DocumentCategory.Invoice">Verbrauchsabrechnung</span>
        <span *ngIf="document.category === DocumentCategory.CorrectedInvoice">Verbrauchsabrechnung</span>
        <span *ngIf="document.category === DocumentCategory.FinalInvoice">Schlussrechnung</span>
        <span *ngIf="document.category === DocumentCategory.CorrectedFinalInvoice">Schlussrechnung</span>
        <span *ngIf="document.category === DocumentCategory.PriceCapInformation">Preisbremseninformation</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="documentCreatedAt">
      <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Datum</th>
      <td mat-cell *matCellDef="let document">{{document.createdAt|date:'mediumDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="download">
      <th mat-header-cell *matHeaderCellDef class="tableHeadRow">Download</th>
      <td mat-cell *matCellDef="let document">
        <a (click)="onDownloadClick(document.id)" class="flex items-center">
          <span class="mr-1 text-[#4a4a4a] hidden sm:inline-block">Download</span>
          <span class="material-symbols-outlined">download</span>
        </a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons class="w-100">
  </mat-paginator>
</ng-container>
