import {Component, HostBinding, Input, ViewChild} from '@angular/core';
import { MeterReadingViewComponent } from './meter-reading-view.component';

@Component({
  selector: 'app-meter-reading',
  templateUrl: './meter-reading.component.html'
})
export class MeterReadingComponent {

  @ViewChild('meterReadingView') meterReadingView: MeterReadingViewComponent | null = null;
  @HostBinding('class') flexClass = 'flex-1';

  refresh(): void {
    this.meterReadingView?.refresh();
  }

}
