import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NotificationType } from '../enumerations/notification-type';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-card-notification',
  templateUrl: './card-notification.component.html',
  styleUrls: ['./card-notification.component.scss']
})
export class CardNotificationComponent {

  @Input() class: string | null = null;
  @Input() notification: string | null = null;
  @Input() notificationType: NotificationType = NotificationType.Information;
  NotificationType = NotificationType;

}

