import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-dashboard-account-statement-card',
  templateUrl: './dashboard-account-statement-card.component.html'
})
export class DashboardAccountStatementCardComponent {

  environment = environment;

}
