import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from "rxjs/operators";
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { ObservableDisplayState } from '../../observable-status/enumerations/observable-display-state';
import { determineGroupedStatus, withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { EnergyType } from '../../shared/enumerations/energy-type';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { Contract } from '../../shared/interfaces/contract';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { GaugeType } from '../enumerations/gauge-type';
import { Meter } from '../interfaces/meter';
import { MeterReading } from '../interfaces/meter-reading';
import { MeterReadingService } from '../services/meter-reading.service';


@Component({
  selector: 'app-meter-reading-view',
  templateUrl: './meter-reading-view.component.html'
})
export class MeterReadingViewComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  private refresh$ = new Subject<void>();

  EnergyType = EnergyType;
  NotificationType = NotificationType;
  ObservableDisplayState = ObservableDisplayState;

  selectedContract: Contract | null = null;
  htMeterReadings: MeterReading[] | null = null;
  ntMeterReadings: MeterReading[] | null = null;
  meter: Meter | null = null;

  meterReadingsLoadingState: ObservableDisplayState = ObservableDisplayState.None;
  meterLoadingState: ObservableDisplayState = ObservableDisplayState.None;
  groupedLoadingState: ObservableDisplayState = ObservableDisplayState.None;

  constructor(
    private customerSelectionService: CustomerSelectionService,
    private meterReadingService: MeterReadingService
  ) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of([
              ObservableStatus.noValue<MeterReading[]>(),
              ObservableStatus.noValue<Meter>()
            ] as [ObservableStatus<MeterReading[]>, ObservableStatus<Meter>])
          }
          return combineLatest([
            withNormalLoadingStatus(
              this.meterReadingService.getMeterReadings(this.selectedContract.contractId)),
            withNormalLoadingStatus(
              this.meterReadingService.getActiveMeter(this.selectedContract.contractId))
          ]);
        }),
        takeUntil(this.destroyed$)
      ).subscribe(([meterReadingStatus, meterStatus]) => {
        this.meterReadingsLoadingState = meterReadingStatus.displayState;
        this.meterLoadingState = meterStatus.displayState;
        this.groupedLoadingState = determineGroupedStatus([this.meterReadingsLoadingState, this.meterLoadingState]);
        this.htMeterReadings = null;
        this.ntMeterReadings = null;
        let meterReadings = meterReadingStatus.value;
        if (meterReadings) {
          meterReadings.sort((first, second) => Date.parse(second.readingDate) - Date.parse(first.readingDate));
          this.htMeterReadings = meterReadings?.filter(meterReading => meterReading.gaugeType === GaugeType.Ht);
          this.ntMeterReadings = meterReadings?.filter(meterReading => meterReading.gaugeType === GaugeType.Nt);
        }
        this.meter = meterStatus.value;
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

}
