import { Component, Input } from '@angular/core';
import { DailyPriceSnapshot } from '../../price/interfaces/daily-price-snapshot';
import {PriceService} from "../../price/services/price.service";
import {PriceDetail} from "../../price/interfaces/price-detail";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-daily-price-spiral',
  templateUrl: './daily-price-spiral.component.html'
})
export class DailyPriceSpiralComponent {

  @Input() dailyPriceSnapshot: DailyPriceSnapshot | null = null;
  @Input() showTotal: boolean = false;
  @Input() displayInCard: boolean = false;
  @Input() currentPrice: PriceDetail | null = null;
  environment = environment;

  constructor(public priceService: PriceService) {
  }

}
