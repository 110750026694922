import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { DashboardComponent } from './components/dashboard.component';


const routes: Routes = [
  {
    title: 'Dashboard | Kundenportal | ' + environment.brandName,
    path: environment.dashboardUrl,
    component: DashboardComponent,
    canActivate: [AuthGuardService]
  }];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
