import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { CanColor, ThemePalette } from "@angular/material/core";
import { HistoryEntryState } from "../enumerations/history-entry-state";

@Component({
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-history-entry',
  templateUrl: './history-entry.component.html',
  styleUrls: ['./history-entry.component.scss'],
})
export class HistoryEntryComponent implements CanColor {

  @Input() color: ThemePalette;
  defaultColor: ThemePalette = 'primary';

  private _isExpanded: boolean = false;
  @Output() isExpandedChange = new EventEmitter<boolean>();
  get isExpanded(): boolean {
    return this._isExpanded;
  }
  @Input() set isExpanded(value: boolean) {
    if (this._isExpanded !== value) {
      this._isExpanded = value;
      this.isExpandedChange.emit(this._isExpanded);
    }
  }

  private _isExpandable: boolean = true;
  @Output() isExpandableChange = new EventEmitter<boolean>();
  get isExpandable(): boolean {
    return this._isExpandable;
  }
  @Input() set isExpandable(value: boolean) {
    if (this._isExpandable !== value) {
      this._isExpandable = value;
      this.isExpandableChange.emit(this._isExpandable);
    }
  }

  private _title: string | null = null;
  @Output() titleChange = new EventEmitter<string| null>();
  get title(): string | null {
    return this._title;
  }
  @Input() set title(value: string | null) {
    if (this._title !== value) {
      this._title = value;
      this.titleChange.emit(this._title);
    }
  }

  private _text: string | null = null;
  @Output() textChange = new EventEmitter<string| null>();
  get text(): string | null {
    return this._text;
  }
  @Input() set text(value: string | null) {
    if (this._text !== value) {
      this._text = value;
      this.textChange.emit(this._text);
    }
  }

  private _showLine: boolean = true;
  @Output() showLineChange = new EventEmitter<boolean>();
  get showLine(): boolean {
    return this._showLine;
  }
  @Input() set showLine(value: boolean) {
    if (this._showLine !== value) {
      this._showLine = value;
      this.showLineChange.emit(this._showLine);
    }
  }

  private _state: HistoryEntryState = HistoryEntryState.Started;
  @Output() stateChange = new EventEmitter < HistoryEntryState>();
  get state(): HistoryEntryState {
    return this._state;
  }
  @Input() set state(value: HistoryEntryState) {
    if (this._state !== value) {
      this._state = value;
      this.stateChange.emit(this._state);
    }
  }

  HistoryEntryState = HistoryEntryState;

  private get colorClass() {
    return this.color ?
      `mat-${this.color}` :
      `mat-${this.defaultColor}`;
  }

  private get colorAndStateClass() {
    return `${this.colorClass}-${this.state.toLowerCase()}`;
  }

  protected get iconClass() {
    return `${this.colorAndStateClass}-icon`;
  }

  protected get expandButtonClass() {
    return `${this.colorAndStateClass}-expand-button`;
  }

  protected get lineClass() {
    return `${this.colorAndStateClass}-line`;
  }

  protected get titleClass() {
    return `${this.colorAndStateClass}-title`;
  }

  protected toggle(): void {
    this.isExpanded = !this.isExpanded;
  }

}
