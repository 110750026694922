import { Component } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard-contact-card',
  templateUrl: './dashboard-contact-card.component.html'
})
export class DashboardContactCardComponent {

  environment = environment;

  constructor(private router: Router) {
  }

  navigate(): void {
    if (environment.isExternalContactFormUrl) {
      window.open(environment.contactFormUrl, '_blank');
    }
    else {
      this.router.navigate(['/', environment.contactFormUrl])
    }
  }

}
