import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from "rxjs/operators";
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { ObservableDisplayState } from '../../observable-status/enumerations/observable-display-state';
import { withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { PaymentMethod } from '../../payment-information/enumerations/payment-method';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { Contract } from '../../shared/interfaces/contract';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { ReceiptOfPayment } from '../interfaces/receipt-of-payment';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-receipt-of-payment-view',
  templateUrl: './receipt-of-payment-view.component.html'
})
export class ReceiptOfPaymentViewComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  private refresh$ = new Subject<void>();

  PaymentMethod = PaymentMethod;
  ObservableDisplayState = ObservableDisplayState;
  NotificationType = NotificationType;

  selectedContract: Contract | null = null;
  receiptsOfPayment: ObservableStatus<ReceiptOfPayment[]> = ObservableStatus.none<ReceiptOfPayment[]>();

  constructor(
    private customerSelectionService: CustomerSelectionService,
    public paymentService: PaymentService
  ) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of(ObservableStatus.none<ReceiptOfPayment[]>())
          }
          return withNormalLoadingStatus(
            this.paymentService.getReceiptsOfPayment(this.selectedContract.contractId)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(receiptsOfPayment => {
        this.receiptsOfPayment = receiptsOfPayment;
        if (this.receiptsOfPayment.value) {
          this.receiptsOfPayment.value.sort((first, second) => Date.parse(second.receiptDate) - Date.parse(first.receiptDate));
        }
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh() {
    this.refresh$.next();
  }

}
