import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MeterReading } from '../interfaces/meter-reading';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Contract } from '../../shared/interfaces/contract';
import { EnergyType } from '../../shared/enumerations/energy-type';

@Component({
  selector: 'app-meter-reading-table',
  templateUrl: './meter-reading-table.component.html'
})
export class MeterReadingTableComponent implements AfterViewInit, OnChanges {

  dataSource: MatTableDataSource<MeterReading> = new MatTableDataSource<MeterReading>();
  @Input() meterReadings: MeterReading[] | null = null;
  @Input() contract: Contract | null = null;
  displayedColumns: string[] = ['readingValue', 'readingFrom', 'readingDate'];
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  EnergyType = EnergyType;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.meterReadings) {
      return;
    }
    if (!changes.meterReadings.currentValue) {
      this.dataSource.data = [];
      return;
    }
    this.dataSource.data = changes.meterReadings.currentValue;
  }

}
