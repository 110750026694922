<div class="container px-4 mx-auto mt-10">
  <div class="headlineWrapper flex flex-row justify-between">
    <h1 class="mat-headline">
      Übersicht
    </h1>
    <span class="sm:hidden">
       Vertragsnr. {{ authService.contract?.contractId }}
    </span>
  </div>
  <div class="dashboardContentWrapper flex flex-col lg:flex-row lg:justify-between">
    <div class="flex flex-row lg:w-3/12">
      <app-dashboard-tariff-card class="w-full"></app-dashboard-tariff-card>
    </div>
    <div class="lg:flex-1 lg:ml-8 my-12 lg:my-0">
      <div class="dashboardItemsWrapper grid grid-cols-2 xl:grid-cols-3 gap-8">
        <div class="dashboardItem flex flex-col" *ngIf="environment.isContractOverviewCardVisible">
          <app-dashboard-contract-overview-card class="flex-1 flex flex-col"></app-dashboard-contract-overview-card>
        </div>
        <div class="dashboardItem flex flex-col" *ngIf="environment.isMoveCardVisible">
          <app-dashboard-move-card class="flex-1 flex flex-col"></app-dashboard-move-card>
        </div>
        <div class="dashboardItem flex flex-col" *ngIf="environment.isMeterReadingCardVisible">
          <app-dashboard-meter-reading-card class="flex-1 flex flex-col"></app-dashboard-meter-reading-card>
        </div>
        <div class="dashboardItem flex flex-col" *ngIf="environment.isMonthlyPaymentCardVisible">
          <app-dashboard-monthly-payment-card class="flex-1 flex flex-col"></app-dashboard-monthly-payment-card>
        </div>
        <div class="dashboardItem flex flex-col" *ngIf="environment.isPaymentInformationCardVisible">
          <app-dashboard-payment-information-card class="flex-1 flex flex-col"></app-dashboard-payment-information-card>
        </div>
        <div class="dashboardItem flex flex-col" *ngIf="environment.isDocumentsCardVisible">
          <app-dashboard-documents-card class="flex-1 flex flex-col"></app-dashboard-documents-card>
        </div>
        <div class="dashboardItem flex flex-col" *ngIf="environment.isCreditSettlementCardVisible">
          <app-dashboard-credit-settlement-card class="flex-1 flex flex-col"></app-dashboard-credit-settlement-card>
        </div>
        <div *ngIf="environment.isAccountStatementCardVisible && !priceAutoLoadService.isLoading && (currentPrice?.tariffType === TariffType.Index || currentPrice?.tariffType === TariffType.IndexHybrid)" class="dashboardItem flex flex-col">
          <app-dashboard-account-statement-card class="flex-1 flex flex-col"></app-dashboard-account-statement-card>
        </div>
        <div class="dashboardItem flex flex-col" *ngIf="environment.isContactCardVisible">
          <app-dashboard-contact-card class="flex-1 flex flex-col"></app-dashboard-contact-card>
        </div>
      </div>
    </div>
  </div>
</div>
