<ng-container *ngIf="groupedLoadingState === ObservableDisplayState.Loading
          || groupedLoadingState === ObservableDisplayState.Error
          || meterLoadingState === ObservableDisplayState.NoValue;
              then loadingOrError; else loaded">
</ng-container>
<ng-template #loadingOrError>
  <ng-container *ngIf="groupedLoadingState === ObservableDisplayState.Loading">
    <div class="flex flex-col items-center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="groupedLoadingState === ObservableDisplayState.Error">
    <app-notification [notificationType]="NotificationType.Error"
                      notification="Ihre Zählerstände können zurzeit nicht angezeigt werden – Bitte versuchen Sie es später erneut.">
    </app-notification>
  </ng-container>
  <ng-container *ngIf="meterLoadingState === ObservableDisplayState.NoValue">
    <app-notification [notificationType]="NotificationType.Warning"
                      notification="Es wurde kein aktiver Zähler gefunden.">
    </app-notification>
  </ng-container>
</ng-template>
<ng-template #loaded>
  <div class="flex flex-row flex-wrap">
    <div class="w-full">
      <mat-card-content>
        <ng-container *ngIf="meter && meter.hasNtGauge">
          <div class="mt-4">
            <h2 class="mat-title !my-0">Hochtarif (HT)</h2>
            <app-meter-reading-table *ngIf="htMeterReadings?.length" [contract]="selectedContract"
                                     [meterReadings]="htMeterReadings"></app-meter-reading-table>
            <div *ngIf="!htMeterReadings?.length">
              <app-notification [notificationType]="NotificationType.Information" notification="Bisher sind keine Zählerstände hinterlegt."></app-notification>
            </div>
          </div>
          <div class="mt-5">
            <h2 class="mat-title !my-0">Niedertarif (NT)</h2>
            <app-meter-reading-table *ngIf="ntMeterReadings?.length" [contract]="selectedContract"
                                     [meterReadings]="ntMeterReadings"></app-meter-reading-table>
            <div *ngIf="!ntMeterReadings?.length">
              <app-notification [notificationType]="NotificationType.Information" notification="Bisher sind keine Zählerstände hinterlegt."></app-notification>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="meter && !meter.hasNtGauge">
          <app-meter-reading-table *ngIf="htMeterReadings?.length" [contract]="selectedContract"
                                   [meterReadings]="htMeterReadings"></app-meter-reading-table>
          <div *ngIf="!htMeterReadings?.length">
            <app-notification [notificationType]="NotificationType.Information" notification="Bisher sind keine Zählerstände hinterlegt."></app-notification>
          </div>
        </ng-container>
      </mat-card-content>
    </div>
  </div>
</ng-template>
