import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnergyType } from '../../shared/enumerations/energy-type';
import { GaugeType } from '../enumerations/gauge-type';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { Contract } from '../../shared/interfaces/contract';
import { Meter } from '../interfaces/meter';
import { MeterReadingService } from '../services/meter-reading.service';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus, withSlowLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { CustomValidators } from '../../shared/validators/custom-validators';
import { MeterReadingCreation } from '../interfaces/meter-reading-creation';

@Component({
  selector: 'app-meter-reading-create',
  templateUrl: './meter-reading-create.component.html'
})
export class MeterReadingCreateComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();

  @Output() createdMeterReading = new EventEmitter();
  @ViewChild('meterReadingForm') meterReadingForm: NgForm | null = null;
  meterReadingFormGroup: FormGroup | null = null;

  EnergyType = EnergyType;
  GaugeType = GaugeType;
  NotificationType = NotificationType;

  selectedContract: Contract | null = null;
  meter: ObservableStatus<Meter> = ObservableStatus.none<Meter>();
  meterReadingSaveOperation: ObservableStatus<void> = ObservableStatus.none<void>();

  maximumReadingValue: number = 10000000;
  minimumReadingValue: number = 0;

  constructor(
    private meterReadingService: MeterReadingService,
    private customerSelectionService: CustomerSelectionService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.customerSelectionService
      .contractSelected$
      .pipe(
        switchMap(contract => {
          this.selectedContract = contract;
          if (!contract) {
            return of(ObservableStatus.noValue<Meter>());
          }
          return withNormalLoadingStatus(
            this.meterReadingService.getActiveMeter(contract.contractId)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(meter => {
        this.meter = meter;
        this.meterReadingFormGroup = this.buildFormGroup(this.meter.value);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private buildFormGroup(meter: Meter | null): FormGroup | null {
    if (!meter) {
      return null;
    }
    if (meter.hasNtGauge) {
      return this.formBuilder.group({
        readingValueHt: [null, [Validators.max(this.maximumReadingValue), Validators.min(this.minimumReadingValue)]],
        readingValueNt: [null, [Validators.max(this.maximumReadingValue), Validators.min(this.minimumReadingValue)]],
        readingDate: [null, [Validators.required, Validators.nullValidator]],
      }, { validators: CustomValidators.oneOfBoth('readingValueHt', 'readingValueNt') });
    }
    else {
      return this.formBuilder.group({
        readingValueHt: [null, [Validators.required, Validators.max(this.maximumReadingValue), Validators.min(this.minimumReadingValue)]],
        readingValueNt: [null, [Validators.max(this.maximumReadingValue), Validators.min(this.minimumReadingValue)]],
        readingDate: [null, [Validators.required, Validators.nullValidator]]
      });
    }
  }

  save(): void {
    if (this.meterReadingForm!.invalid || this.meterReadingSaveOperation.isInLoadingState) {
      return;
    }
    let selectedMeterReading = {
      meterNumber: this.meter.value!.meterNumber,
      readingDate: this.meterReadingFormGroup!.controls.readingDate.value,
      readingValueHt: this.meterReadingFormGroup!.controls.readingValueHt.value,
      readingValueNt: this.meterReadingFormGroup!.controls.readingValueNt.value,
    } as MeterReadingCreation;
    withSlowLoadingStatus(
      this.meterReadingService.createMeterReading(this.selectedContract!.contractId, selectedMeterReading)
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(meterReadingSaveOperation => {
        this.meterReadingSaveOperation = meterReadingSaveOperation;
        if (this.meterReadingSaveOperation.isInErrorState) {
          this.snackBar.open('Der Zählerstand konnte nicht gespeichert werden!', 'OK', {
            panelClass: 'snack-danger'
          });
        } else if (this.meterReadingSaveOperation.isInValueState || this.meterReadingSaveOperation.isInNoValueState) {
          this.snackBar.open('Zählerstand erfolgreich gespeichert.', 'OK', {
            panelClass: 'snack-success'
          });
          this.clear();
          this.createdMeterReading.emit();
        }
      });
  }

  clear(): void {
    this.meterReadingForm!.resetForm({ readingValueNt: null, readingValueHt: null, readingDate: null });
  }

}
