import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { ConsumptionPriceDialogComponent } from "./consumption-price-dialog.component";
import { AccountStatementService } from "../services/account-statement.service";
import { AccountStatement } from "../interfaces/account-statement";
import { of, Subject, takeUntil } from "rxjs";
import { NotificationType } from '../../shared/enumerations/notification-type';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { Contract } from "../../shared/interfaces/contract";
import { switchMap } from "rxjs/operators";
import { CustomerSelectionService } from "../../shared/services/customer-selection.service";
import { AccountStatementEntry } from "../interfaces/account-statement-entry";
import { TotalBalanceDialogComponent } from "./total-balance-dialog.component";
import { MatTabGroup } from '@angular/material/tabs';


@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html'
})
export class AccountStatementComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<void>();
  private refresh$ = new Subject<void>();

  @HostBinding('class') flexClass = 'flex-1';

  environment = environment;
  NotificationType = NotificationType;
  selectedContract: Contract | null = null;
  accountStatement: ObservableStatus<AccountStatement> = ObservableStatus.none<AccountStatement>();

  @ViewChild(MatTabGroup) set tabGroup(tabGroup: MatTabGroup) {
    if (tabGroup && this.accountStatement.value?.entries.length) {
      let index = this.accountStatement.value.entries.length - 1;
      tabGroup.selectedIndex = index;
    }
  }

  constructor(
    private customerSelectionService: CustomerSelectionService,
    public accountStatementService: AccountStatementService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of(ObservableStatus.none<AccountStatement>())
          }
          return (
            withNormalLoadingStatus(
              this.accountStatementService.getAccountStatement(this.selectedContract.contractId)
            )
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(accountStatement => {
        this.accountStatement = accountStatement;
        if (accountStatement.value?.entries.length) {
          accountStatement.value.entries.sort((entryA, entryB) => {
            return new Date(entryA.year,entryA.month).getTime() - new Date(entryB.year,entryB.month).getTime();
          });
        }
    });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

  openConsumptionPriceDialog(entry:AccountStatementEntry | null = null ): void {
    this.dialog.open(ConsumptionPriceDialogComponent, {
      data: entry
    });
  }

  openTotalBalanceDialog(): void {
    this.dialog.open(TotalBalanceDialogComponent);
  }

}



