import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[appRepeat]' })
export class RepeatDirective {

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
  }

  @Input() set appRepeat(number: number) {
    for (let index = 0; index < number; index++) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
