<div class="container mx-auto h-full flex items-center px-4 md:px-8 lg:px-24 justify-center">
  <div class="flex flex-col w-full">
    <div class="flex flex-col w-full md:flex-row md:flex-wrap md:gap-x-10 lg:gap-x-24 md:items-center">
      <ng-container *ngIf="!(magicKeySendResponse.isInValueState && magicKeySendResponse.value && magicKeySendResponse.value.isSuccessful)">
        <mat-card class="!p-8 md:!p-10 flex-1 mt-10 md:mt-0 mb-14 md:mb-0">
          <mat-card-title class="!my-3">
            <span>Schneller Login</span>
          </mat-card-title>
          <p>
            Geben Sie bitte Ihre Vertragsnummer ein. Den Link zum Login bekommen Sie dann per E-Mail.
          </p>
          <mat-card-content>
            <form *ngIf="magicKeyCreateFormGroup" #magicKeyCreateForm="ngForm" [formGroup]="magicKeyCreateFormGroup"
                  (ngSubmit)="onSubmit()">
              <fieldset [disabled]="magicKeySendResponse.isInLoadingState">
                <div class="flex flex-col">
                  <mat-form-field class="w-full mt-5" appearance="fill">
                    <mat-label>Vertragsnummer</mat-label>
                    <input type="text"
                           formControlName="contractId"
                           matInput>
                    <mat-error *ngIf="magicKeyCreateFormGroup.controls.contractId.errors?.required">
                      Pflichtfeld
                    </mat-error>
                    <mat-error *ngIf="magicKeyCreateFormGroup.controls.contractId.errors?.maxlength">
                      Maximal {{ customerOrContractIdMaximumLength }} Zeichen
                    </mat-error>
                  </mat-form-field>
                  <ng-container *ngIf="isErrorRedirection">
                    <cpc-notification class="mt-3" [notificationType]="NotificationType.Information"
                                      notification="Ihr verwendeter Link ist abgelaufen. Hier können Sie einen neuen Link zum ‚Schnellen Login‘ generieren.">
                    </cpc-notification>
                  </ng-container>
                  <ng-container *ngIf="magicKeySendResponse.isInErrorState">
                    <cpc-notification class="mt-3" [notificationType]="NotificationType.Error"
                                      notification="Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es später erneut.">
                    </cpc-notification>
                  </ng-container>
                  <ng-container *ngIf="magicKeySendResponse.isInValueState && magicKeySendResponse.value">
                    <ng-container *ngIf="magicKeySendResponse.value.errorDetails === MagicKeySendingError.UnknownContract">
                      <cpc-notification class="mt-3" [notificationType]="NotificationType.Error"
                                        notification="Die eingegebene Vertragsnummer konnte nicht gefunden werden. Bitte überprüfen Sie Ihre Eingabe.">
                      </cpc-notification>
                    </ng-container>
                    <ng-container *ngIf="magicKeySendResponse.value.errorDetails === MagicKeySendingError.NoValidEmailAddress">
                      <cpc-notification class="mt-3" [notificationType]="NotificationType.Error"
                                        notification="Für die Nutzung unseres Kundenportals muss eine funktionfähige E-Mail-Adresse hinterlegt werden. Bitte nutzen Sie unser Kontaktformular und übermitteln uns unter Angabe Ihrer Vertragsnummer und Lieferadresse eine funktionstüchtige E-Mail-Adresse. Möchten Sie, dass wir zukünftig alle Benachrichtigungen an diese E-Mail-Adresse senden, teilen Sie uns das bitte ebenfalls über das Kontaktformular mit, damit wir Sie auf unseren Online-Service umstellen können.">
                      </cpc-notification>
                    </ng-container>
                  </ng-container>
                  <div class="flex flex-col md:flex-row md:flex-wrap mt-10 items-center justify-between w-full">
                    <div class="w-full md:w-auto mb-3 md:mb-0">
                      <app-button caption="Absenden" color="primary" type="submit" [progressBarVisible]="magicKeySendResponse.isInLoadingState"></app-button>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <ng-container *ngIf="magicKeySendResponse.isInValueState && magicKeySendResponse.value && magicKeySendResponse.value.isSuccessful">
        <mat-card class="!p-8 lg:!p-10 flex-1 mt-10 md:mt-0 mb-14 lg:mb-0">
          <mat-icon class="icon-post"> mail_outline</mat-icon>
          <mat-card-title class="mb-4 mat-title">
            <span>E-Mail Postfach prüfen</span>
          </mat-card-title>
          <mat-card-content>
            <p>Wir haben Ihren Link per E-Mail an <span class="mat-body-2">{{ magicKeySendResponse.value.emailAddress }}</span> versendet.</p>
            <p>Bitte auch den Spam-Ordner prüfen.</p>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <div class="flex-1 flex flex-col mb-5 md:mb-0 px-8 md:px-0">
        <mat-card-title class="primaryColor !mb-4">So einfach gehts</mat-card-title>
        <ul class="flex flex-col mat-body-2">
          <li class="flex items-center my-1"><span>1</span>Vertragsnummer eingeben</li>
          <li class="flex items-center my-1"><span>2</span>Link per E-Mail erhalten</li>
          <li class="flex items-center my-1"><span>3</span>Link in E-Mail klicken</li>
        </ul>
      </div>
    </div>
    <ng-container *ngIf="environment.showDayaheadPriceOverviewInLogin">
      <app-dayahead-base-prices-overview></app-dayahead-base-prices-overview>
    </ng-container>
  </div>
</div>
