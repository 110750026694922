import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnergyType } from '../../shared/enumerations/energy-type';
import { AccountInformation } from '../interfaces/account-information';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getAccountInformation(energyType: EnergyType): Observable<AccountInformation> {
    return this.http.get<AccountInformation>(`${this.baseUrl}api/brand/account-information?energyType=${energyType}`);
  }

}
