import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepeatDirective } from './directives/repeat';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { NotificationComponent } from './components/notification.component';
import { SpinnerComponent } from './components/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomerPortalButtonDirective } from './directives/customer-portal-button.directive';
import { ButtonComponent } from './components/button.component';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatInputBlurValueDirective } from './directives/mat-input-blur-value.directive';
import { RoundUpDecimalPipe } from './pipes/round-up-decimal.pipe';
import { CardNotificationComponent } from './components/card-notification.component';

@NgModule({
  providers: [RoundUpDecimalPipe],
  declarations: [RoundUpDecimalPipe, MatInputBlurValueDirective, RepeatDirective, NotificationComponent, SpinnerComponent, CustomerPortalButtonDirective, ButtonComponent, CardNotificationComponent],
  exports: [RoundUpDecimalPipe, MatInputBlurValueDirective, RepeatDirective, NotificationComponent, SpinnerComponent, CustomerPortalButtonDirective, ButtonComponent, CardNotificationComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
  ]
})
export class SharedModule {
}
