import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { combineLatest, of, Subject, switchMap, takeUntil } from "rxjs";
import { OrderCorrection } from "../interfaces/order-correction";
import { Contract } from "../../shared/interfaces/contract";
import { CustomerSelectionService } from "../../shared/services/customer-selection.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OrderService } from '../services/order.service';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus, withSlowLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { OrderCorrectionAvailability } from '../interfaces/order-correction-availability';
import { OrderCorrectionStepperSettings } from '../interfaces/order-correction-stepper-settings';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/components/confirm-dialog.component';
import { PreviousSupplierTerminationState } from '../enumerations/previous-supplier-termination-state';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-order-correction-stepper',
  templateUrl: './order-correction-stepper.component.html',
  styleUrls: ['./order-correction-stepper.component.scss'],
})
export class OrderCorrectionStepperComponent implements OnInit, OnDestroy {

  @Output() cancellationPerformed: EventEmitter<void> = new EventEmitter<void>();
  @Output() savePerformed: EventEmitter<void> = new EventEmitter<void>();

  private destroyed$ = new Subject<void>();
  private refresh$ = new Subject<void>();

  environment = environment;

  NotificationType = NotificationType;
  PreviousSupplierTerminationState = PreviousSupplierTerminationState;

  @HostBinding('class') flexClass = 'flex-1 flex-col pt-2';

  orderCorrectionFormGroup: FormGroup | null = null;
  @ViewChild('orderCorrectionForm') orderCorrectionForm: NgForm | null = null;
  @ViewChild('stepper') viewStepper: MatStepper | null = null;

  @Input() settings: OrderCorrectionStepperSettings = {
    isStartPageEnabled: true,
    isMeterPageEnabled: true,
    isPreviousSupplierPageEnabled: true,
    isTriggeredByErrorHandling: false,
    isContractorPageEnabled: true,
    isDeliveryAddressPageEnabled: true,
    isDeliveryStartPageEnabled: true
  };

  defaultOrderCorrection: OrderCorrection | null = null;
  orderCorrectionSaveOperation: ObservableStatus<void> = ObservableStatus.none<void>();
  lastOrderCorrection: ObservableStatus<OrderCorrection> = ObservableStatus.none<OrderCorrection>();
  orderCorrectionAvailability: ObservableStatus<OrderCorrectionAvailability> = ObservableStatus.none<OrderCorrectionAvailability>();
  selectedContract: Contract | null = null;
  maxLengthMeterNumber: number = 22;
  maxLengthDeviatingContractorFirstName: number = 100;
  maxLengthDeviatingContractorLastName: number = 100;
  maxLengthDeliveryStreet: number = 55;
  maxLengthDeliveryHouseNumber: number = 10;
  maxLengthDeliveryPostcode: number = 5;
  maxLengthDeliveryCity: number = 50;
  maxLengthPreviousSupplierName: number = 100;

  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private customerSelectionService: CustomerSelectionService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.orderCorrectionFormGroup = this.formBuilder.group({
      meterNumber: [null, [Validators.maxLength(this.maxLengthMeterNumber)]],
      marketLocation: [null, [Validators.pattern('^[0-9]{11}$')]],
      previousSupplierTerminationState: [null, null],
      previousSupplierName: [null, Validators.maxLength(this.maxLengthPreviousSupplierName)],
      previousSupplierTerminationDate: [null, null],
      terminationConfirmationReceived: [null, null],
      firstDeviatingContractorFirstName: [null, Validators.maxLength(this.maxLengthDeviatingContractorFirstName)],
      firstDeviatingContractorLastName: [null, Validators.maxLength(this.maxLengthDeviatingContractorLastName)],
      secondDeviatingContractorFirstName: [null, Validators.maxLength(this.maxLengthDeviatingContractorFirstName)],
      secondDeviatingContractorLastName: [null, Validators.maxLength(this.maxLengthDeviatingContractorLastName)],
      deliveryStreet: [null, [Validators.maxLength(this.maxLengthDeliveryStreet)]],
      deliveryHouseNumber: [null, [Validators.maxLength(this.maxLengthDeliveryHouseNumber)]],
      deliveryPostcode: [null, [Validators.maxLength(this.maxLengthDeliveryPostcode)]],
      deliveryCity: [null, Validators.maxLength(this.maxLengthDeliveryCity)],
      requestedDeliveryStartDate: [null, null],
      isMove: [null, null],
    });
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of([
              ObservableStatus.none<OrderCorrection>(),
              ObservableStatus.none<OrderCorrectionAvailability>()
            ] as [ObservableStatus<OrderCorrection>, ObservableStatus<OrderCorrectionAvailability>])
          }
          return combineLatest([
            withNormalLoadingStatus(
              this.orderService.getLastOrderCorrection(this.selectedContract.contractId)
            ),
            withNormalLoadingStatus(
              this.orderService.getOrderCorrectionAvailability(this.selectedContract.contractId))
          ]);
        }),
        takeUntil(this.destroyed$)
      ).subscribe(([lastOrderCorrection, orderCorrectionAvailability]) => {
        this.lastOrderCorrection = lastOrderCorrection;
        this.orderCorrectionAvailability = orderCorrectionAvailability;
        this.defaultOrderCorrection = this.calculateDefaultOrderCorrection(this.lastOrderCorrection);
        this.orderCorrectionFormGroup!.patchValue(this.defaultOrderCorrection);
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getOrderCorrectionFromForm(): OrderCorrection {
    return {
      meterNumber: this.orderCorrectionFormGroup!.controls.meterNumber.value,
      marketLocation: this.orderCorrectionFormGroup!.controls.marketLocation.value,
      previousSupplierTerminationState: this.orderCorrectionFormGroup!.controls.previousSupplierTerminationState.value,
      previousSupplierName: this.orderCorrectionFormGroup!.controls.previousSupplierName.value,
      previousSupplierTerminationDate: this.orderCorrectionFormGroup!.controls.previousSupplierTerminationDate.value,
      terminationConfirmationReceived: this.orderCorrectionFormGroup!.controls.terminationConfirmationReceived.value,
      firstDeviatingContractorFirstName: this.orderCorrectionFormGroup!.controls.firstDeviatingContractorFirstName.value,
      firstDeviatingContractorLastName: this.orderCorrectionFormGroup!.controls.firstDeviatingContractorLastName.value,
      secondDeviatingContractorFirstName: this.orderCorrectionFormGroup!.controls.secondDeviatingContractorFirstName.value,
      secondDeviatingContractorLastName: this.orderCorrectionFormGroup!.controls.secondDeviatingContractorLastName.value,
      deliveryStreet: this.orderCorrectionFormGroup!.controls.deliveryStreet.value,
      deliveryHouseNumber: this.orderCorrectionFormGroup!.controls.deliveryHouseNumber.value,
      deliveryPostcode: this.orderCorrectionFormGroup!.controls.deliveryPostcode.value,
      deliveryCity: this.orderCorrectionFormGroup!.controls.deliveryCity.value,
      requestedDeliveryStartDate: this.orderCorrectionFormGroup!.controls.requestedDeliveryStartDate.value,
      isMove: this.orderCorrectionFormGroup!.controls.isMove.value,
    } as OrderCorrection;
  }

  save(): void {
    if (this.orderCorrectionForm!.invalid || this.orderCorrectionSaveOperation.isInLoadingState) {
      return;
    }
    let selectedOrderCorrection = this.getOrderCorrectionFromForm();
    withSlowLoadingStatus(
      this.orderService.insertOrderCorrection(this.selectedContract!.contractId, selectedOrderCorrection)
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(orderCorrectionSaveOperation => {
        this.orderCorrectionSaveOperation = orderCorrectionSaveOperation;
        if (orderCorrectionSaveOperation.isInErrorState) {
          this.snackBar.open('Die Auftragsänderung konnte nicht gespeichert werden!', 'OK', {
            panelClass: 'snack-danger'
          });
        } else if (orderCorrectionSaveOperation.isInValueState || orderCorrectionSaveOperation.isInNoValueState) {
          this.snackBar.open('Die Auftragsänderung wurde erfolgreich gespeichert.', 'OK', {
            panelClass: 'snack-success'
          });
          this.savePerformed.emit();
          this.refresh();
        }
      });
  }

  calculateDefaultOrderCorrection(lastOrderCorrection: ObservableStatus<OrderCorrection>): OrderCorrection {
    if (lastOrderCorrection.isInValueState) {
      return lastOrderCorrection.value!;
    }
    return this.getEmptyOrderCorrection();
  }

  getEmptyOrderCorrection(): OrderCorrection {
    return {
      meterNumber: null,
      marketLocation: null,
      previousSupplierTerminationState: PreviousSupplierTerminationState.NoPreviousSupplier,
      previousSupplierName: null,
      previousSupplierTerminationDate: null,
      terminationConfirmationReceived: false,
      firstDeviatingContractorFirstName: null,
      firstDeviatingContractorLastName: null,
      secondDeviatingContractorFirstName: null,
      secondDeviatingContractorLastName: null,
      deliveryStreet: null,
      deliveryHouseNumber: null,
      deliveryPostcode: null,
      deliveryCity: null,
      requestedDeliveryStartDate: null,
      isMove: false,
    } as OrderCorrection;
  }

  refresh(): void {
    this.refresh$.next();
  }

  cancel(): void {
    if (!this.hasUnsavedChanges()) {
      this.performCancellation();
    } else {
      this.confirmCancellation();
    }
  }

  private confirmCancellation(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "450px",
      data: { message: "Sie haben ungespeicherte Änderungen. Wirklich schließen?" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.performCancellation();
      }
    });
  }

  private performCancellation(): void {
    this.viewStepper!.reset();
    if (!this.lastOrderCorrection.isInLoadingState || !this.orderCorrectionAvailability.isInLoadingState) {
      this.orderCorrectionFormGroup!.patchValue(this.defaultOrderCorrection!);
    }
    this.cancellationPerformed.emit();
  }

  private hasUnsavedChanges(): boolean {
    if (this.lastOrderCorrection.isInLoadingState || this.orderCorrectionAvailability.isInLoadingState) {
      return false;
    }
    return !this.orderService.isEqual(this.defaultOrderCorrection, this.getOrderCorrectionFromForm());
  }


}
