<div class="overlay">
  <div class="center">
    <mat-progress-spinner
      diameter=150
      mode="indeterminate"
      color="primary"
      strokeWidth=15>
    </mat-progress-spinner>
  </div>
</div>
