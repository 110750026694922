import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, delayWhen, EMPTY, of, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';
import { environment } from "../../../../environments/environment";
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { PriceDetail } from '../interfaces/price-detail';
import { PriceService } from './price.service';

@Injectable({
  providedIn: 'root'
})
export class PriceAutoLoadService implements OnDestroy {

  environment = environment;
  private destroyed$ = new Subject<void>();

  private currentPriceSubject = new BehaviorSubject<PriceDetail | null>(null);
  currentPrice$ = this.currentPriceSubject.asObservable();

  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(customerSelectionService: CustomerSelectionService, priceService: PriceService) {
    customerSelectionService.contractSelected$
      .pipe(
        tap(() => this._isLoading = true),
        switchMap(contract => {
          if (!contract) {
            return of(null);
          }
          const startTime = Date.now();
          return priceService
            .getCurrentPrice(contract.contractId)
            .pipe(
              catchError(error => {
                this._isLoading = false;
                return EMPTY; 
              }),
              delayWhen(() => {
                const duration = Date.now() - startTime;
                const delayDuration = Math.max(0, environment.minimumLoadingTimeInMilliseconds - duration);
                return timer(delayDuration);
              }));
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        currentPrice => {
          this.currentPriceSubject.next(currentPrice);
          this._isLoading = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
