import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { MeterReadingService } from '../../meter-reading/services/meter-reading.service';
import { of, Subject, switchMap, takeUntil } from 'rxjs';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { Contract } from '../../shared/interfaces/contract';
import { MeterReading } from '../../meter-reading/interfaces/meter-reading';
import { EnergyType } from "../../shared/enumerations/energy-type";
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { ObservableDisplayState } from '../../observable-status/enumerations/observable-display-state';


@Component({
  selector: 'app-dashboard-meter-reading-card',
  templateUrl: './dashboard-meter-reading-card.component.html'
})
export class DashboardMeterReadingCardComponent implements OnInit, OnDestroy {

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  EnergyType = EnergyType;
  ObservableDisplayState = ObservableDisplayState;
  NotificationType = NotificationType;
  environment = environment;

  selectedContract: Contract | null = null;
  lastMeterReading: ObservableStatus<MeterReading> = ObservableStatus.none<MeterReading>();

  constructor(
    private meterReadingService: MeterReadingService,
    private customerSelectionService: CustomerSelectionService) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of(ObservableStatus.none<MeterReading>())
          }
          return withNormalLoadingStatus(this.meterReadingService.getLastMeterReading(this.selectedContract.contractId));
        }),
        takeUntil(this.destroyed$)
      ).subscribe(lastMeterReading => {
        this.lastMeterReading = lastMeterReading;
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

}
