import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FaqComponent} from "./components/faq.component";
import {FaqRoutingModule} from "./faq-routing.module";
import {MatIconModule} from "@angular/material/icon";
import {ContactModule} from "../contact/contact.module";
import {MatCardModule} from "@angular/material/card";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ContactComponent} from "./components/contact.component";
import {TopicComponent} from "./components/topic.component";
import {BreadcrumbComponent} from "./components/breadcrumb.component";
import {MatGridListModule} from "@angular/material/grid-list";
import {ContactTopicComponent} from "./components/contact-topic.component";
import {TagComponent} from "./components/tag.component";
import {TopicsComponent} from "./components/topics.component";
import {ContactTopicRequestComponent} from "./components/contact-topic-request.component";
import {MatListModule} from "@angular/material/list";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatOptionModule} from "@angular/material/core";
import {MatChipsModule} from "@angular/material/chips";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {FavouritesComponent} from "./components/favourites.component";
import {MeterFaqComponent} from "./components/meter-faq.component";
import {FileUploadModule} from "../file-upload/file-upload.module";
import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    FaqComponent,
    ContactComponent,
    TopicComponent,
    BreadcrumbComponent,
    FavouritesComponent,
    ContactTopicComponent,
    TagComponent,
    TopicsComponent,
    ContactTopicRequestComponent,
    MeterFaqComponent
  ],
  exports: [
    FaqComponent,
    MeterFaqComponent
  ],
  imports: [
    CommonModule,
    FaqRoutingModule,
    MatIconModule,
    ContactModule,
    MatCardModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    MatButtonModule,
    MatProgressBarModule,
    MatGridListModule,
    MatListModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatOptionModule,
    MatChipsModule,
    MatCheckboxModule,
    MatSelectModule,
    FileUploadModule,
    MatTooltipModule
  ]
})
export class FaqModule {
}
