import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PaymentInformationService } from '../../payment-information/services/payment-information.service';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';
import { PaymentMethod } from '../../payment-information/enumerations/payment-method';
import { Contract } from '../../shared/interfaces/contract';
import { of, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { PaymentInformation } from '../../payment-information/interfaces/payment-information';
import { EnergyType } from '../../shared/enumerations/energy-type';
import { ObservableDisplayState } from '../../observable-status/enumerations/observable-display-state';
import { NotificationType } from '../../shared/enumerations/notification-type';
import { ObservableStatus } from '../../observable-status/classes/observable-status';
import { withNormalLoadingStatus } from '../../observable-status/extensions/observable.extension';


@Component({
  selector: 'app-dashboard-payment-information-card',
  templateUrl: './dashboard-payment-information-card.component.html'
})
export class DashboardPaymentInformationCardComponent {

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  EnergyType = EnergyType;
  ObservableDisplayState = ObservableDisplayState;
  NotificationType = NotificationType;
  PaymentMethod = PaymentMethod;
  environment = environment;

  selectedContract: Contract | null = null;
  paymentInformation: ObservableStatus<PaymentInformation> = ObservableStatus.none<PaymentInformation>();

  constructor(
    public paymentInformationService: PaymentInformationService,
    private customerSelectionService: CustomerSelectionService) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.selectedContract) {
            return of(ObservableStatus.none<PaymentInformation>())
          }
          return withNormalLoadingStatus(this.paymentInformationService.getPaymentInformation(this.selectedContract.contractId));
        }),
        takeUntil(this.destroyed$)
    ).subscribe(paymentInformation => {
      this.paymentInformation = paymentInformation;
      });
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
        this.refresh$.next();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

}
