<div class="contactInfo p-4 border rounded-2xl">
  <div class="flex flex-wrap w-full">
    <mat-icon aria-hidden="false" aria-label="Kontakt" class="material-symbols-outlined">live_help</mat-icon>
    <span class="!ml-1 mat-subheading-2">Kontakt</span>
    <p class="w-full mt-3">
      Wir möchten sicherstellen, dass Sie als unser Kunde bestens informiert sind. Sollten Sie weitere Fragen haben, stehen wir Ihnen gerne zur Verfügung.
    </p>
    <ng-container *ngIf="environment.isExternalContactFormUrl">
      <a href="{{ environment.contactFormUrl }}" target="_blank" class="mat-body-2 !m-0">Zum Kontaktformular</a>
    </ng-container>
    <ng-container *ngIf="!environment.isExternalContactFormUrl">
      <a routerLink="/{{ environment.contactFormUrl }}" class="mat-body-2 !m-0">Zum Kontaktformular</a>
    </ng-container>
  </div>
</div>
