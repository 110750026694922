import { Component, Input } from '@angular/core';
import { EnergyType } from '../../shared/enumerations/energy-type';

@Component({
  selector: 'app-meter-faq',
  templateUrl: './meter-faq.component.html',
})
export class MeterFaqComponent {

  @Input() energyType: EnergyType = EnergyType.Electricity;

  EnergyType = EnergyType;

}
