import { Component } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-dashboard-move-card',
  templateUrl: './dashboard-move-card.component.html'
})
export class DashboardMoveCardComponent {

  environment = environment;

  constructor(public authService: AuthService) {
  }

}
