import {Component, HostBinding} from '@angular/core';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html'
})
export class PaymentInformationComponent {

  @HostBinding('class') flexClass = 'flex-1';

}
