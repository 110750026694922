<div [class]="class">
  <div class="fileUpload" (click)="fileUpload.click()">
    <input type="file" class="hidden fileUpload" (change)="onFileSelected($event)" [accept]="acceptedFileExtensions" #fileUpload>
    <mat-form-field floatLabel='never' class="w-full" appearance="fill">
      <button matSuffix mat-icon-button class="fileUpload">
        <mat-icon>attach_file</mat-icon>
      </button>
      <input matInput readonly color="primary" value="{{file?.name || 'Keine Datei ausgewählt...'}}" class="fileUpload">
    </mat-form-field>
  </div>
</div>



