<div class="container mx-auto px-4 py-8 flex flex-col">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Vielen Dank für Ihre Umzugsmeldung!
    </h1>
  </mat-card-title>
  <p>
    Wir haben Ihre Angaben zum Umzug erhalten und werden sie an die Fachabteilung weiterleiten.<br />
    Sie müssen nichts weiter unternehmen, wir werden bei offenen Fragen unaufgefordert auf Sie zukommen.
  </p>
  <div class="flex mt-5 w-full flex-wrap flex-col md:flex-row md:flex-wrap md:items-center">
    <a type="button" customer-portal-button color="primary" mat-raised-button routerLink="/{{ environment.dashboardUrl }}">Zurück zur Übersicht</a>
    <a class="mat-body-2 text-center md:text-left mt-4 md:mt-0 md:ml-12" href="{{ faqUrl }}" target="_blank">Weitere Hilfe & Kontakt</a>
  </div>
  <mat-card class="!mt-14">
    <div class="flex flex-col">
      <p class="mat-subheading-2">So geht es weiter:</p>
      <p>
        Die Netznutzung werden wir auftragsgemäß zum Auszugsdatum bei Ihrem zuständigen Netzbetreiber abmelden und Ihnen für den Zeitraum der Belieferung eine Schlussrechnung übermitteln.<br />
        Ihren Endzählerstand können sie bequem über unser Kundenportal <a href="{{ customerPortalUrl }}">{{ customerPortalUrl }}</a> eingeben.
      </p>
      <p>
        Sofern eine Weiterbelieferung an Ihrer neuen Anschrift möglich ist, werden wir Ihnen hierzu in den nächsten Tagen eine Lieferbestätigung zukommen lassen.
      </p>
      <p>
        <span class="block mat-body-2">Haben Sie noch Fragen?</span>
        Dann können Sie uns wochentags von 09:00 bis 18:00 Uhr telefonisch unter der Nummer {{ hotline }} erreichen.
      </p>
    </div>
  </mat-card>
</div>
