<div class="container mx-auto px-4 py-8">
  <mat-card-title>
    <h1 class="mat-headline !my-0 !mb-6">
      Vielen Dank für Ihre Nachricht
    </h1>
    <span class="mat-title !my-0">
      Wir werden Ihr Anliegen prüfen und Ihnen in den nächsten Tagen eine Antwort zukommen lassen.
    </span>
  </mat-card-title>
  <div class="flex flex-col mt-10 w-full">
    <a mat-raised-button customer-portal-button color="primary" class="w-full md:w-auto text-white" routerLink="/{{ environment.dashboardUrl }}">Zurück zur Übersicht</a>
  </div>
</div>
