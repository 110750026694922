import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../../shared/services/auth.service';
import {Faq} from "../interfaces/faq";
import {Customer} from "../interfaces/customer";

@Injectable({
  providedIn: 'root'
})

export class FaqService {

  constructor(private authService: AuthService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  }

  getFaq(
    tagId: number | null,
    topicId: number | null,
    questionId: number | null): Observable<Faq> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);

    if (tagId != null)
      return this.http.get<Faq>(`${this.baseUrl}api/faq?tagId=` + tagId.toString(), {headers: headers});

    if (topicId != null)
      return this.http.get<Faq>(`${this.baseUrl}api/faq?topicId=` + topicId.toString(), {headers: headers});

    if (questionId != null)
      return this.http.get<Faq>(`${this.baseUrl}api/faq?questionId=` + questionId.toString(), {headers: headers});

    return this.http.get<Faq>(`${this.baseUrl}api/faq`, {headers: headers});
  }

  getFaqContact(): Observable<Customer[]> {
    let headers = new HttpHeaders()
      .set('authorization', `Bearer ${this.authService.jwt}`);
    return this.http.get<Customer[]>(`${this.baseUrl}api/faq/contact`, {headers: headers});
  }
}
