import {MatPaginatorIntl} from "@angular/material/paginator";

export class GermanMatPaginator extends MatPaginatorIntl {
  itemsPerPageLabel = 'Elemente pro Seite:';
  nextPageLabel = 'Nächste Seite';
  firstPageLabel = 'Erste Seite';
  lastPageLabel = 'Letzte Seite';
  previousPageLabel = 'Vorherige Seite';
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} von ${length}`;
  };
}
