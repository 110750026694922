import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {of, Subject, takeUntil} from "rxjs";
import {ObservableStatus} from "../../observable-status/classes/observable-status";
import {Faq} from "../interfaces/faq";
import {Topic} from "../interfaces/topic";
import {FaqService} from "../services/faq.service";
import {switchMap} from "rxjs/operators";
import {withNormalLoadingStatus} from "../../observable-status/extensions/observable.extension";
import {NotificationType} from "../../shared/enumerations/notification-type";
import {environment} from "../../../../environments/environment";
import {Favourite} from "../interfaces/favourite";
import {ContactSelectionService} from "../services/contact-selection.service";

@Component({
  selector: 'app-contact-topic',
  templateUrl: './contact-topic.component.html'
})
export class ContactTopicComponent implements OnInit, OnDestroy {
  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  @HostBinding('class') flexClass = 'flex-1';

  NotificationType = NotificationType;
  environment = environment;

  faqState: ObservableStatus<Faq> = ObservableStatus.none<Faq>();
  topics: Topic[] = [];
  favourites: Favourite[] = []
  customerType: string | null = null

  constructor(private faqService: FaqService, private contactSelectionService: ContactSelectionService) {
  }

  ngOnInit(): void {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (!this.customerType) {
            return of(ObservableStatus.none<Faq>())
          }
          return withNormalLoadingStatus(
            this.faqService.getFaq(null, null, null)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe(faqState => {
      this.faqState = faqState;
      this.topics = faqState.value?.topics || [];
      this.favourites = faqState.value?.favourites || [];
    });

    this.contactSelectionService.customerTypeSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(customerType => {
        this.customerType = customerType;
        if (this.customerType) this.refresh();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }
}
