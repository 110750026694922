import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {of, Subject, switchMap, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {withNormalLoadingStatus} from "../../observable-status/extensions/observable.extension";
import {ObservableStatus} from "../../observable-status/classes/observable-status";
import {FaqService} from "../services/faq.service";
import {Question} from "../interfaces/question";
import {Faq} from "../interfaces/faq";
import {Topic} from "../interfaces/topic";
import {NotificationType} from "../../shared/enumerations/notification-type";
import {Favourite} from "../interfaces/favourite";
import {Tag} from "../interfaces/tag";

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html'
})
export class TopicComponent implements OnInit, OnDestroy {
  @HostBinding('class') flexClass = 'flex-1';

  private refresh$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  route: number | null = null;

  faqState: ObservableStatus<Faq> = ObservableStatus.none<Faq>();
  topic: Topic | null = null;
  favourites: Favourite[] = [];
  subTopics:Topic[] = [];
  questions: Question[] = [];
  visibleQuestions: Question[] = [];
  visibleQuestionsCount: number = 0;
  questionsPerLoad: number = 5;
  allQuestionsLoaded: boolean = false;

  NotificationType = NotificationType;

  constructor(private activatedRoute: ActivatedRoute, private faqService: FaqService) {
  }

  ngOnInit(): void {
    this.initWithRouteParameters();
    this.refresh$
      .pipe(
        switchMap(() => {
          return withNormalLoadingStatus(
            this.faqService.getFaq(null, this.route, null)
          );
        }),
        takeUntil(this.destroyed$)
      ).subscribe((faqState) => {
      this.faqState = faqState;
      this.topic = this.faqState.value?.topics.find(t => t.id == this.route) || null;
      this.favourites = this.faqState.value?.favourites || [];
      this.subTopics = this.faqState.value?.subTopics || [];
      this.questions = this.faqState.value?.questions || [];
      this.resetPagination();
    });
    this.refresh();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  refresh(): void {
    this.refresh$.next();
  }

  private initWithRouteParameters(): void {
    const link = this.activatedRoute.snapshot.params.id;
    if (link) {
      this.route = link;
    }
  }

  loadMore() {
    if (this.allQuestionsLoaded) {
      return;
    }
    const nextItems = this.questions.slice(this.visibleQuestionsCount, this.visibleQuestionsCount + this.questionsPerLoad);
    this.visibleQuestions = [...this.visibleQuestions, ...nextItems];
    this.visibleQuestionsCount += this.questionsPerLoad;
    this.allQuestionsLoaded = this.visibleQuestionsCount >= this.questions.length;
  }

  private resetPagination(): void {
    this.visibleQuestions = [];
    this.visibleQuestionsCount = 0;
    this.allQuestionsLoaded = false;
    this.loadMore();
  }
}
