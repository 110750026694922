import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, forkJoin, of, Subject, takeUntil, timer } from 'rxjs';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Contract } from '../../shared/interfaces/contract';
import { environment } from '../../../../environments/environment';
import { FileUploadService } from '../../file-upload/services/file-upload.service';
import { FileUploadStatus } from '../../file-upload/enumerations/file-upload-status';
import { FileUploadResult } from '../../file-upload/interfaces/file-upload-result';
import { CustomerSelectionService } from '../../shared/services/customer-selection.service';

@Component({
  selector: 'app-sepa-upload',
  templateUrl: './sepa-upload.component.html'
})
export class SepaUploadComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<void> = new Subject<void>();
  selectedContract: Contract | null = null;
  isUploadProcessOngoing = false;
  environment = environment;
  selectedFile: File | null = null;

  constructor(
    private customerSelectionService: CustomerSelectionService,
    private fileUploadService: FileUploadService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.customerSelectionService.contractSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contract => {
        this.selectedContract = contract;
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  upload() {
    if (this.isUploadProcessOngoing) {
      return;
    }
    this.isUploadProcessOngoing = true;
    forkJoin(
      {
        uploadFile: this.fileUploadService
          .uploadSepaFile(this.selectedContract!.contractId, this.selectedFile!)
          .pipe(catchError(() => of(false))),
        awaitMinimumProgressTime: timer(environment.minimumProgressTimeInMilliseconds)
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(observer => {
        let errorMessage = 'Datei konnte nicht hochgeladen werden, bitte versuchen Sie es später erneut.';
        if (observer.uploadFile === false) {
          this.snackBar.open(errorMessage, 'OK', {
            panelClass: 'snack-danger'
          });
        } else {
          let fileUploadResult = observer.uploadFile as FileUploadResult;
          if (fileUploadResult.status !== FileUploadStatus.Success) {
            if (fileUploadResult.status === FileUploadStatus.UnsupportedFileExtension) {
              errorMessage = 'Es werden nur PDF, JPG und PNG Dateien unterstützt.';
            }
            else if (fileUploadResult.status === FileUploadStatus.VirusFound) {
              errorMessage = 'Es liegt ein Fehler in Ihrer Datei vor. Datei konnte nicht hochgeladen werden.';
            }
            else if (fileUploadResult.status === FileUploadStatus.FileSizeLimitExceeded) {
              errorMessage = `Es werden nur Dateien bis zu einer Größe von ${parseFloat((this.environment.maximumFileLengthInBytes / 1024 / 1024).toFixed(2))} MB unterstützt`;
            }
            this.snackBar.open(errorMessage, 'OK', {
              panelClass: 'snack-danger'
            });
          }
          else {
            this.snackBar.open('Datei wurde erfolgreich hochgeladen.', 'OK', {
              panelClass: 'snack-success'
            });
            this.selectedFile = null;
          }
        }
      }).add(() => {
        this.isUploadProcessOngoing = false;
      });
  }

}
